//------------------------------------------------------------------------------
const arrow_keys = {
    right   : 37,
    up      : 38,
    left    : 39,
    down    : 40,
    shift   : 16
};

//------------------------------------------------------------------------------
/**
 * @ignore
 */
class SDK3DVerse_ActionMap
{
    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(ftlAPI)
    {
        this.ftlAPI = ftlAPI;
        this.reset();
    }

    //--------------------------------------------------------------------------
    /**
     * @deprecated The SDK now guesses the keyboardLayout by itself. So the current function
     * has no effect anymore. If you really need to do it, then you can still remap the
     * [values of the action map]{@link SDK3DVerse.actionMap#getValues} and
     * [propagate]{@link SDK3DVerse.actionMap#propagate} it.
     * @description Reset the action map to default bindings but with AZERTY keyboard layout.
     * 
     * @method SDK3DVerse.actionMap#setFrenchKeyboardBindings
     */
    setFrenchKeyboardBindings()
    {
        // Obsolete and would interfere with the good layout guess now performed by InputRelay
        /*
        this.reset();
        this.values.MOVE_FORWARD[0] = ["KEY_"+ toCharCode('Z')];
        this.values.MOVE_LEFT[0]    = ["KEY_"+ toCharCode('Q')];
        this.values.MOVE_DOWN[0]    = ["KEY_"+ toCharCode('A')];
        */
    }

    //------------------------------------------------------------------------------
    /**
     * Get the action map values.
     * 
     * @returns {Object} The action map values
     * 
     * @method SDK3DVerse.actionMap#getValues
     */
    getValues()
    {
        return this.values;
    }

    //------------------------------------------------------------------------------
    /**
     * Send the action map.
     * 
     * @method SDK3DVerse.actionMap#propagate
     */
    propagate()
    {
        this.ftlAPI.updateActionMap(this);
    }

    //--------------------------------------------------------------------------
    /**
     * Reset the action to default bindings.
     * 
     * @method SDK3DVerse.actionMap#reset
     */
    reset()
    {
        this.values = this.getDefault();
    }
    
    //--------------------------------------------------------------------------
    serialize()
    {
        return JSON.stringify(this.values);
    }

    //--------------------------------------------------------------------------
    getDefault()
    {
        return {
            ACTION_1:
            [
                // Space
                [ "KEY_32"                  ],
                [ "GAMEPAD_BTN_A"           ]
            ],

            ACTION_2:
            [
                [ "KEY_"+ toCharCode('R')   ],
                [ "GAMEPAD_BTN_B"           ]
            ],

            ACTION_3:
            [
                [ "KEY_"+ toCharCode('Q')   ],
                [ "GAMEPAD_BTN_X"           ]
            ],

            ACTION_4:
            [
                [ "KEY_"+ toCharCode('E')   ],
                [ "GAMEPAD_BTN_Y"           ]
            ],

            ACTION_5:
            [
                // ControlLeft
                [ "KEY_17"                  ],
                [ "GAMEPAD_LEFT_TRIGGER"    ]
            ],

            ACTION_6:
            [
                // ShiftLeft
                [ "KEY_16"                  ],
                [ "GAMEPAD_RIGHT_TRIGGER"   ]
            ],

            ACTION_7:
            [
                [ "KEY_"+ toCharCode('X')   ],
                [ "GAMEPAD_LB"              ]
            ],

            ACTION_8:
            [
                [ "KEY_"+ toCharCode('C')   ],
                [ "GAMEPAD_RB"              ]
            ],

            MOVE_FORWARD: 
            [
                [ "KEY_"+ toCharCode('W')   ],
                [ "KEY_"+ arrow_keys.up     ],
                [
                    "TOUCH_PINCH_START",
                    "TOUCH_FORWARD"
                ],
                [ "GAMEPAD_LEFT_JOYSTICK_Y_NEG" ]
            ],

            MOVE_LEFT:
            [
                [ "KEY_"+ toCharCode('A')       ],
                [ "GAMEPAD_LEFT_JOYSTICK_X_NEG" ]
            ],

            MOVE_BACKWARD:
            [
                [ "KEY_"+ toCharCode('S')   ],
                [ "KEY_"+ arrow_keys.down   ],
                [
                    "TOUCH_PINCH_START",
                    "TOUCH_BACKWARD"
                ],
                [ "GAMEPAD_LEFT_JOYSTICK_Y_POS" ]
            ],

            MOVE_RIGHT:
            [
                [ "KEY_"+ toCharCode('D')       ],
                [ "GAMEPAD_LEFT_JOYSTICK_X_POS" ]
            ],

            MOVE_DOWN:
            [
                [ "KEY_"+ toCharCode('Q')   ],
                [ "GAMEPAD_LEFT_TRIGGER"    ]
            ],

            MOVE_UP:
            [
                [ "KEY_"+ toCharCode('E')   ],
                [ "GAMEPAD_RIGHT_TRIGGER"   ]
            ],

            LOOK_LEFT:
            [
                [
                    "MOUSE_BTN_LEFT",
                    "MOUSE_AXIS_X_POS"
                ],
                [ "KEY_"+ arrow_keys.left   ],
                [
                    "TOUCH_START",
                    "TOUCH_AXIS_X_POS"
                ],
                [ "GAMEPAD_RIGHT_JOYSTICK_X_POS" ]
            ],

            LOOK_RIGHT:
            [
                [
                    "MOUSE_BTN_LEFT",
                    "MOUSE_AXIS_X_NEG"
                ],
                [ "KEY_"+ arrow_keys.right   ],
                [
                    "TOUCH_START",
                    "TOUCH_AXIS_X_NEG"
                ],
                [ "GAMEPAD_RIGHT_JOYSTICK_X_NEG" ]
            ],

            LOOK_UP:
            [
                [
                    "MOUSE_BTN_LEFT",
                    "MOUSE_AXIS_Y_POS"
                ],
                [
                    "TOUCH_START",
                    "TOUCH_AXIS_Y_POS"
                ],
                [ "GAMEPAD_RIGHT_JOYSTICK_Y_POS" ]
            ],

            LOOK_DOWN:
            [
                [
                    "MOUSE_BTN_LEFT",
                    "MOUSE_AXIS_Y_NEG"
                ],
                [
                    "TOUCH_START",
                    "TOUCH_AXIS_Y_NEG"
                ],
                [ "GAMEPAD_RIGHT_JOYSTICK_Y_NEG" ]
            ],

            SPEED_UP:
            [
                [ "MOUSE_WHEEL_UP" ]
            ],

            SPEED_DOWN:
            [
                [ "MOUSE_WHEEL_DOWN" ]
            ],

            DISPLACE_UP: 
            [
                [
                    "MOUSE_BTN_MIDDLE",
                    "MOUSE_AXIS_Y_POS"
                ]
            ],
            DISPLACE_DOWN:
            [
                [
                    "MOUSE_BTN_MIDDLE",
                    "MOUSE_AXIS_Y_NEG"
                ]
            ],
            DISPLACE_LEFT:
            [
                [
                    "MOUSE_BTN_MIDDLE",
                    "MOUSE_AXIS_X_NEG"
                ]
            ],
            DISPLACE_RIGHT:
            [
                [
                    "MOUSE_BTN_MIDDLE",
                    "MOUSE_AXIS_X_POS"
                ]
            ],
            SENSITIVITY_UP:
            [
                [
                    "KEY_"+ arrow_keys.shift,
                    "MOUSE_WHEEL_UP"
                ]
            ],
            SENSITIVITY_DOWN:
            [
                [
                    "KEY_"+ arrow_keys.shift,
                    "MOUSE_WHEEL_DOWN"
                ]
            ]
        };
    }
}

//------------------------------------------------------------------------------
function toCharCode(str)
{
    return str.charCodeAt(0);
}

export default SDK3DVerse_ActionMap;