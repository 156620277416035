//------------------------------------------------------------------------------
import SDK3DVerse_EditorAPI from './EditorAPI';
import SDK3DVerse_FTLAPI from './FTLAPI';
import { physics_query_filter_flag } from './FTLAPI';
import Entity from './Entity';
import SDK3DVerse_EntityRegistry, { ComponentDescription } from './EntityRegistry';
import SDK3DVerse_CameraAPI from './CameraAPI';
import SDK3DVerse_Streamer from './Streamer';
import Utils from './Utils';
import Viewport from './CameraAPI';

import { vec3, quat, mat4 } from 'gl-matrix';

//------------------------------------------------------------------------------
import { EventEmitter } from 'events';
import UUID from 'uuid';
import toposort from 'toposort';
import { ComponentMap, ComponentName, EditorEntity, EntityTemplate, SDK_Quat, SDK_Vec3, SettingName, SettingsMap } from 'types';

//------------------------------------------------------------------------------
/**
 * Handles entities and communicates with the rendering engine.
 * @namespace SDK3DVerse.engineAPI
 */

//------------------------------------------------------------------------------
/**
 * @ignore
 */
class SDK3DVerse_EngineAPI {

    get PHYSICS_EVENT_MAP_UUID() { return "7a8cc05e-8659-4b23-99d1-1352d13e2020" };

    notifier: EventEmitter;
    streamer: SDK3DVerse_Streamer;
    scriptNotifier: EventEmitter;
    ftlAPI: SDK3DVerse_FTLAPI;
    entityRegistry: SDK3DVerse_EntityRegistry;
    editorAPI: SDK3DVerse_EditorAPI;
    cameraAPI: SDK3DVerse_CameraAPI;
    selectedEntities: Entity[];
    settingDefaultValues: Record<string, any>;

    commitChangesTimeout: number | null = null;
    pendingEntities: Set<Entity>;
    dirtyEntities: Record<string, Entity>;

    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(notifier: EventEmitter, streamer: SDK3DVerse_Streamer) {
        this.notifier = notifier;
        this.streamer = streamer;
        this.scriptNotifier = new EventEmitter();
        this.pendingEntities = new Set();

        this.ftlAPI = new SDK3DVerse_FTLAPI(streamer.taskStack);
        this.cameraAPI = new SDK3DVerse_CameraAPI(this.notifier, this, streamer);
        this.entityRegistry = new SDK3DVerse_EntityRegistry(this);
        this.editorAPI = new SDK3DVerse_EditorAPI(this.notifier, this.entityRegistry);

        this.listenEditorEvents();
        this.reset();
    }

    //--------------------------------------------------------------------------
    initialize() {
        this.cameraAPI.initialize();

        this.notifier.on('scriptEvent', this.handleScriptEvent);
        this.notifier.on('sceneGraphReady', async () => {
            const overriders = await this.filterEntities({ mandatoryComponents: ['overrider'] });
            for (const overrider of overriders) {
                this.notifier.emit("entityResolved", overrider);
            }
        })
        this.notifier.on('onLoadingEnded', this.onLoadingEnded);
    }

    //--------------------------------------------------------------------------
    reset() {
        this.entityRegistry.resetState();
        this.editorAPI.disconnect();
        this.cameraAPI.resetState();

        this.selectedEntities = [];
        this.settingDefaultValues = {};

        this.dirtyEntities = {};
        this.pendingEntities.clear();

        if(this.commitChangesTimeout) {
            this.commitChangesTimeout = null;
            clearTimeout(this.commitChangesTimeout);
        }
    }

    //--------------------------------------------------------------------------
    // private
    async createEntity(parentEntity: Entity | null, entityTemplate: EntityTemplate, ordinal = 0) {
        return await this.requestEntityCreation(
            parentEntity,
            entityTemplate,
            ordinal,
            false
        );
    }

    //--------------------------------------------------------------------------
    // deprecated
    async instantiateEntities(parentEntity: Entity | null, entityTemplates: EntityTemplate[], ordinal: number = 0) {
        const parentUUID = parentEntity
            ? parentEntity.getEUID()
            : Utils.invalidUUID;

        for (const template of entityTemplates) {
            if (!template.hasOwnProperty('lineage')) {
                template.lineage = { parentUUID, ordinal: ordinal++ };
            }

            if (template.hasOwnProperty('local_transform')) {
                template.local_transform = Utils.patchTransform(template.local_transform);
            }
        }

        return await this.editorAPI.sendCreateEntityRequest('create-entities', entityTemplates);
    }

    //--------------------------------------------------------------------------
    // deprecated
    async createEntities(parentEntity: Entity | null, entityTemplates: EntityTemplate[], ordinal: number = 0) {
        return await this.instantiateEntities(parentEntity, entityTemplates, ordinal);
    }

    //--------------------------------------------------------------------------
    // deprecated
    async linkScene(parentEntity: Entity, entityName: string, sceneUUID: string) {
        let entityTemplate = {
            debug_name: { value: entityName },
        };
        Utils.resolveComponentDependencies(entityTemplate, "scene_ref");
        entityTemplate["scene_ref"] = { value: sceneUUID };
        return await this.createEntity(parentEntity, entityTemplate);
    }

    //--------------------------------------------------------------------------
    // private
    async createTransientEntity(parentEntity: Entity | null, entityTemplate: EntityTemplate, ordinal = 0) {
        return await this.requestEntityCreation(parentEntity, entityTemplate, ordinal, true);
    }

    //--------------------------------------------------------------------------
    // deprecated
    async spawnEntity(parentEntity: Entity, entityTemplate: EntityTemplate, ordinal = 0) {
        return await this.requestEntityCreation(parentEntity, entityTemplate, ordinal, true);
    }

    //--------------------------------------------------------------------------
    // private
    async requestEntityCreation(parentEntity: Entity, entityTemplate: EntityTemplate, ordinal: number, isRuntime: boolean) {
        if (!isRuntime && parentEntity && parentEntity.isRuntime()) {
            console.warn('Cannot create a non-runtime entity under a runtime entity');
            return;
        }

        const parentUUID = parentEntity
            ? parentEntity.getEUID()
            : Utils.invalidUUID;

        entityTemplate.lineage = { parentUUID, ordinal };
        const requestType = isRuntime ? 'spawn-entity' : 'create-entity';

        if (entityTemplate.hasOwnProperty('local_transform')) {
            entityTemplate.local_transform = Utils.patchTransform(entityTemplate.local_transform);
        }

        const createdEntities = await this.editorAPI.sendCreateEntityRequest(requestType, entityTemplate);
        return createdEntities[0];
    }

    //--------------------------------------------------------------------------
    // private
    async transientEntity(entityTemplate: EntityTemplate) {
        const uuid = UUID.v4();
        const rtid = Utils.generateRTID(uuid);

        entityTemplate.euid = {
            value: uuid
        };

        this.ftlAPI.createEntity(entityTemplate);
        entityTemplate.euid.rtid = rtid;

        const entity = new Entity(
            this,
            {
                rtid: rtid.toString(),
                components: entityTemplate as ComponentMap,
                children: [],
                selectingClients: [],
                selectedDescendants: {},

                isVisible: true,
                isRuntime: true,
                isExternal: false,
                isTransient: true
            }
        );

        // Dirty sleep to make sure the renderer created the entity.
        await new Promise(resolve => setTimeout(resolve, 250));
        return entity;
    }

    //--------------------------------------------------------------------------
    /**
     * Delete entities.
     *
     * @param {Array.<Entity>} entities - Entities to delete
     *
     * @fires onEntitiesDeleted
     *
     * @method SDK3DVerse.engineAPI#deleteEntities
     * @async
     */
    async deleteEntities(entities: Entity[]) {
        for (const entity of entities) {
            this.entityRegistry.cancelDirtyState(entity)
            this.pendingEntities.delete(entity);
        }

        const entityEUIDs = entities.map((e) => e.getEUID());
        await this.editorAPI.deleteEntities(entityEUIDs);
    }

    //--------------------------------------------------------------------------
    /**
     * Override component of specified type in entities.
     *
     * @param {Array.<Entity>} entities - Entities
     * @param {string} componentType - The <a href="tutorial-components.html">component</a> type
     *
     * @method SDK3DVerse.engineAPI#overrideComponent
     * @async
     */
    async overrideComponent(entities: Entity[], componentType: ComponentName) {
        for (const entity of entities) {
            const value = entity.isAttached(componentType)
                ? entity.getComponent(componentType)
                : this.getComponentDefaultValue(componentType);

            await this.setOrCreateOverrider(entity, componentType, value);
        }

        this.commitChanges();
    }

    //--------------------------------------------------------------------------
    /**
     * Discard overridden component of specified type in entities.
     *
     * @param {Array.<Entity>} entities - Entities
     * @param {string} componentType - The <a href="tutorial-components.html">component</a> type
     *
     * @method SDK3DVerse.engineAPI#discardOverriddenComponent
     */
    discardOverriddenComponent(entities: Entity[], componentType: ComponentName) {
        const componentDescription = this.getComponentDescription(componentType);
        if (!componentDescription) {
            console.warn('Invalid component type', componentType);
            return;
        }

        const markAsDeleteCandidates = [];
        const entityToPropagate : Record<string, Entity> = {};

        let entitiesToDetach: Entity[] = [];
        for (let entity of entities) {

            const overrider = entity.getLocalOverrider();
            if(!overrider) {
                if(entity.isComponentMarkedAsNew(componentType)) {
                    markAsDeleteCandidates.push(entity);
                }
                continue;
            }

            if (componentType === 'local_aabb' && entity.overrider.isAttached('initial_local_aabb')) {
                overrider.detachComponent('initial_local_aabb');
            }
            overrider.detachComponent(componentType);

            let targetEntity = overrider.overriddenEntity;
            while(targetEntity.isOverrider() && !targetEntity.isAttached(componentType)) {
                targetEntity = targetEntity.overriddenEntity;
            }

            if (targetEntity.UNSAFE_isAttached(componentType)) {
                targetEntity.UNSAFE_setDirty(componentType);
                entityToPropagate[targetEntity.getID()] = targetEntity;
            }
            else {
                entitiesToDetach.push(targetEntity);
            }

        }

        if (entitiesToDetach.length > 0) {
            const entitiesToDetachComponentPerHash = {
                [componentDescription.hash]: entitiesToDetach.map(e => e.getComponent("euid").rtid)
            };
            this.ftlAPI.removeComponents(entitiesToDetachComponentPerHash);
        }

        // If a mesh_ref is discard, discard local_aabb as well.
        if (componentType === 'mesh_ref') {
            this.discardOverriderComponent(entities, 'local_aabb');
            return;
        }

        this.deleteOverriderIfNeeded(entities);

        if(markAsDeleteCandidates.length > 0) {
            this.markComponentAsDetached(markAsDeleteCandidates, componentType);
        }

        if(Object.keys(entityToPropagate).length > 0) {
            this.ftlAPI.propagateChanges(entityToPropagate);
        }
        this.commitChanges();
    }

    //--------------------------------------------------------------------------
    // deprecated
    discardOverriderComponent(entities: Entity[], componentType: ComponentName) {
        console.warn("discardOverriderComponent is deprecated, use discardOverriddenComponent instead");
        this.discardOverriddenComponent(entities, componentType);
    }

    //--------------------------------------------------------------------------
    /**
     * Discard all overridden components of entities.
     *
     * @param {Array.<Entity>} entities - Entities
     *
     * @method SDK3DVerse.engineAPI#discardOverriddenComponents
     */
    discardOverriddenComponents(entities: Entity[]) {
        const entityToPropagate : Record<string, Entity> = {};

        for (const entity of entities) {
            for (const componentName of entity.getComponentList()) {
                if (entity.isComponentOverridden(componentName)) {
                    entity.overrider.detachComponent(componentName);

                    if (entity.UNSAFE_isAttached(componentName)) {
                        entity.UNSAFE_setDirty(componentName);
                        entityToPropagate[entity.getID()] = entity;
                    }

                }
            }
        }

        this.deleteOverriderIfNeeded(entities);

        if(Object.keys(entityToPropagate).length > 0) {
            this.ftlAPI.propagateChanges(entityToPropagate);
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Delete external entities. They can be restored with [restoreExternalEntities]{@link SDK3DVerse.engineAPI#restoreExternalEntities}.
     *
     * @param {Array.<Entity>} entities - External entities to delete
     *
     * @method SDK3DVerse.engineAPI#deleteExternalEntities
     * @async
     */
    async deleteExternalEntities(entities: Entity[]) {
        for (const entity of entities) {
            await this.setOrCreateOverrider(entity, 'overrider', { deleter: true });
        }

        this.ftlAPI.deleteEntities(entities.map(e => e.getComponent("euid").rtid));
        this.commitChanges();
    }

    //--------------------------------------------------------------------------
    // deprecated
    async markAsDeleted(entities: Entity[]) {
        await this.deleteExternalEntities(entities);
    }

    //--------------------------------------------------------------------------
    /**
     * Restore external entities that were deleted with [deleteExternalEntities]{@link SDK3DVerse.engineAPI#deleteExternalEntities}.
     *
     * @param {Array.<Entity>} entities - External entities
     *
     * @method SDK3DVerse.engineAPI#restoreExternalEntities
     */
    restoreExternalEntities(entities: Entity[]) {
        for (const entity of entities) {
            entity.overrider.setComponent('overrider', { deleter: false });
            this.ftlAPI.createEntity(
                entity.getComponents(),
                entity.getLinker().getComponent("euid").rtid,
                entity.getParent().getComponent("euid").rtid,
            );
        }

        this.deleteOverriderIfNeeded(entities);
        this.commitChanges();
    }

    //--------------------------------------------------------------------------
    // deprecated
    restoreEntities(entities: Entity[]) {
        this.restoreExternalEntities(entities);
    }

    //--------------------------------------------------------------------------
    /**
     * Detach component from external entities. The component can be restored with
     * [restoreExternalComponent]{@link SDK3DVerse.engineAPI#restoreExternalComponent}.
     *
     * @param {Array.<Entity>} entities - External entities
     * @param {string} componentType - The <a href="tutorial-components.html">component</a> type
     *
     * @method SDK3DVerse.engineAPI#detachExternalComponent
     * @async
     */
    async detachExternalComponent(entities: Entity[], componentType: ComponentName) {
        const componentDescription = this.getComponentDescription(componentType);
        if (!componentDescription) {
            console.warn('Invalid component type', componentType);
            return;
        }

        for (const entity of entities) {
            const componentsToDetach = entity.isOverridden()
                ? entity.overrider.UNSAFE_getComponent('overrider').componentsToDetach
                : [];

            const componentSet = new Set(componentsToDetach);
            componentSet.add(componentDescription.hash);

            await this.setOrCreateOverrider(entity, 'overrider', { componentsToDetach: Array.from(componentSet) });
        }

        const entitiesToDetachComponentPerHash = {
            [componentDescription.hash]: entities.map(e => e.getComponent("euid").rtid)
        }
        this.ftlAPI.removeComponents(entitiesToDetachComponentPerHash);
        this.commitChanges();
    }

    //--------------------------------------------------------------------------
    // deprecated
    async markComponentAsDetached(entities: Entity[], componentType: ComponentName) {
        await this.detachExternalComponent(entities, componentType);
    }

    //--------------------------------------------------------------------------
    /**
     * Restore a component in external entities that had been detached with [detachExternalComponent]{@link SDK3DVerse.engineAPI#detachExternalComponent}
     *
     * @param {Array.<Entity>} entities - External entities
     * @param {string} componentType - The <a href="tutorial-components.html">component</a> type to restore
     *
     * @method SDK3DVerse.engineAPI#restoreExternalComponent
     * @async
     */
    async restoreExternalComponent(entities: Entity[], componentType: ComponentName) {
        const componentDescription = this.getComponentDescription(componentType);
        if (!componentDescription) {
            console.warn('Invalid component class name', componentType);
            return;
        }

        for (const entity of entities) {
            const componentsToDetach = entity.isOverridden()
                ? entity.overrider.UNSAFE_getComponent('overrider').componentsToDetach
                : [];

            await this.setOrCreateOverrider(
                entity,
                'overrider',
                {
                    componentsToDetach: componentsToDetach.filter(v => v != componentDescription.hash)
                }
            );

            entity.UNSAFE_setDirty(componentType);
            this.entityRegistry.UNSAFE_setDirtyEntity(entity);
        }

        this.ftlAPI.propagateChanges(entities);
        this.deleteOverriderIfNeeded(entities);
        this.commitChanges();
    }

    //--------------------------------------------------------------------------
    // deprecated
    async restoreComponent(entities: Entity[], componentType: ComponentName) {
        await this.restoreExternalComponent(entities, componentType);
    }

    //--------------------------------------------------------------------------
    // private
    async setOrCreateOverrider<T extends ComponentName>(entity: Entity, componentName: T, value: ComponentMap[T], forceSpaw = false) {
        if (entity.overrider) {
            if (entity.overrider.isExternal()) {
                return this.setOrCreateOverrider(entity.overrider, componentName, value, forceSpaw);
            }

            entity.overrider.setOrAttachComponent(componentName, value);
            return entity.overrider;
        }

        const linkerLineage = entity.getLinkerLineage().reverse();
        const entityTemplate: EntityTemplate = {};

        entityTemplate.debug_name = this.getComponentDefaultValue('debug_name');
        entityTemplate.debug_name.value = entity.getName();

        entityTemplate.overrider = this.getComponentDefaultValue('overrider');
        entityTemplate.overrider.entityRef.originalEUID = entity.getEUID();
        entityTemplate.overrider.entityRef.linkage = linkerLineage.map(l => l.getEUID());

        value = {
            ...entityTemplate[componentName],
            ...value
        };
        entityTemplate[componentName] = value;

        const parent = linkerLineage[0];
        let overrider;

        if (!entity.isRuntime() && !forceSpaw) {
            overrider = await this.createEntity(parent, entityTemplate, -1);
        }
        else {
            overrider = await this.spawnEntity(parent, entityTemplate);
        }

        overrider.setComponent(componentName, value);

        return overrider;
    }

    //--------------------------------------------------------------------------
    // private
    deleteOverriderIfNeeded(entities: Entity[]) {
        const entitiesToProcess: Entity[] = Array.from(entities);
        const overridersToDelete: Entity[] = [];
        for (const entity of entitiesToProcess) {

            if (!entity.isOverridden()) {
                continue;
            }

            // Recursively delete overriders that are not external
            if(entity.overrider.isExternal()) {
                entitiesToProcess.push(entity.overrider);
                continue;
            }

            if (entity.overrider.components.overrider.deleter) {
                continue;
            }

            if (entity.overrider.components.overrider.componentsToDetach.length > 0) {
                continue;
            }

            const entityComponents = Object.keys(entity.components) as ComponentName[];
            const overriderComponents = Object.keys(entity.overrider.components) as ComponentName[];

            const differences = overriderComponents.filter((componentClass) => {
                return entity.isComponentOverridable(componentClass) &&
                    !entityComponents.includes(componentClass);
            });

            if (differences.length > 0) {
                continue;
            }

            const overridenComponents = overriderComponents.filter((componentClass) => {
                return entity.isComponentOverridable(componentClass) &&
                    entityComponents.includes(componentClass);
            });

            if (overridenComponents.length > 0) {
                continue;
            }

            overridersToDelete.push(entity.overrider);

            entity.UNSAFE_detachComponent("overridden");
            entity.overrider = null;
        }

        if (overridersToDelete.length > 0) {
            this.deleteEntities(overridersToDelete);
        }
    }

    //--------------------------------------------------------------------------
    // private
    generateRigidbodyDefaultValue(entity: Entity)
    {
        if(entity.isAttached('box_geometry'))
        {
            return { centerOfMass : [...entity.getComponent('box_geometry').offset] };
        }
        if(entity.isAttached('sphere_geometry'))
        {
            return { centerOfMass : [...entity.getComponent('sphere_geometry').offset] };
        }
        if(entity.isAttached('capsule_geometry'))
        {
            return { centerOfMass : [...entity.getComponent('capsule_geometry').offset] };
        }
        if(entity.isAttached('cylinder_geometry'))
        {
            return { centerOfMass : [...entity.getComponent('cylinder_geometry').offset] };
        }

        return null;
    }

    //--------------------------------------------------------------------------
    // private
    tryGenerateComponentDefaultValue<T extends ComponentName>(entity: Entity, componentType: T) {
        if(!entity.isAttached('local_aabb'))
        {
            return null;
        }

        const localAABB = entity.getComponent('local_aabb');
        switch(componentType)
        {
            case 'box_geometry':
                return {
                    dimension: [
                        localAABB.max[0] - localAABB.min[0],
                        localAABB.max[1] - localAABB.min[1],
                        localAABB.max[2] - localAABB.min[2]
                    ],
                    offset: [
                        (localAABB.max[0] + localAABB.min[0]) * 0.5,
                        (localAABB.max[1] + localAABB.min[1]) * 0.5,
                        (localAABB.max[2] + localAABB.min[2]) * 0.5
                    ]
                } as ComponentMap[T];

            case 'sphere_geometry':
                return {
                    radius: Math.max(localAABB.max[0] - localAABB.min[0],
                        localAABB.max[1] - localAABB.min[1],
                        localAABB.max[2] - localAABB.min[2]) / 2,
                    offset: [
                        (localAABB.max[0] + localAABB.min[0]) * 0.5,
                        (localAABB.max[1] + localAABB.min[1]) * 0.5,
                        (localAABB.max[2] + localAABB.min[2]) * 0.5
                    ]
                } as ComponentMap[T];

            case 'capsule_geometry':
            {
                const radius = Math.max(localAABB.max[0] - localAABB.min[0], localAABB.max[2] - localAABB.min[2]) / 2;
                return {
                    radius: radius,
                    height: Math.max(0.1, (localAABB.max[1] - localAABB.min[1]) - (2*radius)),
                    axis: 1,
                    offset: [
                        (localAABB.max[0] + localAABB.min[0]) * 0.5,
                        (localAABB.max[1] + localAABB.min[1]) * 0.5,
                        (localAABB.max[2] + localAABB.min[2]) * 0.5
                    ]
                } as ComponentMap[T];
            }

            case 'cylinder_geometry':
            {
                const radius = Math.max(localAABB.max[0] - localAABB.min[0], localAABB.max[2] - localAABB.min[2]) / 2;
                return {
                    radius: radius,
                    height: localAABB.max[1] - localAABB.min[1],
                    axis: 1,
                    offset: [
                        (localAABB.max[0] + localAABB.min[0]) * 0.5,
                        (localAABB.max[1] + localAABB.min[1]) * 0.5,
                        (localAABB.max[2] + localAABB.min[2]) * 0.5
                    ]
                } as ComponentMap[T];
            }

            case 'rigid_body':
                return this.generateRigidbodyDefaultValue(entity) as ComponentMap[T];

            default:
                return null;
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    attachComponentWithDependencies<T extends ComponentName>(entity: Entity, newComponentClassName: T) {
        if (entity.isAttached(newComponentClassName)) {
            return;
        }

        const description = this.getComponentDescription(newComponentClassName);
        if(!description) {
            return;
        }

        const dependencies = description.dependencies || [];

        for (var i in dependencies) {
            if (!entity.isAttached(dependencies[i])) {
                this.attachComponentWithDependencies(entity, dependencies[i]);
            }
        }

        let defaultValue = this.tryGenerateComponentDefaultValue(entity, newComponentClassName);
        if(defaultValue === null) {
            defaultValue = this.getComponentDefaultValue(newComponentClassName);
        }

        entity.attachComponent(newComponentClassName, defaultValue);
    }

    //--------------------------------------------------------------------------
    // deprecated
    detachComponentWithReferences<T extends ComponentName>(entity: Entity, componentNameToDetach: T) {
        if (!entity.isAttached(componentNameToDetach)) {
            return;
        }

        const components = entity.getComponents();
        let componentName: ComponentName;
        for (componentName in components) {
            const dependencies = this.getComponentDescription(componentName)?.dependencies || [];

            if (dependencies.includes(componentNameToDetach)) {
                this.detachComponentWithReferences(entity, componentName);
            }
        }

        entity.detachComponent(componentNameToDetach);
    }

    //--------------------------------------------------------------------------
    // private
    getComponentDependencies(componentType: ComponentName): ComponentName[] {
        const dependencyClassNames = this.getComponentDescription(componentType)?.dependencies || [];
        const subDependencies = dependencyClassNames.map((name) => this.getComponentDependencies(name)).flat();

        return [...subDependencies, ...dependencyClassNames];
    }

    //--------------------------------------------------------------------------
    /**
     * Get entity.
     *
     * @param {string} entityRTID - Entity runtime identitifier, or rtid
     *
     * @see [Entity.getID]{@link Entity#getID}
     *
     * @returns {Entity} Entity.
     *
     * @method SDK3DVerse.engineAPI#getEntity
     */
    getEntity(entityRTID: string) {
        return this.entityRegistry.getEntity(entityRTID);
    }

    //--------------------------------------------------------------------------
    ensureReparent(movingEntities: Entity[], newParent: Entity) {
        const anyExternal = movingEntities.some(e => e.isExternal());
        if (anyExternal) {
            console.warn('Attempt to move external entities');
        }

        const parentExternal = newParent && newParent.isExternal();

        if (parentExternal) {
            console.warn('Attempt to move to an external entity');
        }

        //TODO: Test lineage as well, like in the editor backend

        return !anyExternal && !parentExternal;
    }

    //--------------------------------------------------------------------------
    // deprecated
    async reparentEntity(entityRTID: string, newParentRTID: string, keepGlobalTransform?: boolean, commit: boolean = true) {
        const entity = this.getEntity(entityRTID);
        const oldParentEntity = entity.getParent();
        const newParentEntity = this.getEntity(newParentRTID);

        if (entityRTID === newParentRTID) {
            console.warn("Cannot reparent entity in itself");
            return;
        }

        if (entity.isExternal()) {
            console.error("Attempt to reparent an external entity");
            return;
        }

        if (entity.isOverrider()) {
            console.error("Cannot reparent an overrider");
            return;
        }

        if (newParentEntity && newParentEntity.isExternal()) {
            console.error("Attempt to reparent a entity to an external parent");
            return;
        }

        // Attempt to prepare a sequence, could return false if a sequence is
        // already running (in simplify hierarchy for example). In that case
        // do not commit the sequence immediatly at the end of the function.
        const sequenceState = keepGlobalTransform
                            ? this.editorAPI.prepareSequence()
                            : false;

        if (keepGlobalTransform) {
            entity.computeGlobalTransformFromEntity(entity.getGlobalTransform(), newParentEntity);

            if(commit) {
                this.commitChanges();
            }
        }

        const reparentPromise = this.editorAPI.reparentEntity(
            entity.getEUID(),
            oldParentEntity ? oldParentEntity.getEUID() : null,
            newParentEntity ? newParentEntity.getEUID() : null,
            commit
        );

        if (sequenceState) {
            this.editorAPI.commitSequence("reparent-entity");
        }

        await reparentPromise;
    }

    //--------------------------------------------------------------------------
    /**
     * Reparent multiple entities to a given parent.
     *
     * @param {Array.<Entity>} entities - Entities
     * @param {Entity} parent - Parent entity
     * @param {boolean} [keepGlobalTransform=true] - Recalculate entity local transform after reparenting in order to preserve their previous global transform
     *
     * @fires onEntityReparent
     * @fires onEntitiesUpdated
     *
     * @method SDK3DVerse.engineAPI#reparentEntities
     */
    reparentEntities(entities, parent, keepGlobalTransform: boolean = true, commit: boolean = true) {
        // Deprecated reparentEntities takes rtids not Entity
        const rtidsProvided = entities.length > 0 && typeof entities[0] === 'string';
        const entityRTIDs   = rtidsProvided ? entities : entities.map(e => e.getID());
        const parentRTID    = rtidsProvided ? parent : parent.getID();

        const sequenceState = this.editorAPI.prepareSequence();

        const reparentPromises = entityRTIDs.map(entityRTID =>
            this.reparentEntity(entityRTID, parentRTID, keepGlobalTransform, commit)
        );

        if (sequenceState) {
            this.editorAPI.commitSequence("reparent-entities");
        }

        return Promise.all(reparentPromises);
    }

    //--------------------------------------------------------------------------
    // private
    simplifyHierarchy(targetEntity: Entity) {
        let parentToDelete = null;

        let candidate = targetEntity.getParent();
        while (candidate) {
            if (candidate.children.length > 1) {
                break;
            }

            const componentNames = Object.keys(candidate.getComponents());
            const hasComponents = componentNames.some(componentName => {
                return componentName != "debug_name" &&
                    componentName != "euid" &&
                    componentName != "lineage" &&
                    componentName != "local_transform" &&
                    componentName != "local_aabb" &&
                    componentName != "initial_local_aabb";
            });

            if (hasComponents) {
                break;
            }

            parentToDelete = candidate;
            candidate = candidate.getParent();
        }

        // No parent to delete means there is nothing to do.
        if (parentToDelete != null) {
            var targetParent = candidate;
            var newTransform = targetEntity.getGlobalTransform(targetParent);

            const newSequencePrepared = this.editorAPI.prepareSequence();

            targetEntity.setComponent("local_transform", newTransform);
            this.commitChanges();
            this.reparentEntity(targetEntity.getID(), targetParent ? targetParent.getID() : null);
            this.deleteEntities([parentToDelete]);

            if (newSequencePrepared) {
                this.editorAPI.commitSequence("simplify-hierarchy");
            }
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    async setEntityVisibility(entity: Entity, isVisible: boolean) {
        await this.editorAPI.setEntityVisibility(
            entity.getID(),
            isVisible,
            this.handleEntityVisibilityChangedEvent
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Find entities by their name. Name is case-sensitive.
     *
     * @param {...string} entityNames - Entity names
     *
     * @returns {Array.<Entity>} Entities having the specified names.
     *
     * @method SDK3DVerse.engineAPI#findEntitiesByNames
     * @async
     *
     * @example
     * const entities = await SDK3DVerse.engineAPI.findEntitiesByNames('my sphere', 'my cube', 'cat');
     */
    async findEntitiesByNames(...entityNames: string[]) {
        return await this.editorAPI.findEntitiesByNames(entityNames);
    }

    //--------------------------------------------------------------------------
    /**
     * Find entities by their euid.
     *
     * @param {string} euid - Entity uuid
     *
     * @returns {Array.<Entity>} Entities with same euid.
     *
     * @method SDK3DVerse.engineAPI#findEntitiesByEUID
     * @async
     *
     * @example
     * const entities = await SDK3DVerse.engineAPI.findEntitiesByEUID('027ed09b-4a33-49eb-b1bf-10aa5eb4c7c1');
     */
    findEntitiesByEUID(euid: string) {
        return this.editorAPI.resolveEntitiesByEUID(euid);
    }

    //--------------------------------------------------------------------------
    // deprecated
    selectEntity(entity: Entity, keepOldSelection: boolean, triggeredBy: string) {
        this.updateSelectedEntities([entity], keepOldSelection, triggeredBy);
    }

    //--------------------------------------------------------------------------
    /**
     * Select multiple entities. Selected entities are highlighted.
     *
     * @param {Array.<Entity>} entities - Entities to select
     * @param {boolean} [keepOldSelection=false] - Set to true to keep old selection, i.e. old selection stays highlighted
     * @param {string} [triggeredBy='selectEntities'] - The `triggeredBy` string passed to {@link event:onEntitySelectionChanged}
     *
     * @see [select]{@link Entity#select}
     * @see [unselectAllEntities]{@link SDK3DVerse.engineAPI#unselectAllEntities}
     *
     * @fires onEntitySelectionChanged
     *
     * @example
     * SDK3DVerse.engineAPI.selectEntities([entity1, entity2, entity3]);
     *
     * @method SDK3DVerse.engineAPI#selectEntities
     */
    selectEntities(entities: Entity[], keepOldSelection: boolean = false, triggeredBy: string = 'selectEntities') {
        this.updateSelectedEntities(entities, keepOldSelection, triggeredBy);
    }

    //--------------------------------------------------------------------------
     /**
     * Unselect all entities.
     *
     * @param {string} [triggeredBy='unselectAllEntities'] - The `triggeredBy` string passed to {@link event:onEntitySelectionChanged}
     *
     * @fires onEntitySelectionChanged
     *
     * @example
     * SDK3DVerse.engineAPI.unselectAllEntities();
     *
     * @see [selectEntities]{@link SDK3DVerse.engineAPI#selectEntities}
     * @see [select]{@link Entity#select}
     *
     * @method SDK3DVerse.engineAPI#unselectAllEntities
     */
    unselectAllEntities(triggeredBy: string = 'unselectAllEntities') {
        this.updateSelectedEntities([], false, triggeredBy);
    }

    //--------------------------------------------------------------------------
    /**
     * Casts a ray into the scene and returns the entity, the world space position and
     * the world space normal that the ray hits or "picks". If the ray does not hit anything,
     * entity is null. X and Y coordinates are expected to be
     * [viewport coordinates]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSSOM_view/Coordinate_systems#viewport},
     * i.e. the same coordinate system used in certain
     * [MouseEvents]{@link https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent}
     * (mouseup, dblclick, etc.).
     *
     * If `selectEntity` is true, the picked entity will be broadcasted
     * in the event [onEntitySelectionChanged]{@link event:onEntitySelectionChanged}, and will
     * be highlighted. If `keepOldSelection` is true, the previously selected entities will
     * also be broadcasted in the event, and will also stay highlighted.
     *
     * @param {number} x - X coordinate
     * @param {number} y - Y coordinate
     * @param {boolean} [selectEntity=false] - Set to true to select entity. Selected entities are highlighted
     * @param {boolean} [keepOldSelection=false] - Set to true to keep old selection, i.e. old selection stays highlighted
     * @param {boolean} [seekExternalLinker=true] - Set to false to avoid selecting linkers before the picked entity
     * @param {SDK_Vec3 | null} [planeNormal=null] - If picking does not encounter any entity, an intersection is computed on a defined plane represented in
     * [Hessian normal form]{@link https://mathworld.wolfram.com/HessianNormalForm.html} by a unit length normal vector and a distance.
     * Ignored on a viewport with an orthographic projection.
     * @param {number} [planeDistanceFromOrigin=0] - The signed distance from the origin to the plane. Ignored on a viewport with an orthographic projection.
     *
     * @fires onEntitySelectionChanged
     *
     * @returns {object} An object defined as follows `{ entity : {@link Entity} | null, pickedPosition : {@link SDK_Vec3}, pickedNormal : {@link SDK_Vec3} }`.
     *
     * @example
     * const canvas = document.getElementById('my_canvas_id');
     * canvas.addEventListener('mouseup', async (e) =>
     * {
     *      const selectEntity     = true;
     *      const keepOldSelection = e.ctrlKey;
     *      const {entity, pickedPosition, pickedNormal} = await SDK3DVerse.engineAPI.castScreenSpaceRay(e.clientX, e.clientY, selectEntity, keepOldSelection);
     *      entity ? console.log('Selected entity', entity.getName()) : console.log('No entity selected');
     *
     * }, false);
     *
     * @method SDK3DVerse.engineAPI#castScreenSpaceRay
     * @async
     */
    async castScreenSpaceRay(x: number, y: number, selectEntity: boolean = false, keepOldSelection: boolean = false, seekExternalLinker = true, planeNormal: SDK_Vec3 | null = null, planeDistanceFromOrigin = 0) {
        // i feel like this is badly named.. should it be pickEntity. castScreenSpaceRay makes it seem like we're not doing any "picking", or "highlighting"
        const clickedPosition = this.cameraAPI.computeLocalPositionInCanvas(x, y);
        const hoveredViewport = this.cameraAPI.getHoveredViewport(clickedPosition);
        if (!hoveredViewport || !hoveredViewport.getCamera()) {
            return { entity: null };
        }

        const areaSize = hoveredViewport.getAreaSize();
        const offset = hoveredViewport.getOffset();
        const viewportX = (clickedPosition[0] - offset[0]) / areaSize[0];
        const viewportY = (clickedPosition[1] - offset[1]) / areaSize[1];

        const { entityRTID, ...leftOver } = await this.ftlAPI.castScreenSpaceRay(
            hoveredViewport.getCamera().getComponent("euid").rtid,
            viewportX,
            viewportY,
            selectEntity,
            keepOldSelection
        );

        if (entityRTID == null) {
            if (selectEntity && !keepOldSelection) {
                this.updateSelectedEntities([], false, "picking");
            }

            const intersection = this.intersectPlane(
                planeNormal ? vec3.fromValues(...planeNormal) : null,
                planeDistanceFromOrigin,
                hoveredViewport,
                clickedPosition
            );

            if (intersection) {
                return {
                    entity: null,
                    ...leftOver,
                    pickedPosition: intersection,
                    pickedNormal: planeNormal
                };
            }

            return { entity: null, ...leftOver };
        }

        const entity = await this.resolveEntity(entityRTID);

        if (selectEntity) {
            let selectedEntity = entity;

            if (seekExternalLinker) {
                while (selectedEntity.isExternal()
                    && !(selectedEntity.isComponentOverridden("local_transform") && !selectedEntity.overrider.isExternal())
                    && !this.selectedEntities.includes(selectedEntity.linker)
                ) {
                    selectedEntity = selectedEntity.linker;
                }
            }

            this.updateSelectedEntities([selectedEntity], keepOldSelection, "picking");
        }
        return { entity, ...leftOver };
    }

    //--------------------------------------------------------------------------
    /**
     * Cast a ray into the physics scene and get the results of what the ray hits.
     *
     * @param {SDK_Vec3} origin - Point in global space to cast the ray from
     * @param {SDK_Vec3} direction - Direction to cast the ray
     * @param {number} rayLength - Magnitude of the ray
     * @param {PhysicsQueryFilterFlags} [filterFlags=SDK3DVerse.PhysicsQueryFilterFlag.dynamic_block | SDK3DVerse.PhysicsQueryFilterFlag.static_block] -
     *  See more about which {@linkplain SDK3DVerse#PhysicsQueryFilterFlag filter flags} you can set. Dynamic and static bodies are blocking by default.
     * @param {number} [maxNumTouches=12] - If SDK3DVerse.PhysicsQueryFilterFlag.record_touches was set in `filterFlags`, then specifies the maximum number of touches the ray records
     *
     * @returns {PhysicsRaycastResult} An object containing information on what physics body the ray was blocked by, and what other bodies it touched along the way.
     *
     * @example
     * const origin = [0,0,0];
     * const direction = [0,0,1];
     * const rayLength = 20;
     * const filterFlags = SDK3DVerse.PhysicsQueryFilterFlag.dynamic_block | SDK3DVerse.PhysicsQueryFilterFlag.record_touches;
     *
     * // Returns dynamic body (if the ray hit one) in block, and all static bodies encountered along the way in touches
     * const { block, touches } = await SDK3DVerse.engineAPI.physicsRaycast(origin, direction, rayLength, filterFlags);
     *
     * @method SDK3DVerse.engineAPI#physicsRaycast
     * @async
     */
    async physicsRaycast(origin: SDK_Vec3, direction: SDK_Vec3, rayLength: number, filterFlags: number = physics_query_filter_flag.dynamic_block | physics_query_filter_flag.static_block, maxNumTouches: number = 12) {
        const raycastQuery =
        {
            origin,
            direction,
            rayLength,
            filterFlags,
            maxNumTouches
        };

        const result = await this.ftlAPI.physicsRaycast(raycastQuery);

        const block = result.block.rtid == 0 ?  null :
                    {
                        entity      : await this.resolveEntity(result.block.rtid),
                        position    : result.block.position,
                        normal      : result.block.normal
                    };

        let touches = result.touches.map(async ({rtid, position, normal}) =>
        {
            return {
                entity : await this.resolveEntity(rtid),
                position,
                normal
            }
        });
        touches = await Promise.all(touches);

        return { block, touches };
    }

    //--------------------------------------------------------------------------
    intersectPlane(planeNormal: vec3, planeDistanceFromOrigin: number, viewport: Viewport, clickedPosition: [x: number, y: number]) {
        if (viewport.hasOrthographicProjection()) {
            return viewport.unproject(clickedPosition, 0.0);
        }

        if (!planeNormal) {
            return null;
        }

        const { origin, direction } = viewport.computeRayFromScreenCoordinates(clickedPosition);

        const denom = vec3.dot(direction, planeNormal)
        if (denom !== 0) {
            const t = -(vec3.dot(origin, planeNormal) + planeDistanceFromOrigin) / denom;
            if (t < 0) {
                return null;
            }

            return Array.from(vec3.scaleAndAdd(vec3.create(), origin, direction, t));
        }
        else if (vec3.dot(planeNormal, origin) + planeDistanceFromOrigin === 0) {
            return Array.from(origin);
        }
        else {
            return null;
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Retrieve list of entities whose name or euid matches `nameOrEUID`
     * and whose components passes the specified component filter. See {@tutorial components}.
     *
     * @param {string} nameOrEUID - Entity name or euid
     * @param {ComponentFilter} [componentFilter={ mandatoryComponents : [], forbiddenComponents : [] }] - Component filter containing mandatory and forbidden components
     *
     * @returns {Array.<Entity>} Entities that match the name or EUID and component filter.
     *
     * @method SDK3DVerse.engineAPI#findEntities
     * @example
     * const componentFilter = { mandatoryComponents : ['box_geometry'], forbiddenComponents : ['rigid_body']};
     * const entities = await SDK3DVerse.engineAPI.findEntities('my entity name', componentFilter);
     * @async
     */
    async findEntities(nameOrEUID: string, componentFilter: {mandatoryComponents?: ComponentName[], forbiddenComponents?: ComponentName[]}) {
        const filterObject = { searchString : nameOrEUID, mandatoryComponents : componentFilter.mandatoryComponents, forbiddenComponents : componentFilter.forbiddenComponents };
        const editorEntities = await this.editorAPI.sendRequestAsync("filter-entities", filterObject) as EditorEntity[];
        const entities: Entity[] = [];

        const threshold = 50;
        const awaitingTimeInMs = 20;
        let count = 0;

        for (const rtid in editorEntities) {
            const editorEntity = editorEntities[rtid];
            const ancestors = editorEntity.ancestors;

            for (const i in ancestors) {
                const ancestor = ancestors[i];
                await this.entityRegistry.getOrAddEntity(ancestor.rtid, ancestor);
            }

            const entity = await this.entityRegistry.getOrAddEntity(rtid, editorEntity);
            entities.push(entity);

            if (++count > threshold) {
                count = 0;
                await this.createSleepPromise(awaitingTimeInMs);
            }
        }

        return entities;
    }

    //--------------------------------------------------------------------------
    // deprecated
    searchEntities(filterObject: { searchString?: string; mandatoryComponents?: ComponentName[], forbiddenComponents?: ComponentName[] }) {
        return this.editorAPI.sendRequestAsync("filter-entities", filterObject);
    }

    //--------------------------------------------------------------------------
    /**
     * Retrieve list of entities whose components pass the specified component filter. See {@tutorial components}.
     *
     * @param {ComponentFilter} componentFilter - Component filter containing mandatory and forbidden components
     *
     * @returns {Array.<Entity>} Entities that pass the component filter.
     *
     * @method SDK3DVerse.engineAPI#findEntitiesByComponents
     * @async
     *
     * @example
     * const componentFilter = { mandatoryComponents : ['physics_material', 'rigid_body'], forbiddenComponents : ['sphere_geometry'] };
     * const entities = await SDK3DVerse.engineAPI.findEntitiesByComponents(componentFilter);
     */
    async findEntitiesByComponents(componentFilter: { mandatoryComponents?: ComponentName[], forbiddenComponents?: ComponentName[] }) {
        const editorEntities = await this.editorAPI.sendRequestAsync("find-entities-with-components", componentFilter) as EditorEntity[];
        const entities: Entity[] = [];

        const threshold = 50;
        const awaitingTimeInMs = 20;
        let count = 0;

        for (const rtid in editorEntities) {
            const editorEntity = editorEntities[rtid];
            const ancestors = editorEntity.ancestors;

            for (const i in ancestors) {
                const ancestor = ancestors[i];
                await this.entityRegistry.getOrAddEntity(ancestor.rtid, ancestor);
            }

            const entity = await this.entityRegistry.getOrAddEntity(rtid, editorEntity);
            entities.push(entity);

            if (++count > threshold) {
                count = 0;
                await this.createSleepPromise(awaitingTimeInMs);
            }
        }

        return entities;
    }

    //------------------------------------------------------------------------------
    // deprecated
    async filterEntities(componentFilter: { mandatoryComponents?: ComponentName[], forbiddenComponents?: ComponentName[] }) {
        return this.findEntitiesByComponents(componentFilter);
    }

    //------------------------------------------------------------------------------
    // private
    createSleepPromise(sleepTimeInMs: number) {
        return new Promise(function (resolve) {
            setTimeout(resolve, sleepTimeInMs)
        });
    }

    //------------------------------------------------------------------------------
    // private
    async resolveEntity(rtid: string) {
        const entity = this.entityRegistry.getEntity(rtid);
        if (entity) {
            return entity;
        }

        await this.editorAPI.resolveAncestors(rtid);
        return this.entityRegistry.getEntity(rtid);
    }

    //------------------------------------------------------------------------------
    /**
     * Get all currently selected entities.
     *
     * @returns {Array.<Entity>} Selected entities.
     *
     * @see [selectEntity]{@link SDK3DVerse.engineAPI#selectEntity}
     * @see [castScreenSpaceRay]{@link SDK3DVerse.engineAPI#castScreenSpaceRay}
     *
     * @method SDK3DVerse.engineAPI#getSelectedEntities
     */
    getSelectedEntities() {
        return this.selectedEntities;
    }

    //------------------------------------------------------------------------------
    // private
    updateSelectedEntities(selectedEntities: Entity[], keepOldSelection: boolean, triggeredBy: string) {
        let unselectedEntities: Entity[] = [];
        let newSelectedEntities: Entity[] = [];
        let selectionChanged = selectedEntities.length > 0;

        if (!keepOldSelection && this.selectedEntities.length > 0) {
            for (const entity of this.selectedEntities) {
                if (selectedEntities.includes(entity)) {
                    continue;
                }

                entity.selected = false;
                unselectedEntities.push(entity);
            }
            this.selectedEntities.length = 0;
            selectionChanged = true;
        }

        for (const newSelectedEntity of selectedEntities) {
            const index = this.selectedEntities.indexOf(newSelectedEntity);
            const entityAlreadySelected = index != -1;
            selectionChanged = true;

            if (keepOldSelection && entityAlreadySelected) {
                newSelectedEntity.selected = false;
                this.selectedEntities.splice(index, 1);
                unselectedEntities.push(newSelectedEntity);
            }
            else {
                newSelectedEntity.selected = true;
                this.selectedEntities.push(newSelectedEntity);
                newSelectedEntities.push(newSelectedEntity);
            }
        }

        if (selectionChanged) {
            const selectingClient = {
                clientUUID: this.streamer.clientUUID,
                userUUID: this.editorAPI.userUUID,
                sessionUUID: this.editorAPI.sessionUUID
            };

            this.editorAPI.patchClientColor(selectingClient);

            const message = {
                selectedEntities: newSelectedEntities.map(e =>
                ({
                    rtid: e.getID(),
                    ancestorRTIDs: e.getAncestors().map((ancestor) => ancestor.getID())
                })),
                unselectedEntities: unselectedEntities.map(e =>
                ({
                    rtid: e.getID(),
                    ancestorRTIDs: e.getAncestors().map((ancestor) => ancestor.getID())
                }))
            };

            this.editorAPI.handleSelectEntitiesEvent(message, selectingClient, false);
            this.notifier.emit('onEntitySelectionChanged', this.selectedEntities, unselectedEntities, triggeredBy);

            if (newSelectedEntities.length > 0 || unselectedEntities.length > 0) {
                this.editorAPI.sendEntitySelectCommand(newSelectedEntities, unselectedEntities);
            }

            this.highlightSelectedEntities();
        }
    }

    //------------------------------------------------------------------------------
    highlightSelectedEntities() {
        const highlightableEntities = this.selectedEntities.filter(entity => entity.isAttached("local_transform"));

        // Optimize highlight request by prune branches, if an ancestor is in the selection
        const entitiesToHighlight = highlightableEntities.filter(entity => {
            const ancestors = entity.getAncestors();
            return !highlightableEntities.some(e => ancestors.includes(e));
        });
        this.ftlAPI.highlightEntities(entitiesToHighlight.map(e => e.components.euid.rtid));
    }

    //------------------------------------------------------------------------------
    /**
     * Get entities at the root of the scene.
     *
     * @returns {Array.<Entity>}
     *
     * @method SDK3DVerse.engineAPI#getRootEntities
     * @async
     */
    async getRootEntities() {
        await this.editorAPI.onConnected;
        return this.entityRegistry.getRootEntities();
    }

    //------------------------------------------------------------------------------
    // deprecated.
    async getEntityChildren(entity: Entity, resolveIfMissing = true) {
        var children: Entity[] = [];
        for (var i in entity.children) {
            var childRTID = entity.children[i];

            if (this.entityRegistry.hasEntity(childRTID)) {
                children.push(this.entityRegistry.getEntity(childRTID));
            }
            else if (resolveIfMissing) {
                return await this.editorAPI.getChildren(entity);
            }
        }
        return children;
    }

    //------------------------------------------------------------------------------
    // private
    async getEntityDescendants(entity: Entity, resolveIfMissing: boolean, restrictToSameLineageValue = false) {
        let children: Entity[] = await this.getEntityChildren(entity, resolveIfMissing);

        if (restrictToSameLineageValue) {
            const lineageValue = entity.isAttached('lineage')
                ? entity.getComponent('lineage').value
                : [];

            children = children.filter(
                e => Utils.arraysEqual(lineageValue, e.getComponent('lineage').value)
            );
        }

        let descendants: Entity[] = [].concat(children);
        for (const child of children) {
            descendants = descendants.concat(await this.getEntityDescendants(child, resolveIfMissing, restrictToSameLineageValue));
        }

        return descendants;
    }

    //------------------------------------------------------------------------------
    // private
    undo() {
        this.editorAPI.sendRequest('undo');
    }

    //------------------------------------------------------------------------------
    // private
    redo() {
        this.editorAPI.sendRequest('redo');
    }

    //------------------------------------------------------------------------------
    /**
     * @private
     *
     * Propagate all entity-related changes, allowing modified entities to be updated at runtime.
     * **Propagated changes are transient**, meaning the modified entities will be **seen by other clients**,
     * but **will not be saved in the scene asset**. The entities that are propagated are those
     * that have been modified since the last call to `propagateEntities` or [commitEntities]{@link SDK3DVerse.engineAPI#commitEntities}
     * (whichever came last).
     *
     * @param {string} [propagater] The propagater passed to [onEntitiesUpdated]{@link onEntitiesUpdated} event
     *
     * @see [commitEntities]{@link SDK3DVerse.engineAPI#commitEntities}
     *
     * @fires onEntitiesUpdated
     *
     * @method SDK3DVerse.engineAPI#propagateEntities
     */
    propagateEntities(propagater?: string) {
        const updatedComponentByEUIDs : Record<string, ComponentName[]> = {};

        for (const entityRTID in this.entityRegistry.dirtyEntities) {
            const entity = this.entityRegistry.dirtyEntities[entityRTID];

            this.dirtyEntities[entityRTID] = entity;
            updatedComponentByEUIDs[entity.getEUID()] = Array.from(entity.dirtyComponents);
        }

        this.notifier.emit('onEntitiesUpdated', Object.values(this.entityRegistry.dirtyEntities), propagater, updatedComponentByEUIDs, [], null);
        this.entityRegistry.dirtyEntities = {};

        this.streamer.registerTask(
            'propagateEntities', () => {
                this.ftlAPI.propagateChanges(this.dirtyEntities);
                this.dirtyEntities = {};
            }
        );
    }

    //------------------------------------------------------------------------------
    // deprecated
    propagateChanges(propagater?: string) {
        this.propagateEntities(propagater);
    }

    //------------------------------------------------------------------------------
    /**
     * Save entity-related changes, allowing modified entities to be updated at runtime as well as saved.
     * **Saved changes are persistent**, meaning the modified entities will be **seen by other clients** of running sessions
     * referencing this scene and **saved in the scene asset**.
     *
     * @param {Array.<Entity> | null} [entities=null] - If null, then all unsaved entities (i.e. entities that have been modified since the last call to `saveEntities` or [Entity.save]{@link Entity#save}) are saved
     *
     * @example
     * SDK3DVerse.engineAPI.saveEntities();
     *
     * @method SDK3DVerse.engineAPI#saveEntities
     */
    saveEntities(entities: Entity[] | null = null) {

        if(!this.commitChangesTimeout) {
            this.commitChangesTimeout = setTimeout(() => {
                this.commitChangesTimeout = null;
                const entities = Array.from(this.pendingEntities);
                this.pendingEntities.clear();

                this.editorAPI.updateEntities(entities);
                for(const entity of entities) {
                    this.entityRegistry.cancelDirtyState(entity);
                }
            }, 0);
        }

        if(!entities) {
            for(const entityRTID in this.entityRegistry.unsavedEntities) {
                const entity = this.entityRegistry.unsavedEntities[entityRTID];
                this.entityRegistry.cancelDirtyState(entity);
                this.pendingEntities.add(entity);
            }

            this.entityRegistry.unsavedEntities = {};
            return;
        }

        for (const entity of entities) {
            this.entityRegistry.cancelDirtyState(entity);
            this.pendingEntities.add(entity);
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    commitChanges(entities: Entity[] | null = null) {
        this.saveEntities(entities);
    }

    //--------------------------------------------------------------------------
    // private
    getComponentTypes() {
        return Object.keys(this.editorAPI.componentClasses);
    }

    //--------------------------------------------------------------------------
    // deprecated
    getComponentClassNames() {
        return this.getComponentTypes();
    }

    //--------------------------------------------------------------------------
    // private
    getComponentDescription(componentClassName: ComponentName): ComponentDescription | null {
        return this.editorAPI.componentClasses[componentClassName] || null;
    }

    //--------------------------------------------------------------------------
    // private
    getComponentDefaultValue<T extends ComponentName>(componentType: T): ComponentMap[T] {
        return this.entityRegistry.getComponentDefaultValue(componentType);
    }

    //--------------------------------------------------------------------------
    /**
     * Get default readonly <a href="tutorial-settings.html">scene settings</a> of specified type.
     *
     * @param {string} settingsType - The <a href="tutorial-settings.html">settings</a> type
     *
     * @returns {SceneSettings} Default readonly settings of specified type.
     *
     * @example
     * const { gravity } = SDK3DVerse.engineAPI.getDefaultSceneSettings('physics');
     *
     * @method SDK3DVerse.engineAPI#getDefaultSceneSettings
     */
    getDefaultSceneSettings<T extends SettingName>(settingsType: T): Readonly<SettingsMap[T]> {
        if (!this.settingDefaultValues.hasOwnProperty(settingsType)) {
            const value = Utils.resolveDefaultValue(this.editorAPI.settingDescriptions, settingsType);
            this.settingDefaultValues[settingsType] = Object.freeze(value);
        }

        return this.settingDefaultValues[settingsType];
    }

    //--------------------------------------------------------------------------
    // deprecated
    getSettingDefaultValue<T extends SettingName>(settingsType: T): Readonly<SettingsMap[T]> {
        return this.getDefaultSceneSettings(settingsType);
    }

    //--------------------------------------------------------------------------
    /**
     * Get <a href="tutorial-settings.html">scene settings</a> of specified type.
     *
     * @param {string} settingsType - The <a href="tutorial-settings.html">settings</a> type
     *
     * @returns {SceneSettings} Scene settings.
     *
     * @example
     * const { maxTextureSize, enableTextureStreaming } = SDK3DVerse.engineAPI.getSceneSetting('display');
     *
     * @method SDK3DVerse.engineAPI#getSceneSettings
     */
    getSceneSettings<T extends SettingName>(settingsType: T): any {
        const defautValue = this.getSettingDefaultValue(settingsType);
        const sceneSettings = this.editorAPI.sceneSettings;
        const editorSetting = sceneSettings.hasOwnProperty(settingsType)
            ? sceneSettings[settingsType]
            : {};

        return {
            ...defautValue,
            ...editorSetting
        };
    }

    //--------------------------------------------------------------------------
    // deprecated
    getSceneSetting<T extends SettingName>(settingType: T): any {
        return this.getSceneSettings(settingType);
    }

    //--------------------------------------------------------------------------
    /**
     * Propagate scene settings, allowing settings to be updated at runtime.
     * **Propagated scene settings are transient**, meaning the scene settings will be **seen by other clients**, but **will not be saved**.
     *
     * @param {SceneSettingsMap} settings - Scene settings
     *
     * @see [saveSceneSettings]{@link SDK3DVerse.engineAPI#saveSceneSettings}
     * @see [getSceneSettings]{@link SDK3DVerse.engineAPI#getSceneSettings}
     *
     * @fires onSettingsUpdated
     *
     * @method SDK3DVerse.engineAPI#propagateSceneSettings
     */
    propagateSceneSettings(settings: SettingsMap) {
        this.checkSettings(settings);
        this.ftlAPI.updateConfig(settings);
    }

    //--------------------------------------------------------------------------
    // deprecated
    propagateSettings(settings: SettingsMap) {
        this.propagateSceneSettings(settings);
    }


    //--------------------------------------------------------------------------
    /**
     * Save scene settings, allowing settings to be updated at runtime as well as saved.
     * **Saved scene settings are persistent**, meaning the scene settings will be **seen by other clients** and **will be saved**.
     *
     * @param {SceneSettingsMap} settings - Scene settings
     *
     * @see [propagateSceneSettings]{@link SDK3DVerse.engineAPI#propagateSceneSettings}
     * @see [getSceneSettings]{@link SDK3DVerse.engineAPI#getSceneSettings}
     *
     * @fires onSettingsUpdated
     *
     * @method SDK3DVerse.engineAPI#saveSceneSettings
     */
    saveSceneSettings(settings: SettingsMap) {
        this.checkSettings(settings);
        this.editorAPI.sendRequest('update-settings', settings);
    }

    //--------------------------------------------------------------------------
    // deprecated
    commitSettings(settings: SettingsMap) {
        this.saveSceneSettings(settings);
    }

    //--------------------------------------------------------------------------
    checkSettings(settings: SettingsMap) {
        const settingDescriptions = this.editorAPI.settingDescriptions;
        let settingClass: SettingName;
        for (settingClass in settings) {
            if (!settingDescriptions.hasOwnProperty(settingClass)) {
                console.warn(`Invalid setting class name '${settingClass}' in provided settings`, settings);
                continue;
            }

            const settingValue = settings[settingClass];
            const settingDescription = settingDescriptions[settingClass];

            for (const attributeName in settingValue) {
                const attributeDescription = settingDescription.attributes.find(
                    a => a.name === attributeName
                );

                if (!attributeDescription) {
                    console.warn(`Invalid setting attribute name '${attributeName}' in provided settings`, settings);
                    continue;
                }

                const value = settingValue[attributeName];
                const defaultValue = this.getSettingDefaultValue(settingClass)[attributeName];
                const expectedType = typeof defaultValue;

                if (typeof value !== expectedType) {
                    console.warn(`Invalid setting attribute type '${attributeName}' in provided settings, expected type : ${expectedType}`, settings);
                }
            }
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Fire an event found in an event map with eventMapUUID.
     *
     * @param {string} eventMapUUID The eventMap asset UUID
     * @param {string} eventName The event name from the eventMap
     * @param {Array.<Entity>} [targetEntities=[]] Fire event only on specified target entities
     * @param {object} [dataObject={}] The input data of the event. The expected object
     * properties are defined by the input descriptor of the event in the event map.
     *
     * @see [registerToEvent]{@link SDK3DVerse.engineAPI#registerToEvent}
     * @see [unregisterFromEvent]{@link SDK3DVerse.engineAPI#unregisterFromEvent}
     *
     * @method SDK3DVerse.engineAPI#fireEvent
     */
    fireEvent(eventMapUUID: string, eventName: string, targetEntities: Entity[] = [], dataObject: unknown = {}) {
        const targetEntityRTIDs = targetEntities.map((targetEntity) => targetEntity.UNSAFE_getComponent("euid").rtid);
        this.ftlAPI.fireEvent(eventMapUUID, eventName, 0, targetEntityRTIDs, dataObject);
    }

    //--------------------------------------------------------------------------
    /**
     * Determines if you can make transient and persistent edits to the scene. Signifies
     * [Entity.save]{@link Entity#save} in addition to
     * [SDK3DVerse.engineAPI.saveEntities]{@link SDK3DVerse.engineAPI#saveEntities} will
     * function. Will always be false if not connected to the editor.
     *
     * To connect to editor check the connectToEditor option when you
     * [startSession]{@link SDK3DVerse#startSession},
     * [joinSession]{@link SDK3DVerse#joinSession}, or [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}.
     *
     * @returns {boolean} Return true if the scene is editable.
     *
     * @method SDK3DVerse.engineAPI#canEdit
     */
    canEdit() {
        return this.editorAPI.canEdit;
    }

    //--------------------------------------------------------------------------
    /**
     * Get scene statistics, such as the entity count and triangle count.
     *
     * @returns {object} An object defined as follows `{ entityCount : number, triangleCount : number, totalTriangleCount : number }`.
     * - `entityCount` : Entity count of scene and all its subscenes
     * - `triangleCount` : Triangle count of scene
     * - `totalTriangleCount` : Triangle count of scene and all its subscenes
     *
     * @method SDK3DVerse.engineAPI#getSceneStats
     */
    getSceneStats() {
        return this.editorAPI.stats;
    }

    //--------------------------------------------------------------------------
    /**
     * Export the entity and its descendants to a new scene.
     * @private
     *
     * @param {Entity} entity - Entity to export
     * @param {string} sceneName - Scene name to create
     * @param {string} workspaceUUID - Workspace unique identifier which will contains the created scene
     * @param {boolean} replaceByLinker - Replace the entity in parameter by a linker referencing the created scene
     * In that case the entity should not be external.
     *
     * @returns {object} An object defined as follows `{ sceneUUID : string, linker : {@link Entity} }`.
     * * `sceneUUID` : The created scene uuid
     * * `linker` : The created linker if replaceByLinker parameter is true
     *
     * @async
     * @method SDK3DVerse.engineAPI#exportEntityToScene
     */
    async exportEntityToScene(entity: Entity, sceneName: string, workspaceUUID: string, replaceByLinker, options: Record<string, boolean> = {}) {
        const {
            exportPosition = false,
            exportOrientation = false,
            exportScale = false
        } = options;

        const localTransform = entity.getComponent("local_transform");
        const globalTransform = entity.getGlobalTransform();
        const parentGlobalTransform = entity.getParentGlobalTransform();

        let linkerTransform = { ...localTransform };
        let rootNodeTransform = { ...Utils.identityTransform };

        if (exportPosition) {
            const localPosition = vec3.create();
            const invertedParentGlobalMatrix = mat4.create();
            const parentGlobalMatrix = entity.getParentGlobalMatrix();

            mat4.invert(invertedParentGlobalMatrix, parentGlobalMatrix);
            vec3.transformMat4(localPosition, vec3.fromValues(...Utils.identityTransform.position as SDK_Vec3), invertedParentGlobalMatrix);

            linkerTransform.position = Array.from(localPosition) as SDK_Vec3;
            rootNodeTransform.position = Array.from(globalTransform.position) as SDK_Vec3;
        }

        if (exportOrientation) {
            const localOrientation = quat.create();
            const parentGlobalOrientationConjugate = quat.create();
            const parentGlobalOrientation = quat.fromValues(...parentGlobalTransform.orientation);

            quat.conjugate(parentGlobalOrientationConjugate, parentGlobalOrientation);
            quat.mul(localOrientation, parentGlobalOrientationConjugate, quat.fromValues(...Utils.identityTransform.orientation as [x: number, y: number, z: number, w: number]));

            linkerTransform.orientation = Array.from(localOrientation) as SDK_Quat;
            rootNodeTransform.orientation = Array.from(globalTransform.orientation) as SDK_Quat;
        }

        if (exportScale) {
            const localScale = vec3.create();
            const parentGlobalScale = vec3.fromValues(...parentGlobalTransform.scale);

            vec3.div(localScale, vec3.fromValues(...Utils.identityTransform.scale as [x: number, y: number, z: number]), parentGlobalScale);

            linkerTransform.scale = Array.from(localScale) as SDK_Vec3;
            rootNodeTransform.scale = Array.from(globalTransform.scale) as SDK_Vec3;
        }

        rootNodeTransform = Utils.patchTransform(rootNodeTransform);
        linkerTransform = Utils.patchTransform(linkerTransform);

        const response = await this.editorAPI.exportEntityToScene(entity.getID(), sceneName, workspaceUUID, rootNodeTransform);

        if (response.hasOwnProperty("error")) {
            throw response.error;
        }

        const sceneUUID = response.createdSceneUUID;

        if (!replaceByLinker) {
            return { sceneUUID };
        }

        if (entity.isExternal()) {
            console.warn("Attempt to replace an external entity in exportEntityToScene", entity);
            return { sceneUUID };
        }

        const parent = entity.getParent();
        let ordinal = 0;
        let entityTemplate = {
            debug_name: { value: sceneName },
        };

        Utils.resolveComponentDependencies(entityTemplate, "scene_ref");
        entityTemplate["scene_ref"] = { value: sceneUUID };
        entityTemplate["local_transform"] = linkerTransform;

        if (entity.isAttached("lineage")) {
            ordinal = entity.getComponent("lineage").ordinal;
        }
        else if (parent) {
            const siblings = await this.getEntityChildren(parent, true);
            ordinal = siblings.reduce(
                (accumulator, entity) => {
                    if (entity.isExternal() || !entity.isAttached('lineage')) {
                        return accumulator;
                    }
                    const entityOrdinal = entity.getComponent('lineage').ordinal;
                    return entityOrdinal > accumulator ? entityOrdinal : accumulator;
                },
                0
            ) + 1;
        }

        this.editorAPI.prepareSequence();
        this.deleteEntities([entity]);
        const createEntityPromise = this.createEntity(parent, entityTemplate, ordinal);
        this.editorAPI.commitSequence("replace-entity");

        const linker = await createEntityPromise;
        return { sceneUUID, linker };
    }

    //--------------------------------------------------------------------------
    // private
    listenEditorEvents() {
        this.editorAPI.on('editor-connected', () => {
            this.ftlAPI.setComponentDescriptions(this.editorAPI.componentClasses);
            this.entityRegistry.setComponentDescriptions(this.editorAPI.componentClasses);
            this.cameraAPI.onEditorConnected();
            this.ftlAPI.updateSelectionColor(this.editorAPI.selectColor);
        });

        this.editorAPI.on('entities-deleted', (deletedEntityRTIDs, emitter) => {
            let unselectedEntityCount = 0;
            for (const deletedEntityRTID of deletedEntityRTIDs) {
                const index = this.selectedEntities.findIndex((e) => {
                    return e.getID() == deletedEntityRTID;
                });

                if (index != -1) {
                    this.selectedEntities.splice(index, 1);
                    ++unselectedEntityCount;
                }
            }

            if (unselectedEntityCount > 0) {
                this.notifier.emit('onEntitySelectionChanged', this.selectedEntities, [], 'entities-deleted');
            }

            this.notifier.emit('onEntitiesDeleted', deletedEntityRTIDs, emitter);
        });

        this.editorAPI.on('entity-created', (createdEntity, emitter) => {
            this.notifier.emit('onEntityCreated', createdEntity, emitter);
        });

        this.editorAPI.on('entity-visibility-updated', (data: { entityRTID: string, isVisible: boolean }, emitter) => {
            this.handleEntityVisibilityChangedEvent(data, emitter);
        });

        this.editorAPI.on('reparent-entity', (movingEntity, oldParentEntity, newParentEntity, emitter) => {
            this.notifier.emit('onEntityReparent', movingEntity, oldParentEntity, newParentEntity, emitter);
        });

        this.editorAPI.on('entities-overridden', () => {
            this.notifier.emit('onEntitySelectionChanged', this.selectedEntities, [], 'entities-overridden');
        });

        this.editorAPI.on('entities-updated', (entities, updatedComponentByEUIDs, emitter, updatedAncestors = []) => {
            this.notifier.emit('onEntitiesUpdated', entities, 'remote-operation', updatedComponentByEUIDs, updatedAncestors, emitter);
        });

        this.editorAPI.on('settings-updated', (updatedSettings) => {
            this.notifier.emit('onSettingsUpdated', updatedSettings);
        });

        this.editorAPI.on('editor-error', (error) => {
            this.notifier.emit('onEditorError', error);
        });

        this.editorAPI.on('error', (error) => {
            this.notifier.emit('error', error);
        });
    }

    //------------------------------------------------------------------------------
    /**
     * Registers the callback function to the `enter_trigger` physics event.
     *
     * @param {TriggerCallback} callback  - The callback function
     *
     * @example
     * SDK3DVerse.engineAPI.onEnterTrigger((entity, triggerEntity) =>
     * {
     *     console.log(entity, " entered trigger of ", triggerEntity);
     * });
     *
     * @method SDK3DVerse.engineAPI#onEnterTrigger
     */
    onEnterTrigger(callback: (entity: Entity, triggerEntity: Entity) => void) {
        this.scriptNotifier.on(`${this.PHYSICS_EVENT_MAP_UUID}/enter_trigger`,
        async ({emitterEntity, dataObject}) => {
            const entity = await this.editorAPI.resolveEntityRef(dataObject.hEntity);
            callback(entity, emitterEntity);
        });
    }

    //------------------------------------------------------------------------------
    /**
     * Registers the callback function to the `exit_trigger` physics event.
     *
     * @param {TriggerCallback} callback  - The callback function
     *
     * @example
     * SDK3DVerse.engineAPI.onExitTrigger((entity, triggerEntity) =>
     * {
     *     console.log(entity, " exited trigger of ", triggerEntity);
     * });
     *
     * @method SDK3DVerse.engineAPI#onExitTrigger
     */
    onExitTrigger(callback: (entity: Entity, triggerEntity: Entity) => void) {
        this.scriptNotifier.on(`${this.PHYSICS_EVENT_MAP_UUID}/exit_trigger`,
        async ({emitterEntity, dataObject}) => {
            const entity = await this.editorAPI.resolveEntityRef(dataObject.hEntity);
            callback(entity, emitterEntity);
        });
    }

    //------------------------------------------------------------------------------
    /**
     * Register callback function to the event named `eventName` belonging to the specified event map with UUID `eventMapUUID`.
     * Event maps are assets grouping events that can be fired with [fireEvent]{@link SDK3DVerse.engineAPI#fireEvent}.
     *
     * @param {string} eventMapUUID - Event map UUID
     * @param {string} eventName - Name of the event
     * @param {function} callback  - Callback function
     *
     * @see [unregisterFromEvent]{@link SDK3DVerse.engineAPI#unregisterFromEvent}
     * @see [fireEvent]{@link SDK3DVerse.engineAPI#fireEvent}
     *
     * @example
     * SDK3DVerse.engineAPI.registerToEvent(eventMapUUID, eventName, (event) =>
     * {
     *    const { emitterEntity, dataObject } = event;
	 *    console.log(`${emitterEntity} emitted the event ${eventName} with this data:`, dataObject);
     * });
     *
     * @method SDK3DVerse.engineAPI#registerToEvent
     */
    registerToEvent(eventMapUUID: string, eventName: string, listener: (event : any) => void) {
        this.scriptNotifier.on(eventMapUUID + '/' + eventName, listener);
    }

    //------------------------------------------------------------------------------
    /**
     * Unregister callback function from the event named `eventName` belonging to the specified event map with UUID `eventMapUUID`.
     * Event maps are assets grouping events that can be fired with [fireEvent]{@link SDK3DVerse.engineAPI#fireEvent}.
     *
     * @param {string} eventMapUUID - Event map UUID
     * @param {string} eventName - Name of the event
     * @param {function} callback  - Callback function
     *
     * @see [registerToEvent]{@link SDK3DVerse.engineAPI#registerToEvent}
     * @see [fireEvent]{@link SDK3DVerse.engineAPI#fireEvent}
     *
     * @method SDK3DVerse.engineAPI#unregisterFromEvent
     */
    unregisterFromEvent(eventMapUUID: string, eventName: string, handler: () => void) {
        this.scriptNotifier.off(eventMapUUID + '/' + eventName, handler);
    }

    //--------------------------------------------------------------------------
    /**
     * Assign client to the entity's script with UUID `scriptUUID`.
     * This allows the client's inputs to be used within the script.
     * Entity should have a <a href="tutorial-components_script_map.html">script_map</a> component.
     *
     * @private
     *
     * @param {Entity} entity Entity containing the script within its <a href="tutorial-components_script_map.html">script_map</a> component
     * @param {string} scriptUUID Script UUID
     *
     * @method SDK3DVerse.engineAPI#assignClientToScript
     */
    assignClientToScript(entity: Entity, scriptUUID: string){
        this.ftlAPI.assignClientToScript(this.streamer.clientUUID, scriptUUID, entity.UNSAFE_getComponent("euid").rtid);
    }

    //--------------------------------------------------------------------------
    /**
     * Assign client to all scripts attached to the entity. This allows the client's inputs to be used within the scripts.
     * Specify multiple entities to attach client to the scripts of all those entities at once.
     *
     * @param {...Entity} entity Entity containing a <a href="tutorial-components_script_map.html">script_map</a> component
     *
     * @example
     * SDK3DVerse.engineAPI.assignClientToScripts(entity);
     *
     * @method SDK3DVerse.engineAPI#assignClientToScripts
     */
    assignClientToScripts(...entities: Array<Entity>) {
        for (const entity of entities) {
            if(!entity.isAttached("script_map")){
                console.warn("Entity does not have a script_map component", entity);
                continue;
            }

            const scriptUUIDs = Object.keys(entity.getComponent("script_map").elements);

            for (const scriptUUID of scriptUUIDs) {
                this.assignClientToScript(entity, scriptUUID);
            }
        }
    }

    //--------------------------------------------------------------------------
    // deprecated
    attachToScript(entity: Entity, scriptUUID: string) {
        this.assignClientToScript(entity, scriptUUID);
    }

    //--------------------------------------------------------------------------
    /**
     * Detach client from all scripts attached to the entity. This stops the scripts from accessing the client's inputs.
     * Specify multiple entities to detach client from the scripts of all those entities at once.
     *
     * @param {...Entity} entity Entity containing a <a href="tutorial-components_script_map.html">script_map</a> component
     *
     * @example
     * SDK3DVerse.engineAPI.detachClientFromScripts(entity);
     *
     * @method SDK3DVerse.engineAPI#detachClientFromScripts
     */
    detachClientFromScripts(...entities: Array<Entity>) {
        for (const entity of entities) {
            if(!entity.isAttached("script_map")){
                console.warn("Entity does not have a script_map component", entity);
                continue;
            }

            const scriptUUIDs = Object.keys(entity.getComponent("script_map").elements);

            for (const scriptUUID of scriptUUIDs) {
                this.ftlAPI.assignClientToScript(Utils.invalidUUID, scriptUUID, entity.UNSAFE_getComponent("euid").rtid);
            }
        }
    }

    //--------------------------------------------------------------------------
    handleScriptEvent = async ({ emitterRTID, entityRTIDs, eventName, ...scriptEvent }: { emitterRTID: string; entityRTIDs: string[]; eventName: string }) =>
    {
        if (!this.scriptNotifier.listenerCount(eventName)) {
            return;
        }

        let emitterEntity = null;
        if (emitterRTID !== "0") {
            emitterEntity = await this.resolveEntity(emitterRTID);
        }

        const entities = await Promise.all(entityRTIDs.map(rtid => this.resolveEntity(rtid)));
        this.scriptNotifier.emit(eventName, { emitterEntity, entities, ...scriptEvent });
    }

    //--------------------------------------------------------------------------
    handleEntityVisibilityChangedEvent = async ({ entityRTID, isVisible }: { entityRTID: string, isVisible: boolean }, emitter: string) => {
        const targetedEntity = this.entityRegistry.getEntity(entityRTID);
        if (!targetedEntity) {
            return;
        }

        targetedEntity.visible = isVisible;

        const entities = await this.getEntityDescendants(targetedEntity, false);
        for (const entity of entities) {
            entity.visible = isVisible;
        }

        this.notifier.emit('onEntityVisibilityChanged', entityRTID, emitter);
    }

    //--------------------------------------------------------------------------
    onLoadingEnded = ({ status }: { status: string }) => {
        if (status === 'accepted') {
            this.ftlAPI.fetchAssetLoadingStatus();
        }
    }

    //--------------------------------------------------------------------------
    // private
    async batchOperations(sequenceName: string, operations: (() => Promise<void> | void)[]) {
        this.editorAPI.prepareSequence();
        const operationPromises = operations.map(fn => fn());
        this.editorAPI.commitSequence(sequenceName);
        return await Promise.all(operationPromises);
    }

    //--------------------------------------------------------------------------
    async computeBoundingBoxes() {
        const scenes = {};
        const dependencyGraph: [sceneUUID: string, sceneRef: string][] = [];

        const entitiesWithAABB = await this.filterEntities(
            {
                mandatoryComponents: ['local_aabb', 'mesh_ref']
            });

        const linkers = this.entityRegistry.getLinkers();

        const entities = [
            ...entitiesWithAABB,
            ...linkers
        ];

        for (const entity of entities) {
            const sceneUUID = entity.isExternal()
                ? entity.linker.getComponent("scene_ref").value
                : this.editorAPI.sceneUUID;

            if (!scenes.hasOwnProperty(sceneUUID)) {
                scenes[sceneUUID] = {
                    parentLinker: entity.isExternal() ? entity.linker : null,
                    entities: [],
                    aabb: {
                        min: vec3.fromValues(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
                        max: vec3.fromValues(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE)
                    }
                };
            }

            if (entity.isLinker()) {
                dependencyGraph.push([sceneUUID, entity.getComponent("scene_ref").value]);
            }

            const scene = scenes[sceneUUID];

            // Prevent to reference multiple instance of the same external entity
            if (entity.isExternal() && !entity.linker.isSame(scene.parentLinker)) {
                continue;
            }

            scene.entities.push(entity);
        }

        const sceneList = toposort(dependencyGraph).reverse();
        if (!sceneList.includes(this.editorAPI.sceneUUID)) {
            sceneList.push(this.editorAPI.sceneUUID);
        }

        for (const sceneUUID of sceneList) {
            const scene = scenes[sceneUUID];
            if (!scene) {
                debugger;
            }

            const sceneAABB = scene.aabb;
            for (const entity of scene.entities) {
                const globalMatrix = entity.getGlobalMatrix(scene.parentLinker);
                let localAABB: {
                    min: vec3,
                    max: vec3,
                } | null = null;

                if (entity.isLinker()) {
                    localAABB = scenes[entity.getComponent("scene_ref").value].aabb as {
                        min: vec3,
                        max: vec3,
                    };
                }
                else {
                    const entityLocalAABB = entity.UNSAFE_getComponent('local_aabb');
                    localAABB = {
                        min: vec3.fromValues(...entityLocalAABB.min as [x: number, y: number, z: number]),
                        max: vec3.fromValues(...entityLocalAABB.max as [x: number, y: number, z: number])
                    };
                }

                const vertex: vec3[] = [];
                vertex[0] = vec3.fromValues(localAABB.min[0], localAABB.min[1], localAABB.min[2]);
                vertex[1] = vec3.fromValues(localAABB.max[0], localAABB.min[1], localAABB.min[2]);
                vertex[2] = vec3.fromValues(localAABB.min[0], localAABB.max[1], localAABB.min[2]);
                vertex[3] = vec3.fromValues(localAABB.min[0], localAABB.min[1], localAABB.max[2]);

                vertex[4] = vec3.fromValues(localAABB.max[0], localAABB.max[1], localAABB.max[2]);
                vertex[5] = vec3.fromValues(localAABB.min[0], localAABB.max[1], localAABB.max[2]);
                vertex[6] = vec3.fromValues(localAABB.max[0], localAABB.min[1], localAABB.max[2]);
                vertex[7] = vec3.fromValues(localAABB.max[0], localAABB.max[1], localAABB.min[2]);

                const minVertex = vec3.fromValues(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
                const maxVertex = vec3.fromValues(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

                for (const vec of vertex) {
                    vec3.transformMat4(vec, vec, globalMatrix);
                    vec3.min(minVertex, vec, minVertex);
                    vec3.max(maxVertex, vec, maxVertex);
                }

                vec3.max(sceneAABB.max, maxVertex, sceneAABB.max);
                vec3.min(sceneAABB.min, minVertex, sceneAABB.min);
            }
        }

        console.log(scenes);

        for (const sceneUUID in scenes) {
            const scene = scenes[sceneUUID];
            this.editorAPI.updateAABB(
                sceneUUID,
                {
                    min: Array.from(scene.aabb.min) as SDK_Vec3,
                    max: Array.from(scene.aabb.max) as SDK_Vec3
                }
            );
        }

        return scenes;
    }

    /**
     * Start the simulation (e.g: physics and script assets listening to the event 'start' in the system.events event map).
     *
     * @see [pauseSimulation]{@link SDK3DVerse.engineAPI#pauseSimulation}
     * @see [stopSimulation]{@link SDK3DVerse.engineAPI#stopSimulation}
     *
     * @method SDK3DVerse.engineAPI#startSimulation
     */
    startSimulation() {
        this.fireEvent(Utils.invalidUUID, "start_simulation");
    }

    /**
     * Pause the simulation (e.g: physics and script assets listening to the event 'start' in the system.events event map).
     *
     * @see [startSimulation]{@link SDK3DVerse.engineAPI#startSimulation}
     * @see [stopSimulation]{@link SDK3DVerse.engineAPI#stopSimulation}
     *
     * @method SDK3DVerse.engineAPI#pauseSimulation
     */
    pauseSimulation() {
        this.fireEvent(Utils.invalidUUID, "pause_simulation");
    }

    /**
     * Stop the simulation (e.g: physics and script assets listening to the event 'start' in the system.events event map).
     *
     * @see [startSimulation]{@link SDK3DVerse.engineAPI#startSimulation}
     * @see [pauseSimulation]{@link SDK3DVerse.engineAPI#pauseSimulation}
     *
     * @method SDK3DVerse.engineAPI#stopSimulation
     */
    stopSimulation() {
        this.fireEvent(Utils.invalidUUID, "stop_simulation");
    }

    /**
     * @deprecated
     */
    async playAnimationSequence(animationSequenceUUID, settings = {}, linker = null) {
       return this._updateAnimationSequenceState(animationSequenceUUID, { ...settings, playState : 1 }, linker);
    }

    /**
     * @deprecated
     */
    pauseAnimationSequence(animationSequenceUUID, linker = null) {
        return this._updateAnimationSequenceState(animationSequenceUUID, { playState : 2 }, linker);
    }

    /**
     * @deprecated
     */
    stopAnimationSequence(animationSequenceUUID, linker = null) {
        return this._updateAnimationSequenceState(animationSequenceUUID, { playState : 0 }, linker);
    }

    //--------------------------------------------------------------------------------
    // private
    async _updateAnimationSequenceState(animationSequenceUUID, attributes, linker = null)
    {
        const entities = await this.findEntitiesByComponents({mandatoryComponents: ["animation_sequence_controller"]});

        for(const entity of entities)
        {
            if(entity.getComponent('animation_sequence_controller').animationSequenceRef !== animationSequenceUUID)
            {
                continue;
            }

            if(!linker || (entity.isExternal() && entity?.linker.isSame(linker)))
            {
                entity.UNSAFE_setComponent('animation_sequence_controller', attributes);
                entity.UNSAFE_setDirty('animation_sequence_controller');
                this.entityRegistry.UNSAFE_setDirtyEntity(entity);
            }
        }

        this.propagateEntities('updateAnimationSequenceState');
    }
}

/**
 * Component filter used to filter in mandatory components and filter out forbidden components.
 *
 * @typedef {object} ComponentFilter
 * @param {Array.<string>} mandatoryComponents - Mandatory <a href="tutorial-components.html">component</a> types
 * @param {Array.<string>} forbiddenComponents - Forbidden <a href="tutorial-components.html">component</a> types
 * @see {@tutorial components}
 * @see [SDK3DVerse.engineAPI.findEntities]{@link SDK3DVerse.engineAPI#findEntities}
 * @see [SDK3DVerse.engineAPI.findEntitiesByComponents]{@link SDK3DVerse.engineAPI#findEntitiesByComponents}
 * @example
 * {
 *      mandatoryComponents : ['physics_material', 'rigid_body'],
 *      forbiddenComponents : ['sphere_geometry']
 * }
 */

/**
 * The scene settings of a particular type. See settings types <a href="tutorial-settings.html">here</a>.
 *
 * @typedef {object} SceneSettings
 * @see {@tutorial settings}
 * @see [SDK3DVerse.engineAPI.getSceneSettings]{@link SDK3DVerse.engineAPI#getSceneSettings}
 * @see [SDK3DVerse.engineAPI.propagateSceneSettings]{@link SDK3DVerse.engineAPI#propagateSceneSettings}
 * @example
 * // These are the settings of environment type
 * {
 *      clearColor : [0,0,0],
 *      ambientColorTop : [1,1,1],
 *      ambientColorBottom : [1,1,1]
 * }
 */

/**
 * A map of scene settings type to [SceneSettings]{@link SceneSettings} object.
 *
 * @typedef {object} SceneSettingsMap
 * @see {@tutorial settings}
 * @see [SDK3DVerse.engineAPI.saveSceneSettings]{@link SDK3DVerse.engineAPI#saveSceneSettings}
 * @see [SDK3DVerse.engineAPI.propagateSceneSettings]{@link SDK3DVerse.engineAPI#propagateSceneSettings}
 * @example
 * {
 *      display : { drawDebugLines : true, drawCameraFrustum : true },
 *      sound : { enabled : true },
 *      environment : { ambientColorTop : [1, 0, 1], ambientColorBottom : [0, 0, 1] }
 * }
 */

/**
 * Components.
 * A collection of keys and values, where key is the component type and value is an object
 * containing the corresponding component's attributes.
 *
 * @typedef {object} ComponentsMap
 * @see {@tutorial components}
 * @example
 * {
 *      euid : { value : <entity uuid>, rtid : <entity rtid> },
 *      lineage : { parentUUID : <parent uuid>, ordinal : 0 },
 *      local_transform : { position : [0,0,0], orientation : [0,0,0,1], scale : [1,1,1], eulerOrientation : [0,0,0], globalEulerOrientation : [0,0,0] },
 *      mesh_ref : { value : <mesh uuid>, submeshIndex : 0 },
 *      material_ref : { value : <material uuid> }
 * }
 */

/**
 * Bit flags that contains filter information for physics queries like [physicsRaycast]{@link SDK3DVerse.engineAPI#physicsRaycast}.
 * Meant to be set using [SDK3DVerse.PhysicsQueryFilterFlag]{@link SDK3DVerse#PhysicsQueryFilterFlag}.
 *
 * @typedef {number} PhysicsQueryFilterFlags
 *
 * @example
 * // This PhysicsQueryFilterFlags specifies dynamic AND static bodies are blocking
 * SDK3DVerse.PhysicsQueryFilterFlag.dynamic_block | SDK3DVerse.PhysicsQueryFilterFlag.static_block
 */

/**
 * Object containing information about the location that was hit by a physics ray.
 *
 * @typedef {object} PhysicsRayHit
 * @property {Entity} entity - Entity that was hit by the ray
 * @property {SDK_Vec3} position - Global space position that the ray hit
 * @property {SDK_Vec3} normal - Global space normal of the physics geometry at the point where the ray hit
 *
 * @example
 * { entity, position, normal }
 */

/**
 * The return type of [physicsRaycast]{@link SDK3DVerse.engineAPI#physicsRaycast}. Object containing information about what the ray hit.
 *
 * @typedef {object} PhysicsRaycastResult
 * @property {PhysicsRayHit | null} block - Info about the location and physics body that blocked the ray. Can be null if no physics body blocked the ray
 * @property {Array.<PhysicsRayHit>} touches - Info about the location(s) and physics bod(ies) that the ray passed before being blocked
 *
 * @example
 * {
 *      block : { entity, position, normal },
 *      touches : [
 *          { entity, position, normal },
 *          { entity, position, normal },
 *      ]
 * }
 */

/**
 * Action emitter. Occurs during an entity event.
 *
 * @typedef Emitter
 * @property {string} clientUUID Client unique identifier in the session
 * @property {string} userUUID User unique identifier
 * @property {string} sessionUUID Session unique identifier where the action occurs
 */

/**
 * Event emitted when entity has been created.
 *
 * @event onEntityCreated
 * @property {Entity} entity - Entity that was created
 * @property {Emitter | null} emitter - Client emitting the event. Null if the client listening to this event initiated the creation
 *
 * @example SDK3DVerse.notifier.on('onEntityCreated', (entity) => { console.log(entity.getName(), 'was created!') });
 */

/**
 * Event emitted when one or more entities have been deleted.
 *
 * @event onEntitiesDeleted
 * @property {Array.<string>} deletedEntityRTIDs - An array of deleted entity rtids. See example below to convert them to {@link Entity}
 * @property {Emitter} emitter - Client emitting the event
 *
 * @example
 * SDK3DVerse.notifier.on('onEntitiesDeleted', (deletedEntityRTIDs) => {
 *      const deletedEntities = deletedEntityRTIDs.map((rtid) => SDK3DVerse.engineAPI.getEntity(rtid));
 * });
 */

/**
 * Event emitted when entity visibility has changed.
 * @example SDK3DVerse.notifier.on('OnEntityVisibilityChanged', (entityRTID) => {
 *     const entity = SDK3DVerse.engineAPI.getEntity(entityRTID);
 * });
 *
 * @see [Entity.setVisibility]{@link Entity#setVisibility}
 *
 * @event onEntityVisibilityChanged
 * @property {string} entityRTID - Entity rtid
 */

/**
 * Event emitted when entity has been reparented.
 * @example SDK3DVerse.notifier.on('onEntityReparent', (entity, prevParent, newParent) => {});
 *
 * @event onEntityReparent
 * @property {Entity} entity - The re-parented entity
 * @property {Entity} prevParent - Previous parent
 * @property {Entity} newParent - New parent
 * @property {Emitter} emitter - Client emitting the event
 */

/**
 * Event emitted when scene settings have been updated.
 * @example SDK3DVerse.notifier.on('onSettingsUpdated', (sceneSettingTypeToValue) => {});
 *
 * @event onSettingsUpdated
 * @property {object} sceneSettingTypeToValue Each property of this object corresponds to an updated scene setting
 */

/**
 * Event emitted when entity selection has changed.
 *
 * @example
 * SDK3DVerse.notifier.on('onEntitySelectionChanged', (selectedEntities, unselectedEntities) =>
 * {
 *      console.log('Selected', selectedEntities);
 *      console.log('Unselected', unselectedEntities);
 * });
 *
 * @see [Entity.select]{@link Entity#select}
 * @see [Entity.unselect]{@link Entity#unselect}
 *
 * @event onEntitySelectionChanged
 * @property {Array.<Entity>} selectedEntities - Entities that were selected
 * @property {Array.<Entity>} unselectedEntities - Entities that were unselected
 * @property {string} triggeredBy - If entity selection was triggered by [castScreenSpaceRay]{@link SDK3DVerse.engineAPI#castScreenSpaceRay} then this will be set
 *                                  to 'picking', otherwise this will be the `triggeredBy` param passed to [selectEntities]{@link SDK3DVerse.engineAPI#selectEntities}
 *                                  or [selectEntity]{@link Entity#selectEntity}.
 */

/**
 * Event emitted when entities have been modified or, i.e. their components have been modified.
 * [Entity.attachComponent]{@link Entity#attachComponent}, [Entity.setComponent]{@link Entity#setComponent},
 * [Entity.setGlobalTransform]{@link Entity#setGlobalTransform}, and [Entity.lookAt]{@link Entity#lookAt} are
 * functions that emit this event.
 *
 * Note: If a component is detached with [Entity.detachComponent]{@link Entity#detachComponent} this event is not fired.
 *
 * @event onEntitiesUpdated
 * @property {Array.<Entity>} entities - Array of updated entities
 * @property {string} triggeredBy - The function or module that triggered this event
 * @property {object} euidToUpdatedComponentTypes - An object associating entity UUID (or EUID) to its modified component types
 * @property {Array.<Entity>} updatedParents - Array of parents of updated entities. Empty array unless event emitted by [saveEntities]{@link SDK3DVerse.engineAPI#saveEntities}
 * @property {Emitter | null} emitter - Client emitting the event. null unless event emitted by [saveEntities]{@link SDK3DVerse.engineAPI#saveEntities}
 *
 * @example SDK3DVerse.notifier.on('onEntitiesUpdated', (entities) => {});
 */

/**
 * Callback function for physics trigger collider events used by:
 *   - [SDK3DVerse.engineAPI.onEnterTrigger]{@link SDK3DVerse.engineAPI#onEnterTrigger}
 *   - [SDK3DVerse.engineAPI.onExitTrigger]{@link SDK3DVerse.engineAPI#onExitTrigger}
 *
 * @callback TriggerCallback
 * @param {Entity} emitterEntity - The entity that has triggered the collider and has entered or exited it
 * @param {Entity} triggerEntity - The entity that has a trigger collider and was entered or exited
 *
 * @example
 * (emitterEntity, triggerEntity) => { console.log(emitterEntity, 'has entered or exited the trigger of', triggerEntity) }
 */

export default SDK3DVerse_EngineAPI;
