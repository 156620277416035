//------------------------------------------------------------------------------
import SDK3DVerse_Utils from './Utils';

//------------------------------------------------------------------------------
import type SDK3DVerse_EngineAPI from './EngineAPI';
import type { ComponentName, EntityTemplate as EntityTemplateMap } from 'types';
import type Entity from './Entity';

//------------------------------------------------------------------------------
declare var SDK3DVerse: {
    engineAPI: SDK3DVerse_EngineAPI;
};

//------------------------------------------------------------------------------
/**
 * Class used in preparation of instantiating an [Entity]{@link Entity}.
 * Attach components to your `EntityTemplate` object and instantiate it with [instantiateEntity]{@link EntityTemplate#instantiateEntity}.
 * You can instantiate multiple entities from the same `EntityTemplate`.
 */
class EntityTemplate {
    entityTemplate: EntityTemplateMap = { debug_name: { value: '' } };


     //-------------------------------------------------------------------------
    private static componentsToIgnore = ['debug_name', 'euid', 'lineage', 'initial_local_aabb', 'local_aabb', 'overridden'];

    //--------------------------------------------------------------------------
    /**
     * Attach component to the entity template. If component is already attached, overwrite the component's
     * specified attributes.
     *
     * This will attach the component and all the component's dependencies if the dependencies aren't already attached.
     * You can see the dependencies of each component in [this page]{@tutorial components}.
     *
     * @param {string} componentType - Component type
     * @param {object} [componentValue] - Component value. The component attributes that are not specified will be given default values
     *
     * @example
     * const entityTemplate = new SDK3DVerse.EntityTemplate();
     * // Attach component and its dependencies (local_transform is a dependency of mesh_ref)
     * entityTemplate.attachComponent('mesh_ref', { value : meshUUID });
     * // Overwrite already attached components by attaching them again
     * entityTemplate.attachComponent('local_transform', { position : [1, 0, 0]});
     * // Chain attachComponent
     * entityTemplate.attachComponent('box_geometry').attachComponent('physics_material').attachComponent('rigid_body');
     *
     * @returns {EntityTemplate} - Returns this `EntityTemplate` object. Allows chaining of `attachComponent`.
     */
    attachComponent<T extends ComponentName>(componentType: T, componentValue?: EntityTemplateMap[T]) {
        const componentDescription = SDK3DVerse.engineAPI.entityRegistry.componentDescriptions[componentType];
        if(!componentDescription) {
            console.warn('Invalid component type', componentType);
            return this;
        }

        if(!this.isAttached(componentType)) {
            SDK3DVerse_Utils.resolveComponentDependencies(this.entityTemplate, componentType);
        }

        if(componentType === 'local_transform') {
            componentValue = SDK3DVerse_Utils.patchTransform(componentValue);
        }

        Object.assign(this.entityTemplate[componentType], componentValue);
        return this;
    }

    //--------------------------------------------------------------------------
    /**
     * Attach all components of an entity to the entity template with their values.
     * If component is already attached, overwrite the component's specified attributes.
     *
     * @param {Entity} entity - Entity to attach components from
     *
     * @returns {EntityTemplate} - Returns this `EntityTemplate` object. Allows chaining of `attachComponent`.
     */
    attachEntityComponents(entity: Entity) {
        const componentTypes = entity.getComponentTypes().filter(
            componentType => !EntityTemplate.componentsToIgnore.includes(componentType)
        );

        for(const componentType of componentTypes) {
            const componentValue = entity.getComponent(componentType);
            this.attachComponent(componentType, componentValue);
        }

        return this;
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the specified component is attached to the entity template.
     * @param {string} componentType - Component type
     * @returns {boolean} Attached component state.
     */
    isAttached<T extends ComponentName>(componentType: T) {
        return this.entityTemplate.hasOwnProperty(componentType);
    }

    //--------------------------------------------------------------------------
    /**
     * Instantiate an [Entity]{@link Entity} in the scene.
     *
     * The entity will have the entity template's components attached. Some system components,
     * such as debug_name, euid, local_aabb, and lineage, will be automatically attached as well.
     *
     * @param {string} [name='unnamed entity'] - The entity's name that will be set in its <a href="tutorial-components_debug_name.html">debug_name</a> component
     * @param {Entity} [parent=null] - The parent of the entity. If null, the entity will be instantiated at the root
     *
     * @fires onEntityCreated
     *
     * @returns {Entity} - Entity that was instantiated.
     *
     * @async
     */
    async instantiateEntity(
        name = 'unnamed entity',
        parent: Entity | null = null,
    ): Promise<Entity> {
        const template = {
            ...this.entityTemplate,
            debug_name: { ...this.entityTemplate.debug_name, value: name },
        };

        return SDK3DVerse.engineAPI.createEntity(parent, template);
    }

    //--------------------------------------------------------------------------
    /**
     * Instantiate a transient [Entity]{@link Entity} in the scene.
     * Transient entities only exist for the duration of the session.
     *
     * The entity will have the entity template's components attached. Some system components,
     * such as debug_name, euid, local_aabb, and lineage, will be automatically attached as well.
     *
     * @param {string} [name='unnamed entity'] - The entity's name that will be set in its <a href="tutorial-components_debug_name.html">debug_name</a> component
     * @param {Entity} [parent=null] - The parent of the entity. If null, the entity will be instantiated at the root
     * @param {boolean} [deleteOnClientDisconnection=false] If true, entity will be deleted when the client that instantiated it disconnects from the session. If false,
     *                                                  entity will be deleted when the session terminates, i.e. once all clients disconnect
     *
     * @fires onEntityCreated
     *
     * @returns {Entity} - Transient entity that was instantiated.
     *
     * @async
     */
    async instantiateTransientEntity(
        name = 'unnamed entity',
        parent: Entity | null = null,
        deleteOnClientDisconnection = false,
    ): Promise<Entity> {
        const template = {
            ...this.entityTemplate,
            debug_name: { ...this.entityTemplate.debug_name, value: name },
        };

        if (deleteOnClientDisconnection) {
            template['entityCreationOptions'] = {
                deleteOnClientDisconnection: true,
            };
        }

        return SDK3DVerse.engineAPI.createTransientEntity(parent, template);
    }

    //--------------------------------------------------------------------------
    /**
     * Instantiate entities with different entity templates.
     *
     * @param {Entity | null} parent - The parent entity, set to null for the root
     * @param {Array.<EntityTemplate>} entityTemplates - The entity templates
     *
     * @see [instantiateEntity]{@link EntityTemplate#instantiateEntity}
     *
     * @fires onEntityCreated
     *
     * @returns {Array.<Entity>} The instantiated entities.
     *
     * @async
     */
    static async instantiateEntities(
        parent: Entity | null,
        entityTemplates: EntityTemplate[],
    ): Promise<Entity[]> {
        const parentUUID = parent ? parent.getEUID() : SDK3DVerse_Utils.invalidUUID;
        const templates = entityTemplates.map((t) => { return {...t.entityTemplate} });
        
        let id      = 0;
        let ordinal = 0;
        for (const template of templates) {
            if (!template.hasOwnProperty('lineage')) {
                template.lineage = { parentUUID, ordinal: ordinal++ };
            }

            if (!template.hasOwnProperty('euid')) {
                template.euid = { value: (id++).toString() };
            }

            if (template.hasOwnProperty('local_transform')) {
                template.local_transform = SDK3DVerse_Utils.patchTransform(template.local_transform);
            }
        }

        return await SDK3DVerse.engineAPI.editorAPI.sendCreateEntityRequest('create-entities', templates) as Promise<Entity[]>;
    }
}

export default EntityTemplate;
