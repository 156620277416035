//------------------------------------------------------------------------------
/**
 * @ignore
 */
export default /*abstract*/ class SDK3DVerse_WebAPI {
    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(defaultApiURL) {
        this.apiURL = defaultApiURL;
        this.sceneId = null;
        this.aabbPromises = new Map();

        if (new.target === SDK3DVerse_WebAPI) {
            throw new TypeError('Cannot construct Abstract instances directly');
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Set 3dverse api gateway URL. Can be used to override the api URL
     * which is 'https://api.3dverse.com/v0' by default.
     *
     * @param {string} apiURL - 3dverse api endpoint.
     *
     * @method SDK3DVerse.webAPI#setURL
     */
    setURL(apiURL) {
        this.apiURL = apiURL;
    }

    //------------------------------------------------------------------------------
    getURL(url) {
        return `${this.apiURL}/${url}`;
    }

    //--------------------------------------------------------------------------
    setSceneId(sceneId) {
        this.sceneId = sceneId;
        this.sceneUUID = sceneId; // backward compatibility
    }

    //--------------------------------------------------------------------------
    generateAuthHeaders() {
        return {};
    }

    //--------------------------------------------------------------------------
    /**
     * Create and join a new session from the 3dverse platform.
     *
     * @param {string} sceneUUID - Scene unique identifier
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI#createSession
     * @async
     */
    async createSession(sceneUUID) {
        throw 'Cannot call an abstract function';
    }

    //--------------------------------------------------------------------------
    /**
     * Join an existing session previously started on the 3dverse platform.
     *
     * @param {string} sessionUUID  - Session unique identifier
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI#joinSession
     * @async
     */
    async joinSession(sessionUUID, route = 'session/join') {
        throw 'Cannot call an abstract function';
    }

    //--------------------------------------------------------------------------
    /**
     * Find and join an existing session on the requested scene, or create a new
     * session if none are running.
     *
     * @param {string} sceneUUID - Scene unique identifier
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI#createOrJoinSession
     * @async
     */
    async createOrJoinSession(sceneUUID) {
        throw 'Cannot call an abstract function';
    }

    //--------------------------------------------------------------------------
    /**
     * Joins an existing session with an unidentified user
     *
     * @param {string} guestToken - Guest token retrieved from 'session/guestLink' API
     *
     * @method SDK3DVerse.webAPI#joinSessionAsGuest
     * @async
     */
    async joinSessionAsGuest(guestToken) {
        throw 'Cannot call an abstract function';
    }

    //------------------------------------------------------------------------------
    /**
     * Fetch the a scene AABB
     *
     * @param {string} [sceneId=current scene UUID] - The unique identifier of the scene asset
     *
     * @method SDK3DVerse.webAPI#getSceneAABB
     * @async
     */
    getSceneAABB(sceneId = this.sceneId) {
        if(this.aabbPromises.has(sceneId)) {
            return this.aabbPromises.get(sceneId);
        }

        const aabbPromise = this.fetchSceneAABB(sceneId);
        this.aabbPromises.set(sceneId, aabbPromise);
        return aabbPromise;
    }

    //------------------------------------------------------------------------------
    async fetchSceneAABB(sceneUUID) {
        throw 'Cannot call an abstract function';
    }

    //------------------------------------------------------------------------------
    async parseApiResponse(res, responseBody) {
        throw 'Cannot call an abstract function';
    }

    //------------------------------------------------------------------------------
    /**
     * Request data from the 3dverse API gateway
     *
     * @param {string} url - API gateway service url
     * @param {object} params - Requests parametersas query parameters
     *
     * @returns {Promise<any>} Response
     *
     * @method SDK3DVerse.webAPI#httpGet
     * @async
     */
    async httpGet(url, params) {
        return fetch(`${this.apiURL}/${url}?${new URLSearchParams(params).toString()}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                ...this.generateAuthHeaders(),
            },
        }).then(this.parseHttpResponse);
    }

    //------------------------------------------------------------------------------
    /**
     * Post a request to the 3dverse API gateway
     *
     * @param {string} url - API gateway service url
     * @param {object} params - Requests parameters as body
     *
     * @returns {Promise<any>} Response
     *
     * @method SDK3DVerse.webAPI#httpPost
     * @async
     */
    async httpPost(url, params) {
        return fetch(`${this.apiURL}/${url}`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json',
                ...this.generateAuthHeaders(),
            },
        }).then(this.parseHttpResponse);
    }

    //------------------------------------------------------------------------------
    /**
     * Send a delete request to the 3dverse API gateway
     *
     * @param {string} url - API gateway service url
     * @param {object} params - Requests parameters as body
     *
     * @returns {Promise<any>} Response
     *
     * @method SDK3DVerse.webAPI#httpDelete
     * @async
     */
    async httpDelete(url, params) {
        return fetch(`${this.apiURL}/${url}`, {
            method: 'DELETE',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json',
                ...this.generateAuthHeaders(),
            },
        }).then(this.parseHttpResponse);
    }

    //--------------------------------------------------------------------------
    parseHttpResponse = async res => {
        const contentType = res.headers.get('content-type');
        const isJSON = contentType?.includes('application/json');
        let responseBody = null;

        try {
            if (isJSON) {
                responseBody = await res.json();
            } else {
                responseBody = await res.text();
            }
        } catch {
            responseBody = null;
        }

        return this.parseApiResponse(res, responseBody);
    };

    //--------------------------------------------------------------------------
    toDataURL(url) {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest();
            xhr.onloadend = function () {
                if (xhr.status == 200) {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                } else {
                    xhr.onerror = null;
                    resolve(null);
                }
            };
            xhr.onerror = function () {
                xhr.onloadend = null;
                resolve(null);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }
}
