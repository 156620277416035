//----------------------------------------------------------------------------------
import Entity    from './Entity';
import SDK3DVerse_EngineAPI from './EngineAPI';
import SDK3DVerse_Utils     from './Utils'
import { ComponentName, EditorEntity } from 'types';

type OverriderMap = { overriders: Entity[]; next: Map<string, OverriderMap> };
export type ComponentDescription = { hash: string, dependencies: ComponentName[] };

//----------------------------------------------------------------------------------
export default class SDK3DVerse_EntityRegistry
{
    engineAPI: SDK3DVerse_EngineAPI;

    entities: Map<string, Entity>;
    euidMap: Map<string, Entity[]>;
    lineageOverriderMap: OverriderMap
    dirtyEntities: Record<string, Entity>;
    unsavedEntities: Record<string, Entity>;
    componentDefaultValues: Record<string, unknown>;
    rootLinkerEntity: Entity | null;
    componentDescriptions: Record<string, { hash: string }>;

    //------------------------------------------------------------------------------
    constructor(engineAPI : SDK3DVerse_EngineAPI)
    {
        this.engineAPI     = engineAPI;
        this.resetState();
    }

    //------------------------------------------------------------------------------
    resetState()
    {
        this.entities               = new Map();
        this.euidMap                = new Map();
        this.lineageOverriderMap    = {overriders : [], next : new Map()};

        this.dirtyEntities          = {};
        this.unsavedEntities        = {};
        this.componentDefaultValues = {};

        this.rootLinkerEntity       = null;
    }

    //------------------------------------------------------------------------------
    resolveEntity(rtid: string)
    {
        return this.engineAPI.resolveEntity(rtid);
    }

    //------------------------------------------------------------------------------
    async addEntity(editorEntity: EditorEntity)
    {
        let entity = this.getEntity(editorEntity.rtid);
        if(entity)
        {
            console.warn("Attempt to add an existing entity in the registry");
            return entity;
        }

        entity = new Entity(this.engineAPI, editorEntity);
        entity.resolveLinker();

        this.entities.set(entity.getID(), entity);

        if(!this.euidMap.has(entity.getEUID()))
        {
            this.euidMap.set(entity.getEUID(), []);
        }
        this.euidMap.get(entity.getEUID()).push(entity);

        if(entity.components.hasOwnProperty('overridden'))  //if(entity.isOverridden())
        {
            const overriderEntityRTID = entity.components.overridden.overriderEntityRTID;
            let overriderEntity = null;
            if(overriderEntityRTID)
            {
                overriderEntity = this.getEntity(overriderEntityRTID);
                if(!overriderEntity)
                {
                    overriderEntity = await this.resolveEntity(overriderEntityRTID);
                }
            }

            if(overriderEntity)
            {
                entity.overrider = overriderEntity;
                overriderEntity.overriddenEntity = entity;
            }
            else
            {
                console.warn(`Cannot find the overrider entity ${JSON.stringify(entity.components.overridden)}`);
            }
        }

        if(entity.isOverrider())
        {
            this.addOverriderToMap(entity);

            const overriddenEntityRTID = entity.components.overrider.overriddenEntityRTID;
            let overriddenEntity  = null;
            if(overriddenEntityRTID)
            {
                overriddenEntity = this.getEntity(overriddenEntityRTID);
                if(!overriddenEntity)
                {
                    overriddenEntity = await this.resolveEntity(overriddenEntityRTID);
                }
            }

            if(overriddenEntity)
            {
                overriddenEntity.overrider = entity;
                entity.overriddenEntity = overriddenEntity;
            }
            else
            {
                console.warn(`Cannot find the overridden entity ${JSON.stringify(entity.components.overrider)}`);
            }

            entity.updateComponentList();
        }

        return entity;
    }

    //------------------------------------------------------------------------------
    deleteEntity(entity: Entity, deletedEntityRTIDs: string[] | null = null, removeFromParent = true)
    {
        const rtid              = entity.getID();
        const childrenToDelete  = entity.children
                                    .map(rtid => this.entities.get(rtid))
                                    .filter(e => Boolean(e));

        // Remove from parent reference, if needed
        if(removeFromParent && entity.hasParent())
        {
            const parent = entity.getParent();
            // If the entity exist because a creation-event, the parent may not exist...
            // Not sure if this is really a good behavior.
            if(parent)
            {
                const index = parent.children.indexOf(entity.getID());
                if (index > -1)
                {
                    parent.children.splice(index, 1);
                }
            }
        }

        if(deletedEntityRTIDs)
        {
            deletedEntityRTIDs.push(rtid);
        }

        for(const child of childrenToDelete)
        {
            this.deleteEntity(child, deletedEntityRTIDs, false);
        }

        const entityArray   = this.euidMap.get(entity.getEUID());
        if(entityArray)
        {
            const index     = entityArray.findIndex(e => e.getID() == rtid);
            if(index != -1)
            {
                entityArray.splice(index, 1);
            }
        }

        this.entities.delete(rtid);
        delete this.dirtyEntities[rtid];
        delete this.unsavedEntities[rtid];

        if(entity.isOverrider())
        {
            this.removeOverriderFromMap(entity);
        }
    }

    //------------------------------------------------------------------------------
    addOverriderToMap(entity: Entity)
    {
        const { linkage = [] } = entity.getComponent('overrider').entityRef;

        const lineage = [
            ...linkage,
            ...entity.getComponent('lineage').value
        ].reverse();

        let currentMap = this.lineageOverriderMap;
        for(const entityEUID of lineage)
        {
            currentMap.overriders.push(entity);

            let nextMap = currentMap.next.get(entityEUID);
            if(!nextMap)
            {
                nextMap = { overriders : [], next : new Map() };
                currentMap.next.set(entityEUID, nextMap);
            }

            currentMap = nextMap;
        }

        currentMap.overriders.push(entity);
    }

    //------------------------------------------------------------------------------
    removeOverriderFromMap(entity: Entity)
    {
        const { linkage = [] } = entity.getComponent('overrider').entityRef;

        const lineage = [
            ...linkage,
            ...entity.getComponent('lineage').value
        ];

        const remove = (currentMap) =>
        {
            if(lineage.length > 0)
            {
                const entityEUID    = lineage.pop();
                const nextMap       = currentMap.next.get(entityEUID);

                if(nextMap)
                {
                    const isMapEmpty = remove(nextMap);
                    if(isMapEmpty)
                    {
                        currentMap.next.delete(entityEUID);
                    }
                }
                else
                {
                    console.warn(`Cannot find ${entityEUID} in map while removing overrider ${entity.getName()} (${entity.getID()})`);
                }
            }

            const index = currentMap.overriders.findIndex(e => e.isSame(entity));
            if(index != -1)
            {
                currentMap.overriders.splice(index, 1);
            }
            else
            {
                console.warn(`Cannot find overrider in list while removing overrider ${entity.getName()} (${entity.getID()})`);
            }

            return (currentMap.overriders.length === 0 && currentMap.next.size === 0);
        };

        remove(this.lineageOverriderMap);
    }

    //------------------------------------------------------------------------------
    getEntity(rtid: string)
    {
        return this.entities.get(rtid) || null;
    }

    //------------------------------------------------------------------------------
    async getOrAddEntity(rtid: string, editorEntity: EditorEntity)
    {
        var entity = this.getEntity(rtid);
        if(!entity)
        {
            entity = await this.addEntity(editorEntity);
        }
        return entity;
    }

    //------------------------------------------------------------------------------
    getEntitiesByEUID(euid: string)
    {
        return this.euidMap.get(euid) || [];
    }

    //------------------------------------------------------------------------------
    getRootEntities()
    {
        const entities = this.entities.size > 0 ? Array.from(this.entities.values()) : [];
        return entities.filter(entity => !entity.hasParent());
    }

    //------------------------------------------------------------------------------
    getLinkers()
    {
        const entities = this.entities.size > 0 ? Array.from(this.entities.values()) : [];
        return entities.filter(entity => entity.isLinker());
    }

    //------------------------------------------------------------------------------
    hasEntity(rtid: string)
    {
        return this.entities.has(rtid);
    }

    //------------------------------------------------------------------------------
    setDirtyEntity(entity: Entity)
    {
        this.UNSAFE_setDirtyEntity(entity);
        this.unsavedEntities[entity.getID()]   = entity;
    }

    //------------------------------------------------------------------------------
    UNSAFE_setDirtyEntity(entity: Entity)
    {
        this.dirtyEntities[entity.getID()]     = entity;
    }

    //--------------------------------------------------------------------------
    cancelDirtyState(entity: Entity)
    {
        this.UNSAFE_cancelDirtyState(entity);
        this.UNSAFE_cancelUnsavedState(entity);
    }

    //--------------------------------------------------------------------------
    UNSAFE_cancelUnsavedState(entity: Entity)
    {
        delete this.unsavedEntities[entity.getID()];
    }

    //--------------------------------------------------------------------------
    UNSAFE_cancelDirtyState(entity: Entity)
    {
        delete this.dirtyEntities[entity.getID()];
    }

    //--------------------------------------------------------------------------
    setComponentDescriptions(componentDescriptions: Record<string, ComponentDescription>)
    {
        this.componentDescriptions = componentDescriptions;
    }

    //------------------------------------------------------------------------------
    getComponentDefaultValue(componentClassName: string)
    {
        if(!this.componentDefaultValues.hasOwnProperty(componentClassName))
        {
            const component = SDK3DVerse_Utils.resolveDefaultValue(this.componentDescriptions, componentClassName);
            this.componentDefaultValues[componentClassName] = component;
        }

        return SDK3DVerse_Utils.clone(this.componentDefaultValues[componentClassName]);
    }
}
