import CanvasLoadingOverlayHTML from '!raw-loader!./CanvasLoadingOverlay.html';
import CanvasLoadingOverlayCSS  from '!raw-loader!sass-loader!./CanvasLoadingOverlay.scss';

const overlayStyle = document.createElement('style');
overlayStyle.innerHTML = CanvasLoadingOverlayCSS;
document.head.appendChild(overlayStyle);

/**
 * @param {HTMLElement} canvasContainer 
 * @param {boolean} [enabled] 
 * @returns {object} callbacks
 * @returns {Function} callbacks.updateStatusText
 * @returns {Function} callbacks.removeOverlay
 */
export function createLoadingOverlay(canvasContainer, enabled = true) {
    if (!enabled) {
        return {
            updateStatusText() {},
            removeOverlay() {}
        }
    }

    {
        const overlayElement = document.createElement('div');
        canvasContainer.appendChild(overlayElement);
        overlayElement.outerHTML = CanvasLoadingOverlayHTML;
    }
    // need to get a new reference to the overlay element after overwriting outerHTML
    const overlayElement = canvasContainer.querySelector('.rocket-loader-3dverse');

    let canvasParentStyleOverridden = false;

    if (!canvasContainer.style.position && getComputedStyle(canvasContainer).position === 'static') {
        // make sure parent element has relative positioning so we can
        // insert the overlay
        canvasContainer.style.position = 'relative';
        canvasParentStyleOverridden = true;
    }

    const statusTextElement = overlayElement.querySelector('.rocket-loader-3dverse__statustext');

    return {
        updateStatusText(text) {
            statusTextElement.textContent = text;
        },
        removeOverlay() {
            overlayElement.style.opacity = 0;
            setTimeout(() => {
                canvasContainer.removeChild(overlayElement);
                if (canvasParentStyleOverridden) {
                    canvasContainer.style.position = '';
                }
            }, 500);
        }
    }
}
