//------------------------------------------------------------------------------
import SDK3DVerse_WebAPI from './WebAPI';

//------------------------------------------------------------------------------
const CONSUMPTION_CONTROLLER_ERROR_NAMESPACE = [4000, 4100];

//------------------------------------------------------------------------------
/**
 * @ignore
 */
export default class SDK3DVerse_WebAPI_v1 extends SDK3DVerse_WebAPI {
    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(url = 'https://api.3dverse.com/app/v1') {
        super(url);
        this.sceneId;
    }

    //--------------------------------------------------------------------------
    /**
     * Set 3dverse user ID
     *
     * @param {string} userId - 3dverse user id.
     *
     * @method SDK3DVerse.webAPI.v1#setUserId
     */
    setUserId(userId) {
        this.userUUID = userId;
    }

    //--------------------------------------------------------------------------
    /**
     * Set 3dverse user token
     *
     * @param {string} userToken - 3dverse user token.
     *
     * @method SDK3DVerse.webAPI.v1#setUserToken
     */
    setUserToken(userToken) {
        this.userToken = userToken;
    }

    //--------------------------------------------------------------------------
    generateAuthHeaders() {
        return {
            user_token: this.userToken,
        };
    }

    /**
     * @param {string} sceneId
     * @param {object} [options]
     * @param {boolean} [options.isTransient = false]
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI.v1#createSession
     */
    async createSession(sceneId, { isTransient = false } = {}) {
        this.sceneId = sceneId;
        const { session_id } = await this.httpPost('sessions', {
            scene_id: sceneId,
            is_transient: isTransient
        });
        return await this.joinSession(session_id);
    }

    /**
     * @param {string} sessionId
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI.v1#joinSession
     */
    async joinSession(sessionId) {
        this.sessionId = sessionId;
        const { endpoint_info, session_token } = await this.httpPost(`sessions/${sessionId}/clients`, {
            session_id: sessionId,
        });
        return {
            ip: endpoint_info.ip,
            port: endpoint_info.port,
            sslport: endpoint_info.ssl_port,
            endpoint: endpoint_info,
            sessionKey: session_token,
        };
    }

    /**
     * @private
     */
    async joinOrCreateSession(sessionId, sceneId, { retriesLeft, isTransient }) {
        try {
            return await this.joinSession(sessionId);
        } catch (err) {
            /**
             * In some cases the session might have been removed (due to
             * no clients) in between the time we discovered it and the
             * time we tried to join it.
             *
             * One possible case is during a page refresh.
             */
            if (retriesLeft && err instanceof HttpError && err.code === 404) {
                return this.createOrJoinSession(sceneId, {
                    retriesLeft: retriesLeft - 1,
                    isTransient,
                });
            }
            throw err;
        }
    }

    /**
     * Constraints of a session that can be specified to [joinOrStartSession]{@link SDK3DVerse#joinOrStartSession}.
     *
     * @typedef {object} SessionConstraints
     * @property {string} [creator_user_id] User id of the user that created the scene
     * @property {string} [country_code] Country code that session will try to open in
     * @property {string} [continent_code] Continent code that session will try to open in
     */

    /**
     *
     * @param {string} sceneId
     * @param {object} [options]
     * @param {number} [options.retriesLeft = 1]
     * @param {boolean} [options.isTransient = false]
     * @param {SessionConstraints} [options.constraints]
     *
     * @returns {object} connectionInfo - Connection information required by [startStreamer]{@link SDK3DVerse#startStreamer}
     *
     * @method SDK3DVerse.webAPI.v1#createOrJoinSession
     */
    async createOrJoinSession(
        sceneId,
        { retriesLeft = 1, isTransient = false, constraints = {} } = {}
    ) {
        this.setSceneId(sceneId);

        // Ensure to join a session that respects the isTransient option value
        constraints.is_transient_session = isTransient;

        const getFirstSessionForConstraints = async () => {
            const sessionList = await this.getSceneSessionlist(sceneId);
            return sessionList.find(session => {
                if (session.clients.length >= session.max_users) {
                    return false;
                }
                for (const [key, value] of Object.entries(constraints)) {
                    if (session[key] !== value) {
                        return false;
                    }
                }
                return true;
            });
        };
        const existingSession = await getFirstSessionForConstraints();
        if (existingSession) {
            return this.joinOrCreateSession(existingSession.session_id, sceneId, {
                retriesLeft,
                isTransient,
            });
        }

        const connectionInfo = await this.createSession(sceneId, { isTransient });
        const newExistingSession = await getFirstSessionForConstraints();
        if (newExistingSession && newExistingSession.session_id !== this.sessionId) {
            this.killSession(this.sessionId).catch(console.warn);
            // someone else created a newer session at the same time so we should join that one instead
            return this.joinOrCreateSession(newExistingSession.session_id, sceneId, {
                retriesLeft,
                isTransient,
            });
        }
        connectionInfo.sessionCreated = true;
        return connectionInfo;
    }

    //--------------------------------------------------------------------------
    async killSession(sessionId) {
        await this.httpDelete(`sessions/${sessionId}`, {
            session_id: sessionId,
        });
    }

    //--------------------------------------------------------------------------
    // private
    fetchSceneAABB(sceneId) {
        return this.httpGet(`assets/scenes/${sceneId}/aabb`);
    }

    //------------------------------------------------------------------------------
    // private
    getAssetWorkspace(assetType, assetId) {
        throw new Error('Deprecated');
    }

    /**
     * Info on a client of a session. See {@link SessionInfo}.
     *
     * @typedef {object} ClientInfo
     * @property {string} [client_id] Id of the client
     * @property {'user' | 'guest'} [client_type] Client type, either a user or a guest
     * @property {string} user_id Id of 3dverse user that created the client
     * @property {string} username Username of 3dverse user that created the client
     */

    /**
     * Info on a session that can be retrieved by a call to [findSessions]{@link SDK3DVerse#findSessions}.
     *
     * @typedef {object} SessionInfo
     * @property {string} session_id Id of the session. This id can be passed to [joinSession]{@link SDK3DVerse#joinSession}
     * @property {string} scene_id UUID of scene that the session has opened
     * @property {string} scene_name Name of scene that the session has opened
     * @property {string} folder_id Id of the folder that the scene is located in
     * @property {number} max_users Maximum number of clients allowed in the session
     * @property {string} creator_user_id Id of the user that created the scene
     * @property {string} created_at Time the user created the scene
     * @property {string} country_code Country code that the session is running in
     * @property {string} continent_code Continent code that the session is running in
     * @property {ClientInfo[]} clients Array of {@link ClientInfo} that contains info on all the clients in the session
     */

    /**
     * @private
     * @async
     * @param {string} sceneId
     * @returns {Promise<SessionInfo[]>}
     */
    async getSceneSessionlist(sceneId) {
        const sessions = await this.httpGet(`assets/scenes/${sceneId}/sessions`);
        return sessions.sort((a, b) => (a.created_at > b.created_at ? 1 : a.created_at < b.created_at ? -1 : 0));
    }

    //------------------------------------------------------------------------------
    parseApiResponse(res, responseBody) {
        if (res.status >= 200 && res.status < 400) {
            return responseBody;
        }

        const errorCode = responseBody.errorCode;
        let error = responseBody.serviceResponse || responseBody;

        const errorMessage =
            errorCode && error.message
                ? error.message
                : `${res.status} ${res.statusText}`;

        const isConsumptionError =
            CONSUMPTION_CONTROLLER_ERROR_NAMESPACE[0] <= errorCode
            && errorCode < CONSUMPTION_CONTROLLER_ERROR_NAMESPACE[1];

        const message = isConsumptionError
            ? errorMessage
            : `API Error ${errorCode} : ${errorMessage}`;

        throw new HttpError(message, error.httpCode, error.details);
    }
}

//------------------------------------------------------------------------------
class HttpError extends Error {

    constructor(message, code, details) {
        super(message);
        this.code = code;
        if(details) {
            this.details = details;
        }
    }
}
