import Decoder from '../externals/Decoder';
import YUVCanvas from '../externals/YUVCanvas';

//------------------------------------------------------------------------------
const codecs = {
    avc: ['avc1.42001E', 'avc1.42002A', 'avc1.42E01E'],
    hvc: ['hvc1.1.6.L123.00']
};

//------------------------------------------------------------------------------
const QUEUE_SIZE_THRESHOLD = 30;

export default class SDK3DVerse_Decoder
{
    //------------------------------------------------------------------------------
    static decoderConfig = {
        hardwareAcceleration: "prefer-hardware",
        optimizeForLatency: true,
    };

    //------------------------------------------------------------------------------
    constructor(notifier)
    {
        this.notifier      = notifier;
        this.resetState();
    }

    //------------------------------------------------------------------------------
    resetState()
    {
        this.lastFrame     = null;
        this.firstFrame    = true;
        this.isInitialized = false;
    }

    //------------------------------------------------------------------------------
    // Ensure the decoder config is valid and modify value if it isn't.
    static async ensureConfig(displayConfig)
    {
        if(!displayConfig.hardwareDecoding)
        {
            return;
        }

        const webCodecSupport = !!window.VideoDecoder;
        if (!webCodecSupport)
        {
            console.warn('Browser does not support Video decoder extension, falling back to software decoding.');
            displayConfig.hardwareDecoding = false;
            displayConfig.hevcSupport      = false;
            return;
        }

        if (!isSecureContext)
        {
            console.warn('VideoDecoder is only available in secure contexts (HTTPS), falling back to software decoding.');
            displayConfig.hardwareDecoding = false;
            displayConfig.hevcSupport      = false;
            return;
        }

        const baseConfig = {
            ...SDK3DVerse_Decoder.decoderConfig,
            codedWidth: displayConfig.canvasWidth,
            codedHeight: displayConfig.canvasHeight
        };

        if(displayConfig.hevcSupport)
        {
            for (const codec of codecs.hvc)
            {
                console.info("Checking HEVC support for", codec);
                const { supported, config } = await VideoDecoder.isConfigSupported({ codec, ...baseConfig });
                if (supported)
                {
                    SDK3DVerse_Decoder.decoderConfig = config;
                    return;
                }
            }

            console.warn("Your system does not support HEVC decoding, falling back to H264.");
            displayConfig.hevcSupport = false;
        }

        // Forcing software decoding for now, as hardware decoding with H264 has delay issues.
        baseConfig.hardwareAcceleration = 'prefer-software';

        for (const codec of codecs.avc)
        {
            console.info("Checking H264 support for", codec);
            const { supported, config } = await VideoDecoder.isConfigSupported({ codec, ...baseConfig });
            if (supported)
            {
                SDK3DVerse_Decoder.decoderConfig = config;
                return;
            }
        }

        console.warn("Your system does not support H264 hardware decoding, falling back to software decoding.");
        displayConfig.hardwareDecoding = false;
    }

    //------------------------------------------------------------------------------
    initialize(config)
    {
        this.isHardwareDecoding = config.display.hardwareDecoding;
        this.isHevc             = config.display.hevcSupport;
        this.firstFrame         = true;

        this.canvasWidth        = config.display.canvasWidth;
        this.canvasHeight       = config.display.canvasHeight;

        this.metadataQueue      = [];
        this.queueMetadata      = false;
        this.targetWidth        = config.resolution[0];
        this.targetHeight       = config.resolution[1];

        this.then               = Date.now();
        this.interval           = 1000 / config.renderingFrequency;

        this.offscreenCanvas    = new OffscreenCanvas(this.targetWidth, this.targetHeight);

        if(this.isHardwareDecoding)
        {
            this.videoDecoder           = new VideoDecoder({
                output: this.onVideoFrameDecoded,
                error: (e) => console.error('VideoDecoder error', e)
            });

            this.canvasBufferContext    = this.offscreenCanvas.getContext("2d");
            this.queueMetadata          = true;
            this.videoDecoder.addEventListener('dequeue', this.onVideoDecoderDequeueEvent);
        }
        else
        {
            console.log('Using software decoding');

            this.decoder        = new Decoder();
            this.decoder.onPictureDecoded = this.onFrameDecoded;
            this.createYuvCanvas();

            this.onFocusHandler = () =>
            {
                this.lastFrame = this.cachedFrame;
            }
            window.addEventListener("focus", this.onFocusHandler , false);
        }

        this.resize(this.targetWidth, this.targetHeight, this.canvasWidth, this.canvasHeight);
        this.triggerRenderLoop();
        this.isInitialized = true;
    }

    //--------------------------------------------------------------------------
    configureVideoDecoder(width, height)
    {
        const baseConfig = {
            ...SDK3DVerse_Decoder.decoderConfig,
            codedWidth: width,
            codedHeight: height
        };

        this.videoDecoder.configure(baseConfig);
        console.info("VideoDecoder configured with", baseConfig);
    }

    //--------------------------------------------------------------------------
    onVideoDecoderDequeueEvent = () =>
    {
        if(this.videoDecoder.decodeQueueSize <= QUEUE_SIZE_THRESHOLD)
        {
            return;
        }

        console.warn('Decoder queue size is too large, reducing rendering frequency');
        this.interval = this.interval * 2;
        this.videoDecoder.removeEventListener('dequeue', this.onVideoDecoderDequeueEvent);
    }

    //--------------------------------------------------------------------------
    enableFakeAlpha()
    {
        this.createYuvCanvas(true);
    }

    //--------------------------------------------------------------------------
    disableFakeAlpha()
    {
        this.createYuvCanvas(false);
    }

    //--------------------------------------------------------------------------
    createYuvCanvas(fakeAlphaEnabled = false)
    {
        this.yuvCanvas  = new YUVCanvas(
        {
            canvas              : this.offscreenCanvas,
            width               : this.targetWidth,
            height              : this.targetHeight,
            fakeAlphaEnabled    : fakeAlphaEnabled,
            contextOptions      : {
                xrCompatible    : true
            }
        });
    }

    //--------------------------------------------------------------------------
    setupDisplay(canvas)
    {
        this.canvasContext = canvas.getContext("2d");
    }

    //--------------------------------------------------------------------------
    close()
    {
        this.stopRenderLoop();

        if(this.isHardwareDecoding)
        {
            this.videoDecoder.removeEventListener('dequeue', this.onVideoDecoderDequeueEvent);
            this.videoDecoder.close();
            this.videoDecoder = null;
        }
        else
        {
            this.yuvCanvas = null;
            this.decoder = null;
            this.offscreenCanvas = null;
        }

        if(this.onFocusHandler)
        {
            window.removeEventListener("focus", this.onFocusHandler , false);
            this.onFocusHandler = null;
        }

        this.resetState();
    }

    //------------------------------------------------------------------------------
    resize(width, height, canvasWidth, canvasHeight)
    {
        this.targetWidth    = width;
        this.targetHeight   = height;

        this.canvasWidth    = canvasWidth;
        this.canvasHeight   = canvasHeight;

        this.offscreenCanvas.width     = this.targetWidth;
        this.offscreenCanvas.height    = this.targetHeight;

        if(this.isHardwareDecoding)
        {
            this.configureVideoDecoder(width, height);
        }
        else
        {
            this.yuvCanvas.width        = this.targetWidth;
            this.yuvCanvas.height       = this.targetHeight;
        }
    }

    //------------------------------------------------------------------------------
    onFrameReceived(frame, frameData, imageSize)
    {
        const inputArray = new Uint8Array(frame.buffer, frame.byteOffset, frame.byteLength);
        const frameMetaData = frameData && this.parseFrameData(frameData, imageSize);

        if(this.isHardwareDecoding)
        {
            const chunk = new EncodedVideoChunk(
            {
                timestamp: 0,
                type: this.firstFrame ? "key" : "delta",
                data: inputArray,
            });

            this.firstFrame = false;
            this.videoDecoder.decode(chunk);
        }
        else
        {
            this.decoder.decode(inputArray, frameMetaData ? { frameMetaData } : {});
        }

        if(frameMetaData)
        {
            const frameId = frameMetaData.frameCounter;
            if(this.queueMetadata)
            {
                this.metadataQueue.push({frameId, frameMetaData});
            }
            else
            {
                this.notifier.emit('onFrameDataReceived', frameMetaData);
            }
        }
    }

    //------------------------------------------------------------------------------
    onVideoFrameDecoded = (buffer) =>
    {
        this.canvasBufferContext.drawImage(buffer, 0, 0);
        buffer.close();

        if(this.metadataQueue.length > 0)
        {
            const { frameId, frameMetaData } = this.metadataQueue.shift();
            this.notifier.emit('onFrameDataReceived', frameMetaData, frameId);
        }

        this.notifier.emit('onFrameDecoded');
    }

    //------------------------------------------------------------------------------
    onFrameDecoded = (buffer, width, height, infos) =>
    {
        this.lastFrame = { buffer, width, height, info: infos && infos[0] };
        this.notifier.emit('onFrameDecoded');
    }

    //------------------------------------------------------------------------------
    triggerRenderLoop()
    {
        this.requestID = window.requestAnimationFrame(this.renderFrame);
    }

    //------------------------------------------------------------------------------
    stopRenderLoop()
    {
        window.cancelAnimationFrame(this.requestID);
    }

    //------------------------------------------------------------------------------
    renderFrame = () =>
    {
        this.triggerRenderLoop();

        const now = Date.now();
        const delta = now - this.then;

        if(delta < this.interval)
        {
            return;
        }

        this.then = now - (delta % this.interval);

        if(this.isHardwareDecoding === false)
        {
            this.drawYUV();
            this.cachedFrame    = this.lastFrame;
            this.lastFrame      = null;
        }

        if(this.canvasContext && this.offscreenCanvas)
        {
            this.adjustCanvasSize();
            this.notifier.emit('onFramePreRender');

            this.canvasContext.drawImage(
                this.offscreenCanvas,
                0, 0,
                this.renderWidth    || this.canvasWidth,
                this.renderHeight   || this.canvasHeight
            );

            this.notifier.emit('onFrameRendered');
            this.notifier.emit('onFramePostRender');
        }
    }

    //------------------------------------------------------------------------------
    drawYUV()
    {
        if(!this.lastFrame)
        {
            return;
        }

        const buffer        = this.lastFrame.buffer;
        const width         = this.lastFrame.width;
        const height        = this.lastFrame.height;

        const yDataPerRow   = width;
        const yRowCnt       = height;
        const uDataPerRow   = width / 2;
        const uRowCnt       = height / 2;

        const yChannelSize  = yDataPerRow * yRowCnt;
        const uvChannelSize = uDataPerRow * uRowCnt;

        this.yuvCanvas.drawNextOutputPicture(
        {
            yData: buffer.subarray(0, yChannelSize),
            uData: buffer.subarray(yChannelSize, yChannelSize + uvChannelSize),
            vData: buffer.subarray(yChannelSize + uvChannelSize, yChannelSize + uvChannelSize + uvChannelSize),

            yDataPerRow,
            yRowCnt,
            uDataPerRow,
            uRowCnt
        });
    }

    //------------------------------------------------------------------------------
    adjustCanvasSize()
    {
        if(this.canvasWidth && this.canvasHeight && (this.canvasContext.canvas.width != this.canvasWidth || this.canvasContext.canvas.height != this.canvasHeight))
        {
            this.canvasContext.canvas.width     = this.canvasWidth;
            this.canvasContext.canvas.height    = this.canvasHeight;

            this.notifier.emit('onCanvasResized', this.canvasWidth, this.canvasHeight);
        }
    }
}

/**
 * Event emitted before a frame is rendered.
 * @example
 * let lastTime = performance.now();
 * function update()
 * {
 *     const deltaTime = performance.now() - lastTime;
 *     lastTime = performance.now();
 * }
 * SDK3DVerse.notifier.on('onFramePreRender', update);
 *
 * @event onFramePreRender
 */

/**
 * Event emitted after a frame is rendered.
 * @example
 * let lastTime = performance.now();
 * function update()
 * {
 *     const deltaTime = performance.now() - lastTime;
 *     lastTime = performance.now();
 * }
 * SDK3DVerse.notifier.on('onFramePostRender', update);
 *
 * @event onFramePostRender
 */
