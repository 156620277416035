import Utils from '../sources/Utils'
import ChannelIDs from 'ChannelIDs'

export default class SDK3DVerse_TaskStack
{
    //------------------------------------------------------------------------------
    constructor()
    {
        this.resetState();
    }

    //------------------------------------------------------------------------------
    resetState()
    {
        this.callbacks  = [];
        this.stack      = [];
        this.uuidBuffer = new Uint8Array(16);
    }

    //------------------------------------------------------------------------------
    setClientUUID(clientUUID)
    {
        this.uuidBuffer = Utils.uuidToUint8Array(clientUUID);
    }

    //------------------------------------------------------------------------------
    isEmpty()
    {
        return this.stack.length == 0;
    }

    //------------------------------------------------------------------------------
    pushInput(id, data, replace = false)
    {
        this.pushQuery(ChannelIDs.inputs, id, data, null, replace);
    }

    //------------------------------------------------------------------------------
    pushClientRemoteOperation(id, data, callback = null, replace = false)
    {
        this.pushQuery(ChannelIDs.client_remote_operations, id, data, callback, replace);
    }

    //------------------------------------------------------------------------------
    pushEditorRemoteOperation(id, data, replace = false)
    {
        /*
            Callback is not supported in editor_remote_operations
            We might need another task stacks for standalone mode, since it's another
            socket, we cannot ensure the task order, without using requestIDs.
        */
        this.pushQuery(ChannelIDs.editor_remote_operations, id, data, null, replace);
    }

    //------------------------------------------------------------------------------
    pushQuery(channelID, id, data, callback, replace)
    {
        if (this.stack.length > 100)
        {
            console.warn("Message limit exceeded");
            return;
        }

        var taskFound =	false;

        //Search if this task can be replaced:
        if (replace)
        {
            var taskIndex = this.stack.findIndex((task) =>
            {
                return channelID == task.channelID && id == task.id;
            });

            if(taskIndex != -1)
            {
                this.stack[taskIndex].data  = data;
                taskFound                   = true;
            }
        }

        if(!taskFound)
        {
            this.stack.push
            (
                {
                    channelID   : channelID,
                    id          : id,
                    data        : data,
                    callback    : callback
                }
            );
        }
    }

    //------------------------------------------------------------------------------
    pop()
    {
        const FTL_HEADER_SIZE                       = 4;
        const INPUT_HEADER_SIZE                     = 1;
        const EDITOR_REMOTE_OPERATION_HEADER_SIZE   = 25;
        const LITTLE_ENDIAN                         = true;

        var task                = this.stack[0];

        var isRemoteOperation   = task.channelID != ChannelIDs.inputs;
        var headerSize          = isRemoteOperation ? EDITOR_REMOTE_OPERATION_HEADER_SIZE : INPUT_HEADER_SIZE;

        var dataSize            = task.data ? task.data.length : 0;
        var totalSize           = headerSize + dataSize;

        var buffer              = new ArrayBuffer(totalSize + FTL_HEADER_SIZE);
        var dataView            = new DataView(buffer);
        var offset              = 0;

        // ftl_header
        dataView.setUint8(offset++, task.channelID);
        dataView.setUint8(offset++, 0xFF&(totalSize>>0));
        dataView.setUint8(offset++, 0xFF&(totalSize>>8));
        dataView.setUint8(offset++, 0xFF&(totalSize>>16));

        if(isRemoteOperation)
        {
            // Client UUID
            for(var i = 0; i < 16; i++)
            {
                dataView.setUint8(offset++, this.uuidBuffer[i]);
            }

            // Request Id for reply. tbd if we want async replies
            const requestID = 0;
            dataView.setUint32(offset, requestID, LITTLE_ENDIAN);
            offset += 4;

            dataView.setUint32(offset, dataSize, LITTLE_ENDIAN);
            offset += 4;
        }

        // Operation ID
        dataView.setUint8(offset++, task.id);

        if(task.data)
        {
            var data = task.data;
            for(var a = 0; a < data.length; a++)
            {
                dataView.setUint8(offset++, data[a], LITTLE_ENDIAN);
            }
        }

        // Register Call-Back
        if(task.callback)
        {
            this.callbacks.push(task.callback);
        }

        // Remove first item
        this.stack.shift();

        return {task, isRemoteOperation, buffer};
    }

    //------------------------------------------------------------------------------
    onTaskResponse(data)
    {
        if(this.callbacks.length == 0)
        {
            console.warn("Task response received but callback stack is empty")
            return;
        }

        var callback = this.callbacks.shift();
        callback(data);
    }
}
