//------------------------------------------------------------------------------
import { vec3, quat, mat4 } from 'gl-matrix';

//------------------------------------------------------------------------------
import SDK3DVerse_Utils from "./Utils";
import SDK3DVerse_EngineAPI from './EngineAPI';
import { AABB, Client, ComponentMap, ComponentName, EditorEntity, Transform, SDK_Vec3, SDK_Mat4, SDK_Quat } from 'types';

//------------------------------------------------------------------------------
/**
 * Class representing an entity in the scene.
 * You cannot directly instantiate an `Entity` object by calling the constructor. To create an `Entity`, create a new [EntityTemplate]{@link EntityTemplate},
 * and then call [EntityTemplate.instantiateEntity]{@link EntityTemplate#instantiateEntity}.
 *
 * An entity is a container for <a href="tutorial-components.html">components</a>.
 */
class Entity {
    engineAPI: SDK3DVerse_EngineAPI;

    rtid: string;
    children: string[];
    components: ComponentMap;
    componentList: ComponentName[];
    selectingClients: Client[];
    selectedDescendants: Record<string, Client[]>;
    selectingClientsWithDescendants: Client[];

    visible: boolean;
    external: boolean;
    runtime: boolean;
    transient: boolean;
    selected: boolean;

    overrider: Entity | null = null;
    overriddenEntity: Entity | null = null;
    linker: Entity | null = null;

    attachedComponents: ComponentName[];
    detachedComponents: ComponentName[];
    dirtyComponents: ComponentName[];
    unsavedComponents: ComponentName[];

    //--------------------------------------------------------------------------
    /**
     * @hideconstructor
     */
    constructor(engineAPI: SDK3DVerse_EngineAPI, editorEntity: EditorEntity) {
        this.engineAPI = engineAPI;

        this.rtid = editorEntity.rtid;
        this.children = editorEntity.children;
        this.components = editorEntity.components;
        this.selectingClients = editorEntity.selectingClients;
        this.selectedDescendants = editorEntity.selectedDescendants;

        this.visible = editorEntity.isVisible;
        this.external = editorEntity.isExternal;
        this.runtime = editorEntity.isRuntime;
        this.transient = editorEntity.isTransient;
        this.selected = false;

        this.overrider = null;
        this.overriddenEntity = null;
        this.linker = null;

        this.attachedComponents = [];
        this.detachedComponents = [];
        this.dirtyComponents = [];
        this.unsavedComponents = [];

        if(!this.transient)
        {
            this.updateSelectingClients();
            this.applyDefaultComponentValues();
        }
        this.updateComponentList();
    }

    //--------------------------------------------------------------------------
    /**
     * Get the entity's rtid, or unique runtime identifier.
     * This identifier is the unique way to target a specific entity instance.
     *
     * @returns {string} rtid.
     */
    getID()
    {
        return this.rtid;
    }

    //--------------------------------------------------------------------------
    /**
     * Get entity UUID.
     * This identifier can be shared by multiple instances of the same entity,
     * across linked scenes.
     *
     * @returns {string} Entity UUID.
     */
    getEUID()
    {
        return this.components.euid.value;
    }

    //--------------------------------------------------------------------------
    /**
     * Check whether or not the entity is visible. This state can be changed by calling
     * [setVisibility]{@link Entity#setVisibility}.
     *
     * @returns {boolean} Visibility state.
     */
    isVisible()
    {
        return this.visible;
    }

    //--------------------------------------------------------------------------
    /**
     * Set visibility.
     *
     * @param {boolean} isVisible - Set to false if entity should be hidden, true otherwise.
     *
     * @fires onEntityVisibilityChanged
     *
     * @async
     */
    async setVisibility(isVisible: boolean)
    {
        await this.engineAPI.editorAPI.setEntityVisibility(
            this.getID(),
            isVisible,
            this.engineAPI.handleEntityVisibilityChangedEvent
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Select entity. The entity will become highlighted.
     *
     * @param {boolean} [keepOldSelection=false] - Set to true to keep old selection, i.e. old selection stays highlighted
     * @param {string} [triggeredBy='select'] - The `triggeredBy` string passed to {@link event:onEntitySelectionChanged}
     *
     * @fires onEntitySelectionChanged
     *
     * @see [unselect]{@link Entity#unselect}
     *
     * @example
     * entity.select();
     */
    select(keepOldSelection: boolean = false, triggeredBy: string = 'select')
    {
        this.engineAPI.updateSelectedEntities([this], keepOldSelection, triggeredBy);
    }

    //--------------------------------------------------------------------------
    /**
     * Unselect entity. If the entity was selected, the entity will become unhighlighted.
     *
     * @param {string} [triggeredBy='unselect'] - The `triggeredBy` string passed to {@link event:onEntitySelectionChanged}
     *
     * @fires onEntitySelectionChanged
     *
     *  @see [select]{@link Entity#select}
     *
     * @example
     * entity.unselect();
     */
    unselect(triggeredBy: string = 'unselect')
    {
        this.engineAPI.updateSelectedEntities([this], true, triggeredBy);
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the entity belongs to another scene.
     *
     * @returns {boolean} External state.
     */
    isExternal()
    {
        return this.external;
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the entity is a transient entity.
     *
     * @see [createTransientEntity]{@link SDK3DVerse.engineAPI#createTransientEntity}
     *
     * @returns {boolean} Transient state.
     */
    isTransient()
    {
        return this.runtime;
    }

    //--------------------------------------------------------------------------
    // deprecated
    isRuntime()
    {
        return this.runtime;
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the entity is currently selected. This state can be changed by calling
     * [select]{@link Entity#select} and [unselect]{@link Entity#unselect}.
     *
     * @returns {boolean} Selected state.
     */
    isSelected()
    {
        return this.selected;
    }

    //--------------------------------------------------------------------------
    getSelectingClients()
    {
        return this.selectingClients;
    }

    //--------------------------------------------------------------------------
    getSelectingClientWithDescendants()
    {
        return this.selectingClientsWithDescendants;
    }

    //--------------------------------------------------------------------------
    // Private
    updateSelectingClients()
    {
        this.selectingClients = Array.from(this.selectingClients);
        this.selectingClientsWithDescendants = this.selectingClients.map(
            c => ({...c, directSelection : true})
        );

        for(const descendantRTID in this.selectedDescendants)
        {
            const selectingClients = this.selectedDescendants[descendantRTID];
            for(const selectingClient of selectingClients)
            {
                if(this.selectingClientsWithDescendants.findIndex(e => selectingClient.clientUUID == e.clientUUID) == -1)
                {
                    this.selectingClientsWithDescendants.push({...selectingClient, directSelection : false});
                }
            }
        }
    }

    //--------------------------------------------------------------------------
    updateComponentList()
    {
        this.componentList = Object.keys(this.components) as ComponentName[];

        if(this.isOverridden())
        {
            const overriderComponents = this.overrider.getComponentList()
                .filter(componentType => this.isComponentOverridable(componentType));

            this.componentList = Array.from(new Set([...this.componentList, ...overriderComponents]));
        }

        if(this.isOverrider() && this.overriddenEntity)
        {
            this.overriddenEntity.updateComponentList();
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the types of all the components attached to the entity.
     *
     * @returns {Array.<string>} Component types.
     *
     * @example
     * const componentTypes = entity.getComponentTypes();
     * // componentTypes = ['debug_name', 'local_transform', ...]
     */
    getComponentTypes()
    {
        return this.componentList;
    }

    //--------------------------------------------------------------------------
    // deprecated
    getComponentList()
    {
        return this.componentList;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the entity's name stored in the
     * <a href="tutorial-components_debug_name.html">debug_name</a> component.
     *
     * @param {boolean} [defaultName=true] If false, an empty string is returned if
     * the entity does not have a <a href="tutorial-components_debug_name.html">debug_name</a>
     * component attached. If true, "No name" would be returned in that case.
     *
     * @returns {string} Entity's name.
     */
    getName(defaultName = true)
    {
        const name = this.components.debug_name ? this.components.debug_name.value : "";

        if(!name && defaultName)
        {
            return "No name";
        }

        return name;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the entity's parent.
     *
     * @returns {Entity} Entity's parent.
     */
    getParent()
    {
        if(!this.hasParent())
        {
            return null;
        }

        var parentRTID = this.getComponent("lineage").ancestorRTID;
        var entity: Entity | null = this.engineAPI.entityRegistry.getEntity(parentRTID);
        if(entity)
        {
            return entity;
        }
        else
        {
            return undefined;
        }
    }

    //------------------------------------------------------------------------------
    /**
     * Get the direct children of the entity. To get all of its descendants, you must call
     * this function recursively.
     *
     * @returns {Array.<Entity>} Entity's direct children.
     *
     * @async
     */
    async getChildren() {
        var children: Entity[] = [];
        for (var i in this.children) {
            var childRTID = this.children[i];

            if (this.engineAPI.entityRegistry.hasEntity(childRTID)) {
                children.push(this.engineAPI.entityRegistry.getEntity(childRTID));
            }
            else {
                return await this.engineAPI.editorAPI.getChildren(this);
            }
        }
        return children;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the entity's ancestors. The ancestors are returned in ascending order,
     * i.e. beginning with the entity's direct parent and ending with its highest ancestor.
     *
     * @returns {Array.<Entity>} Entity's ancestors.
     */
    getAncestors()
    {
        var currentEntity: Entity | null = this;
        var ancestors: Entity[] = [];
        while(currentEntity = currentEntity.getParent())
        {
            ancestors.push(currentEntity);
        }
        return ancestors;
    }

    //--------------------------------------------------------------------------
    /**
     * Check if the entity has a parent.
     *
     * @returns {boolean} Has parent state.
     */
    hasParent()
    {
        return this.isAttached("lineage")
            && this.getComponent("lineage").ancestorRTID
            && this.getComponent("lineage").ancestorRTID !== "0";
    }

    //--------------------------------------------------------------------------
    /**
     * Check if the entity has children.
     *
     * @returns {boolean} Has children state.
     */
    hasChildren()
    {
        return this.children.length > 0 || (this.isLinker() && this.components.scene_ref.value !== SDK3DVerse_Utils.invalidUUID);
    }

    //--------------------------------------------------------------------------
    // private
    getLinkerLineage()
    {
        const lineage: Entity[] = [];
        let currentLinker   = this.linker;
        while(currentLinker)
        {
            lineage.push(currentLinker);
            currentLinker = currentLinker.linker;
        }
        return lineage;
    }

    //--------------------------------------------------------------------------
    // private
    toEntityRef()
    {
        const linkage       = [];
        let currentLinker   = this.linker;
        while(currentLinker)
        {
            linkage.unshift(currentLinker.getEUID());
            currentLinker = currentLinker.linker;
        }

        return {
            originalEUID : this.getEUID(),
            linkage : linkage
        };
    }

    //--------------------------------------------------------------------------
    // private
    resolveLinker()
    {
        this.linker = this.getLinker();

        // If the entity doesn't have any ancestors, set his linker as his parent
        if(this.linker && !this.components.lineage.ancestorRTID)
        {
            this.components.lineage.ancestorRTID = this.linker.rtid;
        }
    }

    //--------------------------------------------------------------------------
    // private
    getLinker()
    {
        if(!this.isAttached("lineage"))
        {
            return null;
        }

        const lineage = this.getComponent("lineage");
        if(lineage.value.length == 0)
        {
            return null;
        }

        const linkerEUID    = lineage.value[lineage.value.length - 1];
        const linkers: Entity[] = this.engineAPI.entityRegistry.getEntitiesByEUID(linkerEUID);

        if(linkers.length == 0)
        {
            console.warn(`Cannot find linker EUID ${linkerEUID}`);
            return null;
        }

        if(linkers.length == 1)
        {
            return linkers[0];
        }

        const commonLineage = lineage.value.slice(0, -1);

        for(var i in linkers)
        {
            const candidateLinker = linkers[i];

            const linkerLineage = candidateLinker.components.lineage.value;
            if(linkerLineage.length != commonLineage.length)
            {
                continue;
            }

            let linkerFound = true;
            for(var j in linkerLineage)
            {
                if(linkerLineage[j] != commonLineage[j])
                {
                    linkerFound = false;
                    break;
                }
            }

            if(linkerFound)
            {
                return candidateLinker;
            }
        }

        console.warn(`Cannot find appropriate linker for ${this.getEUID()}`);
    }

    //--------------------------------------------------------------------------
    // private
    isLinker()
    {
        return this.components.scene_ref ? true : false;
    }

    //--------------------------------------------------------------------------
    // private
    isOverrider()
    {
        return this.components.overrider ? true : false;
    }

    //--------------------------------------------------------------------------
    // private
    getOverriddenEntity()
    {
        if(!this.isOverrider() || !this.overriddenEntity)
        {
            return this;
        }

        return this.overriddenEntity.getOverriddenEntity();
    }

    //--------------------------------------------------------------------------
    /**
     * Get the state of an external entity.
     *
     * @param {string} componentType The component type
     *
     * @example
     * const entityIsOverridden = entity.getExternalState() === 'overridden';
     *
     * @returns {ExternalEntityState} External entity state.
     */
    getExternalState()
    {
        if(!this.isOverridden())
        {
            return 'unmodified';
        }

        if(this.isMarkedAsDeleted())
        {
            return 'deleted';
        }

        return 'overridden';
    }

    //--------------------------------------------------------------------------
    // private
    isOverridden()
    {
        //return this.components.overridden ? true : false;
        return this.components.overridden && Boolean(this.overrider);
    }

    //--------------------------------------------------------------------------
    // private
    isMarkedAsDeleted(recursive = true)
    {
        return (this.isOverridden() && this.overrider.getComponent("overrider").deleter)
            || (recursive && this.getParent() && this.getParent().isMarkedAsDeleted());
    }

    //--------------------------------------------------------------------------
    /**
     * Get the component state of an external entity.
     *
     * @param {string} componentType The component type
     *
     * @example
     * const materialIsOverridden = entity.getExternalComponentState('material') === 'overridden';
     *
     * @returns {ExternalComponentState} External component state.
     */
    getExternalComponentState(componentType: ComponentName)
    {
        if(!this.isComponentOverridden(componentType))
        {
            return 'unmodified';
        }

        if(!this.UNSAFE_isAttached(componentType))
        {
            return 'new';
        }

        if(this.isComponentMarkedAsDetached(componentType))
        {
            return 'detached';
        }

        return 'overridden';
    }

    //--------------------------------------------------------------------------
    // private
    isComponentMarkedAsNew(componentType: ComponentName)
    {
        return !this.UNSAFE_isAttached(componentType) && this.isComponentOverridden(componentType);
    }

    //--------------------------------------------------------------------------
    // private
    isComponentMarkedAsDetached(componentType: ComponentName)
    {
        if(!this.isOverridden())
        {
            return false;
        }

        const componentDescription = this.engineAPI.entityRegistry.componentDescriptions[componentType];
        if(!componentDescription)
        {
            console.warn('Invalid component type', componentType);
            return false;
        }

        let overrider = this.overrider;
        do
        {
            if(overrider.UNSAFE_getComponent('overrider').componentsToDetach.includes(componentDescription.hash))
            {
                return true;
            }
        }
        while(overrider = overrider.overrider);

        return false;
    }

    //--------------------------------------------------------------------------
    // private
    getChildOverriders(): Entity[]
    {
        if(!this.isAttached('lineage'))
        {
            return [];
        }

        if(!this.hasChildren())
        {
            return [];
        }

        const lineage = Array.from(this.getComponent('lineage').value).reverse();
        if(this.isLinker())
        {
            lineage.push(this.getEUID());
        }

        let currentMap = this.engineAPI.entityRegistry.lineageOverriderMap;
        for(const entityEUID of lineage)
        {
            let nextMap = currentMap.next.get(entityEUID);
            if(!nextMap)
            {
                return [];
            }

            currentMap = nextMap;
        }

        if(this.isLinker())
        {
            return currentMap.overriders;;
        }

        return currentMap.overriders.filter(overrider =>
        {
            let currentEntity = overrider.overriddenEntity;

            if(!currentEntity)
            {
                return false;
            }

            while(currentEntity = currentEntity.getParent())
            {
                if(currentEntity.isSame(this))
                {
                    return true;
                }

                if(currentEntity.isLinker())
                {
                    break;
                }
            }

            return false;
        });
    }

    //--------------------------------------------------------------------------
    // private
    hasChildrenOverridden()
    {
        const overriders = this.getChildOverriders();
        return overriders.length > 0;
    }

    //--------------------------------------------------------------------------
    getLocalOverrider()
    {
        if(!this.isOverridden())
        {
            return null;
        }

        let overrider = this.overrider;
        while(overrider.isExternal()) {
            overrider = overrider.overrider;

            if(!overrider) {
                return null;
            }
        }

        return overrider;
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the specified component is attached to the entity.
     *
     * @param {string} componentType Component type
     *
     * @returns {boolean} Attached component state.
     *
     * @example
     * const hasRigidbody = entity.isAttached('rigid_body');
     */
    isAttached(componentType: ComponentName)
    {
        return this.UNSAFE_isAttached(componentType) || this.isComponentOverridden(componentType);
    }

    //--------------------------------------------------------------------------
    // Private
    UNSAFE_isAttached(componentType: ComponentName)
    {
        return this.components.hasOwnProperty(componentType);
    }

    //--------------------------------------------------------------------------
    // Private
    applyDefaultComponentValues()
    {
        let componentType: ComponentName;
        for (componentType in this.components) {
            const currentValue = this.UNSAFE_getComponent(componentType);
            const defaultValue = this.engineAPI.entityRegistry.getComponentDefaultValue(componentType);

            if (componentType === 'local_transform') {
                const localTransform = this.UNSAFE_getComponent(componentType)
                if (localTransform.orientation && !localTransform.eulerOrientation) {
                    defaultValue.eulerOrientation = SDK3DVerse_Utils.quaternionToEuler(localTransform.orientation);
                }
            }

            const value = {
                ...defaultValue,
                ...currentValue,
            };

            this.UNSAFE_setComponent(componentType, value);
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the specified component's value.
     *
     * @param {string} componentType - Component type
     *
     * @returns {object} Specified component's value.
     *
     * @example
     * const localTransform = entity.getComponent('local_transform');
     * // do something with localTransform.position
     */
    getComponent<T extends ComponentName>(componentType: T): ComponentMap[T]
    {
        if(this.isComponentOverridden(componentType))
        {
            return this.overrider.getComponent(componentType);
        }

        if(!this.isAttached(componentType))
        {
            console.warn(`Attempt to get the value of an unattached component (${componentType}) on ${this.getName()} (${this.getID()})`);
            return null;
        }

        return this.UNSAFE_getComponent(componentType);
    }

    //--------------------------------------------------------------------------
    // private
    UNSAFE_getComponent<T extends ComponentName>(componentType: T): ComponentMap[T]
    {
        return this.components[componentType];
    }

    //--------------------------------------------------------------------------
    /**
     * Get components attached to the entity.
     *
     * @returns {object} A collection of keys and values, where key is the component
     * type and its value is the corresponding component value.
     *
     * @example
     * const components = entity.getComponents();
     * for(const componentType in components)
     * {
     *      const componentValue = components[componentType];
     *      // do something with componentType, componentValue
     * }
     */
    getComponents(): ComponentMap
    {
        let overriderComponents: ComponentMap = this.isOverridden() ? this.overrider.getComponents() : {};

        let components = {};
        let componentType: ComponentName;
        for (componentType in this.components) {
            const component = this.getComponent(componentType);
            components[componentType] = component;
        }

        for (componentType in overriderComponents) {
            if (this.isComponentOverridable(componentType)) {
                components[componentType] = overriderComponents[componentType];
            }
        }

        return components;
    }

    //--------------------------------------------------------------------------
    // private
    isComponentOverridden(componentType: ComponentName)
    {
        return this.isComponentOverridable(componentType) && Boolean(this.overrider) && this.overrider.isAttached(componentType);
    }

    //--------------------------------------------------------------------------
    // private
    isComponentOverridable(componentType: ComponentName)
    {
        const unonverridableComponentNames =
        [
            "lineage",
            "euid",
            "overrider",
            "debug_name"
        ];
        return !unonverridableComponentNames.includes(componentType);
    }

    //--------------------------------------------------------------------------
    /**
     * Attach component to the entity. If the component is already attached,
     * do nothing.
     *
     * @param {string} componentType - Component type
     * @param {object} componentValue - Component value
     *
     * @fires onEntitiesUpdated
     *
     * @example
     * const meshUUID = 'b9936cb6-0e73-4398-a06f-0355d8a96fca';
     * const meshRefComponent = { value : meshUUID, submeshIndex : 0 };
     * entity.attachComponent('mesh_ref', meshRefComponent);
     */
    attachComponent<T extends ComponentName>(componentType: T, componentValue: Partial<ComponentMap[T]>)
    {
        if(this.isAttached(componentType))
        {
            console.warn(`Cannot attach component ${componentType} that is already attached`);
            return;
        }

        const componentDescription = this.engineAPI.entityRegistry.componentDescriptions[componentType];
        if(!componentDescription)
        {
            console.warn('Invalid component type', componentType);
            return;
        }

        if(componentType === 'local_transform')
        {
            componentValue = SDK3DVerse_Utils.patchTransform(componentValue);
        }

        const defaultValue = this.engineAPI.entityRegistry.getComponentDefaultValue(componentType);

        const computedValue = {
            ...defaultValue,
            ...componentValue
        };

        this.UNSAFE_attachComponent(componentType, computedValue);
        this.UNSAFE_setDirty(componentType);
        if(this.detachedComponents.includes(componentType))
        {
            const index = this.detachedComponents.indexOf(componentType);
            this.detachedComponents.splice(index, 1);
        }
        else
        {
            this.attachedComponents.push(componentType);
        }

        this.engineAPI.entityRegistry.setDirtyEntity(this);

        this.updateComponentList();
        this.engineAPI.propagateChanges();
    }

    //--------------------------------------------------------------------------
    /**
     * Detach component from the entity. If the component is not attached to the
     * entity, do nothing.
     *
     * @param {string} componentType - Component type
     *
     * @example
     * entity.detachComponent('mesh_ref');
     */
    detachComponent(componentType: ComponentName)
    {
        if(!this.isAttached(componentType))
        {
            console.warn(`Cannot detach component ${componentType} that is not attached`);
            return;
        }

        const componentDescription = this.engineAPI.entityRegistry.componentDescriptions[componentType];
        if(!componentDescription)
        {
            console.warn('Invalid component type', componentType);
            return;
        }

        this.UNSAFE_detachComponent(componentType);
        this.cancelComponentChanges(componentType);

        if(this.attachedComponents.includes(componentType))
        {
            const index = this.attachedComponents.indexOf(componentType);
            this.attachedComponents.splice(index, 1);
        }
        else
        {
            this.detachedComponents.push(componentType);
        }

        this.engineAPI.entityRegistry.setDirtyEntity(this);
        this.updateComponentList();

        const entitiesToDetachComponentPerHash = {
            [componentDescription.hash]: [this.getComponent("euid").rtid]
        }
        this.engineAPI.ftlAPI.removeComponents(entitiesToDetachComponentPerHash);
    }

    //--------------------------------------------------------------------------
    /**
     * Set the specified component's value. If the component is not attached
     * to the entity, do nothing.
     *
     * @param {string} componentType - Component type
     * @param {object} componentValue - Component value
     *
     * @fires onEntitiesUpdated
     *
     * @example
     * const materialUUID = 'b9936cb6-0e73-4398-a06f-0355d8a96fca';
     * const materialRef = { value : materialUUID };
     * entity.setComponent('material_ref', materialRef);
     */
    setComponent<T extends ComponentName>(componentType: T, componentValue: ComponentMap[T])
    {
        if(!this.isAttached(componentType))
        {
            console.warn(`Cannot update component ${componentType} that is not attached`);
            return;
        }

        if(componentType === 'local_transform')
        {
            componentValue = SDK3DVerse_Utils.patchTransform(componentValue);
        }

        if(this.isComponentOverridden(componentType))
        {
            this.overrider.setComponent(componentType, componentValue);
            return;
        }
        else
        {
            this.UNSAFE_setComponent(componentType, componentValue);
        }

        this.setDirty(componentType);
        this.engineAPI.propagateEntities('setComponent');
    }

    //--------------------------------------------------------------------------
    /**
     * Set the specified component's value. If the component is not attached
     * to the entity, attach the component and set the specified value.
     *
     * @param {string} componentType - Component type
     * @param {object} componentValue - Component value
     *
     * @fires onEntitiesUpdated
     *
     * @example
     * const materialUUID = 'b9936cb6-0e73-4398-a06f-0355d8a96fca';
     * const materialRef = { value : materialUUID };
     * entity.setOrAttachComponent('material_ref', materialRef);
     */
    setOrAttachComponent<T extends ComponentName>(componentType: T, componentValue: ComponentMap[T])
    {
        if(!this.isAttached(componentType))
        {
            this.attachComponent(componentType, componentValue);
            return;
        }

        this.setComponent(componentType, componentValue);
    }

    //--------------------------------------------------------------------------
    /**
     * Reparent entity to a given parent.
     *
     * @param {Entity} parent - Parent entity
     * @param {boolean} [keepGlobalTransform=true] - Recalculate entity local transform after reparenting in order to preserve their previous global transform
     *
     * @fires onEntityReparent
     * @fires onEntitiesUpdated
     */
    async reparent(parent: Entity, keepGlobalTransform: boolean = true) {
        if (this.getID() === parent.getID()) {
            console.warn("Cannot reparent entity in itself");
            return;
        }

        if (this.isExternal()) {
            console.error("Attempt to reparent an external entity");
            return;
        }

        if (this.isOverrider()) {
            console.error("Cannot reparent an overrider");
            return;
        }

        if (parent && parent.isExternal()) {
            console.error("Attempt to reparent a entity to an external parent");
            return;
        }

        // Attempt to prepare a sequence, could return false if a sequence is
        // already running (in simplify hierarchy for example). In that case
        // do not commit the sequence immediatly at the end of the function.
        const sequenceState = keepGlobalTransform
                            ? this.engineAPI.editorAPI.prepareSequence()
                            : false;

        if (keepGlobalTransform) {
            this.computeGlobalTransformFromEntity(this.getGlobalTransform(), parent);
        }

        const oldParent = this.getParent();
        const reparentPromise = this.engineAPI.editorAPI.reparentEntity(
            this.getEUID(),
            oldParent ? oldParent.getEUID() : null,
            parent ? parent.getEUID() : null,
            false
        );

        if (sequenceState) {
            this.engineAPI.editorAPI.commitSequence("reparent-entity");
        }

        await reparentPromise;
    }

    //--------------------------------------------------------------------------
    // private
    UNSAFE_setComponent<T extends ComponentName>(componentType: T, value: ComponentMap[T])
    {
        this.components[componentType] =
        {
            ...this.components[componentType],
            ...value
        };
    }

    //--------------------------------------------------------------------------
    // private
    UNSAFE_attachComponent<T extends ComponentName>(componentType: T, value: ComponentMap[T])
    {
        this.components[componentType] = value;
    }

    //--------------------------------------------------------------------------
    // private
    UNSAFE_detachComponent(componentType: ComponentName)
    {
        delete this.components[componentType];
    }

    //--------------------------------------------------------------------------
    // private
    setDirty(componentType: ComponentName)
    {
        if(this.transient)
        {
            return;
        }

        if(this.attachedComponents.includes(componentType))
        {
            return;
        }

        this.UNSAFE_setDirty(componentType);
        if(!this.unsavedComponents.includes(componentType))
        {
            this.unsavedComponents.push(componentType);
        }

        this.engineAPI.entityRegistry.setDirtyEntity(this);
    }

    //--------------------------------------------------------------------------
    // private
    UNSAFE_setDirty(componentType: ComponentName)
    {
        if(!this.dirtyComponents.includes(componentType))
        {
            this.dirtyComponents.push(componentType);
        }
    }

    //--------------------------------------------------------------------------
    cancelChanges()
    {
        this.unsavedComponents.length   = 0;
        this.dirtyComponents.length     = 0;
        this.engineAPI.entityRegistry.cancelDirtyState(this);
    }

    //--------------------------------------------------------------------------
    cancelComponentChanges(componentType: ComponentName, dirty = true, unsaved = true)
    {
        if(dirty)
        {
            const index = this.dirtyComponents.indexOf(componentType);
            if(index >= 0)
            {
                this.dirtyComponents.splice(index, 1);
            }
        }

        if(unsaved)
        {
            const index = this.unsavedComponents.indexOf(componentType);
            if(index >= 0)
            {
                this.unsavedComponents.splice(index, 1);
            }
        }

        if(this.unsavedComponents.length === 0)
        {
            this.engineAPI.entityRegistry.UNSAFE_cancelUnsavedState(this);
        }

        if(this.dirtyComponents.length === 0)
        {
            this.engineAPI.entityRegistry.UNSAFE_cancelDirtyState(this);
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Return true if the entity is the same instance of the specified entity,
     * i.e. they have the same rtid.
     *
     * @param {Entity} entity
     *
     * @returns {boolean} Entity parity.
     */
    isSame(entity: Entity)
    {
        return entity && this.getID() == entity.getID();
    }

    //--------------------------------------------------------------------------
    /**
     * Get root linker (i.e. entity with scene_ref component) of this entity.
     *
     * @returns {Entity|null} Root linker, or null if entity isn't external.
     */
    getRootLinker()
    {
        if(!this.isExternal())
        {
            return null;
        }

        let current = this.getParent();
        while (current.isExternal())
        {
            current = current.getParent();
        }

        return current;
    }

    //--------------------------------------------------------------------------
    /**
     * Get the local matrix of the entity.
     *
     * @returns {mat4} Local matrix.
     */
    getLocalMatrix()
    {
        var localMatrix = mat4.create();
        var t = this.getComponent("local_transform");

        mat4.fromRotationTranslationScale(
            localMatrix,
            quat.fromValues(t.orientation[0], t.orientation[1], t.orientation[2], t.orientation[3]),
            vec3.fromValues(t.position[0], t.position[1], t.position[2]),
            vec3.fromValues(t.scale[0], t.scale[1], t.scale[2])
        );

        return localMatrix;
    }

    //--------------------------------------------------------------------------
    // private
    getParentGlobalMatrix(stopAtParent: Entity | null = null)
    {
        var parentEntity = this.getParent();
        if(parentEntity)
        {
            return parentEntity.getGlobalMatrix(stopAtParent);
        }
        else
        {
            return mat4.create();
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the global matrix of the entity.
     *
     * @param {Entity | null} [stopAtParent=null] An ancestor of the entity.
     * If specified, returns the global matrix of the entity relative to this ancestor
     * entity's local space
     *
     * @returns {mat4} Global matrix.
     */
    getGlobalMatrix(stopAtParent: Entity | null = null)
    {
        var localMatrix         = this.getLocalMatrix();

        if(stopAtParent && stopAtParent.isSame(this.getParent()))
        {
            return localMatrix;
        }

        var globalMatrix        = mat4.create();
        var parentGlobalMatrix  = this.getParentGlobalMatrix(stopAtParent);

        mat4.mul(
            globalMatrix,
            parentGlobalMatrix,
            localMatrix
        );

        return globalMatrix;
    }

    //--------------------------------------------------------------------------
    // private
    getParentGlobalTransform(stopAtParent: Entity | null = null): Transform
    {
        var parentEntity = this.getParent();
        if(parentEntity)
        {
            return parentEntity.getGlobalTransform(stopAtParent);
        }
        else
        {
            return SDK3DVerse_Utils.getIdentityTransform();
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Get the global transform of the entity.
     *
     * @param {Entity | null} [stopAtParent] An ancestor of the entity.
     * If specified, returns the global transform of the entity relative to this ancestor
     * entity's local space
     *
     * @returns {Transform} Global transform.
     */
    getGlobalTransform(stopAtParent: Entity | null = null): Transform
    {
        const t = this.getComponent("local_transform");

        if(stopAtParent && stopAtParent.isSame(this.getParent()))
        {
            return t;
        }

        const parentTransform     = this.getParentGlobalTransform(stopAtParent);
        const parentMatrix        = this.getParentGlobalMatrix(stopAtParent);

        const localPosition       = vec3.fromValues(t.position[0], t.position[1], t.position[2]);
        const localOrientation    = quat.fromValues(t.orientation[0], t.orientation[1], t.orientation[2], t.orientation[3]);
        const localScale          = vec3.fromValues(t.scale[0], t.scale[1], t.scale[2]);

        const globalPosition      = vec3.create();
        const globalOrientation   = quat.create();
        const globalScale         = vec3.create();

        vec3.transformMat4( globalPosition,     localPosition,                                      parentMatrix);
        quat.mul(           globalOrientation,  quat.fromValues(...parentTransform.orientation),    localOrientation);
        vec3.mul(           globalScale,        vec3.fromValues(...parentTransform.scale),          localScale);

        const globalTransform = SDK3DVerse_Utils.patchTransform(
        {
            position    : Array.from(globalPosition),
            orientation : Array.from(globalOrientation),
            scale       : Array.from(globalScale)
        });

        if(t.globalEulerOrientation)
        {
            const orientationFromEuler = SDK3DVerse_Utils.quaternionFromEuler(t.globalEulerOrientation);
            const isQuaternionEqual = orientationFromEuler.every(
                (value, index) => Math.max(value - globalTransform.orientation[index]) < 0.000001
            );

            if(isQuaternionEqual)
            {
                globalTransform.eulerOrientation = t.globalEulerOrientation;
            }
        }

        return globalTransform;
    }

    //--------------------------------------------------------------------------
    /**
     * Set the entity's global transform.
     * The expected transform properties are optional. For example, to only set global position
     * (and not orientation and scale) you can specify a [Transform]{@link Transform} that only has a position property.
     *
     * @param {Transform} globalTransform - Global transform
     *
     * @fires onEntitiesUpdated
     *
     * @example
     * const transform =
     * {
     *      position : [0,0,0],
     *      orientation : [0,0,0,1],
     *      scale : [1,1,1]
     * };
     * entity1.setGlobalTransform(transform);
     * entity2.setGlobalTransform({ position : [0, 0, 0] });
     */
    setGlobalTransform(globalTransform: Transform)
    {
        const transform = SDK3DVerse_Utils.patchTransform(globalTransform);

        if(transform.position)
        {
            this.setPosition(transform.position);
        }

        if(transform.orientation)
        {
            this.setOrientation(transform.orientation, transform.eulerOrientation);
        }

        if(transform.scale)
        {
            this.setScale(transform.scale);
        }
    }

    //--------------------------------------------------------------------------
    computeGlobalTransformFromEntity(globalTransform: Transform, entity: Entity)
    {
        const transform = SDK3DVerse_Utils.patchTransform(globalTransform);

        const parentGlobalTransform     = entity
                                        ? entity.getGlobalTransform()
                                        : SDK3DVerse_Utils.getIdentityTransform() as Transform;

        const parentGlobalMatrix        = entity
                                        ? entity.getGlobalMatrix()
                                        : mat4.create();

        if(transform.position)
        {
            this.setPosition(transform.position, Array.from(parentGlobalMatrix) as SDK_Mat4);
        }

        if(transform.orientation)
        {
            this.setOrientation(transform.orientation, transform.eulerOrientation, parentGlobalTransform.orientation);
        }

        if(transform.scale)
        {
            this.setScale(transform.scale, parentGlobalTransform.scale);
        }
    }

    //--------------------------------------------------------------------------
    // private
    setPosition(globalPosition: SDK_Vec3, _parentGlobalMatrix: mat4 | null = null){
        const localPosition                 = vec3.create();
        const invertedParentGlobalMatrix    = mat4.create();

        const parentGlobalMatrix            = _parentGlobalMatrix
                                            ? _parentGlobalMatrix
                                            : this.getParentGlobalMatrix();

        mat4.invert(invertedParentGlobalMatrix, parentGlobalMatrix);
        vec3.transformMat4(localPosition, vec3.fromValues(...globalPosition), invertedParentGlobalMatrix);

        this.setComponent(
            "local_transform",
            {
                position : Array.from(localPosition) as SDK_Vec3
            }
        );
    }

    //--------------------------------------------------------------------------
    // private
    setOrientation(globalOrientation: SDK_Quat, globalEulerOrientation?: SDK_Vec3, _parentGlobalOrientation?: SDK_Quat)
    {
        const localOrientation                  = quat.create();
        const parentGlobalOrientationConjugate  = quat.create();

        const parentGlobalOrientation           = _parentGlobalOrientation
                                                ? _parentGlobalOrientation
                                                : quat.fromValues(...this.getParentGlobalTransform().orientation);

        quat.conjugate(parentGlobalOrientationConjugate, parentGlobalOrientation);
        quat.mul(localOrientation, parentGlobalOrientationConjugate, quat.fromValues(...globalOrientation));

        const orientation = Array.from(localOrientation) as SDK_Quat;

        if(!globalEulerOrientation)
        {
            globalEulerOrientation = SDK3DVerse_Utils.quaternionToEuler(orientation);
        }

        this.setComponent("local_transform", { orientation, globalEulerOrientation });
    }

    //--------------------------------------------------------------------------
    // private
    setScale(globalScale: SDK_Vec3, _parentGlobalScale?: SDK_Vec3)
    {
        const localScale        = vec3.create();

        const parentGlobalScale = _parentGlobalScale
                                ? _parentGlobalScale
                                : vec3.fromValues(...this.getParentGlobalTransform().scale);

        vec3.div(localScale, vec3.fromValues(...globalScale), parentGlobalScale);

        this.setComponent(
            "local_transform",
            {
                scale : Array.from(localScale) as SDK_Vec3
            }
        );
    }

    //--------------------------------------------------------------------------
    /**
     * Calculate and return the entity's global AABB, or Axis Aligned Bounding Box.
     * If the entity doesn't have a local AABB, a default local AABB of
     * `{ min : [-1, -1, -1], max : [1, 1, 1] }` is used in the calculation of
     * its global AABB.
     *
     * @return {AABB} Global AABB.
     *
     * @example
     * const aabb = entity.getGlobalAABB();
     * // The minimum point of the aabb is aabb.min[0], aabb.min[1], aabb.min[2]
     * // The maximum point of the aabb is aabb.max[0], aabb.max[1], aabb.max[2]
     */
    getGlobalAABB(): AABB
    {
        const globalMatrix      = this.getGlobalMatrix();
        const localAABB         = this.isAttached('local_aabb')
                                ? this.getComponent('local_aabb')
                                : { min : [-1, -1, -1], max : [1, 1, 1] };

        return SDK3DVerse_Utils.computeAABB(localAABB, globalMatrix);
    }

    //--------------------------------------------------------------------------
    /**
     * Set the global orientation to make the entity look at the target point.
     *
     * @param {SDK_Vec3} target - Point to look at in global space
     *
     * @fires onEntitiesUpdated
     */
    lookAt(target: SDK_Vec3)
    {
        const targetPosition    = vec3.fromValues(...target);
        const globalPosition    = this.getGlobalTransform().position;

        let direction   = vec3.create();
        vec3.sub(direction, targetPosition, globalPosition);
        vec3.normalize(direction, direction);

        let outQuat     = quat.create();
        quat.rotationTo(outQuat, SDK3DVerse_Utils.neutralDirection, direction);
        this.setOrientation(Array.from(outQuat) as SDK_Quat);
    }

    //--------------------------------------------------------------------------
    // deprecated
    focusOn(viewport, options: {
        startPosition?: SDK_Vec3,
        startOrientation?: SDK_Quat,
        speedFactor?: number,
        distanceShift?: number,
    } = {})
    {
        return viewport.focusOn(this, options);
    }

    //--------------------------------------------------------------------------
    /**
     * Save the entity in the scene asset. Updates the entity in all running sessions of the scene.
     * To save multiple entities at once, it is recommended to use [SDK3DVerse.engineAPI.saveEntities]{@link SDK3DVerse.engineAPI#saveEntities}.
     */
    save()
    {
        this.engineAPI.saveEntities([this]);
    }

    //--------------------------------------------------------------------------
    /**
     * Set input values of a script attached to the entity. If entity does not have a
     * <a href="tutorial-components_script_map.html">script_map</a> component, do nothing.
     *
     * @param {string} scriptUUID - Script UUID
     * @param {object} inputValues - Input values of script. The inputs that were specified will have their values updated.
     *                              An object in the form `{ inputName : inputValue, ... }`
     *
     * @fires onEntitiesUpdated
     *
     * @example
     * entity.setScriptInputValues(scriptUUID, { walkSpeed : 1, runSpeed : 5 });
     */
    setScriptInputValues(scriptUUID: string, inputValues: object)
    {
        if(!this.isAttached('script_map'))
        {
            console.warn("Cannot set script input values of entity that does not have a script_map component", this);
            return;
        }

        const scriptMap     = this.getComponent('script_map');
        const scriptElement = scriptMap.elements[scriptUUID];
        if(!scriptElement)
        {
            console.warn("Cannot set script input values of entity that does not have the specified script", this, scriptUUID);
            return;
        }

        scriptElement.dataJSON = {
            ...scriptElement.dataJSON,
            ...inputValues
        };

        this.setComponent('script_map', scriptMap);
    }
}

//------------------------------------------------------------------------------
/**
 * Vec3. An array of three numbers that correspond to x, y, z.
 * @typedef {Array.<number>} SDK_Vec3
 *
 * @example
 * [0, 0, 0]
 */

//------------------------------------------------------------------------------
/**
 * Vec2. An array of two numbers that correspond to x, y.
 * @typedef {Array.<number>} SDK_Vec2
 *
 * @example
 * [0, 0]
 */

//------------------------------------------------------------------------------
/**
 * Vec2. An array of two unsigned int that correspond to x, y.
 * @template T
 * @typedef {Array.<uint>} SDK_Vec2_uint
 *
 * @example
 * [0, 0]
 */

//------------------------------------------------------------------------------
/**
 * Quaternion. An array of four numbers that correspond to x, y, z, w.
 * @typedef {Array.<number>} SDK_Quat
 *
 * @example
 * [0, 0, 0, 1]
 */

//------------------------------------------------------------------------------
/**
 * [4x4 column-major matrix]{@link https://glmatrix.net/docs/module-mat4.html}.
 * The 13th, 14th and 15th elements represent the x, y and z translation components.
 *
 * @typedef {Array.<number>} mat4
 *
 * @example
 * [1, 0, 0, 0,
 * 0, 1, 0, 0,
 * 0, 0, 1, 0,
 * x, y, z, 0]
 */

//------------------------------------------------------------------------------
/**
 * Axis Aligned Bounding Box
 *
 * @typedef {object} AABB
 *
 * @property {SDK_Vec3} min Minimum point of the box.
 * @property {SDK_Vec3} max Maximum point of the box.
 *
 * @example
 * { min : [-1,-1,-1], max : [1, 1, 1] }
 */

//------------------------------------------------------------------------------
/**
 * Transform containing position, orientation, scale. Equivalent to the
 * <a href="tutorial-components_local_transform.html">transform component</a>.
 *
 * @typedef {object} Transform
 *
 * @property {SDK_Vec3} [position] Position.
 * @property {SDK_Quat} [orientation] Orientation expressed as quaternion.
 * @property {SDK_Vec3} [scale] Scale.
 * @property {SDK_Vec3} [eulerOrientation] Local orientation expressed as euler angles.
 * @property {SDK_Vec3} [globalEulerOrientation] Global orientation expressed as euler angles.
 *
 * @example
 * { position : [0, 0. 0], orientation : [0, 0, 0, 1], scale : [1, 1, 1] }
 */

//------------------------------------------------------------------------------
/**
 * A `string` that describes the state of an external entity (i.e. an entity belonging to another scene).\
 * The `string` can be either:
 * - 'unmodified' : the entity has not been modified in current scene
 * - 'overridden' : the entity contains at least one overridden component in current scene
 * - 'deleted' : the entity is deleted in current scene
 *
 * @see [Entity.getExternalState]{@link Entity#getExternalState}
 *
 * @typedef {string} ExternalEntityState - A string enum that is either 'unmodified', 'overridden', 'deleted', or 'overrider'
 */

//------------------------------------------------------------------------------
/**
 * A `string` that describes the state of a component belonging to an external entity (i.e. an entity belonging to another scene).\
 * The `string` can be either:
 * - 'unmodified' : the component has not been modified in current scene
 * - 'overridden' : the component is overridden in current scene (i.e. its value is modified in comparison to the scene it comes from)
 * - 'detached' : the component has been detached in current scene
 * - 'new' : the component has been added in current scene
 *
 * @see [Entity.getExternalComponentState]{@link Entity#getExternalComponentState}
 *
 * @typedef {string} ExternalComponentState - A string enum that is either 'unmodified', 'overridden', 'detached', or 'new'
 */

export default Entity;
