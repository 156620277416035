import utils from './Utils'
import Hammer from 'hammerjs';

//------------------------------------------------------------------------------
const TaskID =
{
    MSG_RESET_INPUTS        : 0,
    MSG_LBUTTON_DOWN        : 1,
    MSG_MBUTTON_DOWN        : 2,
    MSG_RBUTTON_DOWN        : 3,
    MSG_LBUTTON_UP          : 4,
    MSG_MBUTTON_UP          : 5,
    MSG_RBUTTON_UP          : 6,
    MSG_MOUSEMOVE           : 7,
    MSG_ONKEYDOWN           : 8,
    MSG_ONKEYUP             : 9,
    MSG_RESIZE              : 10,
    MSG_DISCONNECTED        : 11,
    MSG_TOUCH_START         : 12,
    MSG_TOUCH_END           : 13,
    MSG_TOUCH_MOVE          : 14,
    MSG_TOUCH_PINCH_START   : 15,
    MSG_TOUCH_PINCH_MOVE    : 16,
    MSG_WINDOW_RESIZED      : 17,
    MSG_SET_CAMERA          : 18,
    MSG_WHEEL               : 22,
    MSG_GAMEPAD_AXIS        : 26,
    MSG_GAMEPAD_BUTTONS     : 27,
    MSG_MOUSEDELTA          : 28,
    MSG_TOUCH_PINCH_END     : 29,
};

//------------------------------------------------------------------------------
export default class SDK3DVerse_InputRelay
{
    //--------------------------------------------------------------------------
    constructor(taskStack, notifier, purgeTasks)
    {
        this.taskStack          = taskStack;
        this.notifier           = notifier;
        this.purgeTasks         = purgeTasks;

        this.onFramePreRender   = () => this.handleGamepadInputs();
        this.resetState();
    }

    //--------------------------------------------------------------------------
    resetState()
    {
        this.hammer                     = null;
        this.keys                       = {};
        this.mouseButtons               = {};
        this.lastMousePosition          = { x : 0, y : 0 };
        this.controllerAxis             = SDK3DVerse_InputRelay.ControllerAxis;
        this.isLocked                   = false;
        this.isSuspended                = false;
        this.previousGamepadsReading    = [];
    }

    //--------------------------------------------------------------------------
    initialize(canvas)
    {
        this.canvas = canvas;
        this.initializeListeners();
    }

    //--------------------------------------------------------------------------
    initializeListeners()
    {
        if(this.hammer)
        {
            this.hammer.destroy();
        }

        this.hammer = new Hammer(this.canvas,
        {
            inputClass: Hammer.PointerEventInput
        });

        this.hammer.get('pinch').set({ enable: true });

        if(this.inputListeners)
        {
            this.uninstallListeners();
        }

        this.inputListeners = {
            mousedown : {element : this.canvas, callback : this.OnMouseDown, stateHandler : (e) => this.updateMouseState(e, true), options : false},
            mousemove : {element : window, callback : this.OnMouseMove, stateHandler : (e) => this.updateMouseState(e), options : false},
            mouseup   : {element : window, callback : this.OnMouseUp, stateHandler : (e) => this.updateMouseState(e, false), options : false},

            mousewheel : {element : this.canvas, callback : (e) => this.OnMouseWheel(e), options: {passive: true}},
            DOMMouseScroll : {element : this.canvas, callback : (e) => this.OnMouseWheel(e)},

            keydown : {element : this.canvas, callback : (e) => this.OnKeyDown(e), stateHandler : (e) => this.updateKeyState(e, true), options : false},
            keyup : {element : window, callback : (e) => this.OnKeyUp(e), stateHandler : (e) => this.updateKeyState(e, false), options : false},

            touchmove : {element : this.canvas, callback : (e) => e.preventDefault()},

            pointerlockchange : {element : document, stateHandler : this.onElementLocked, options : false},
            mozpointerlockchange : {element : document, stateHandler : this.onElementLocked, options : false},

            blur : {element : window, callback : this.resetInputs, options : false}
        };

        this.hammerListeners = {
            'panstart'  : this.OnPanStart,
            'panend'    : this.OnPanEnd,
            'pan'       : this.OnPan,
            'pinchstart': this.OnPinchStart,
            'pinchend'  : this.OnPinchEnd,
            'pinch'     : this.OnPinch
        };

        this.installListeners();
    }

    //--------------------------------------------------------------------------
    installListeners(skipStateHandler = false)
    {
        for(const inputName of Object.keys(this.inputListeners))
        {
            const listener = this.inputListeners[inputName];
            if(!skipStateHandler && listener.stateHandler)
            {
                listener.element.addEventListener(inputName, listener.stateHandler, listener.options);
            }

            if(listener.callback)
            {
                listener.element.addEventListener(inputName, listener.callback, listener.options);
            }
        }

        for(const eventName in this.hammerListeners)
        {
            this.hammer.on(eventName, this.hammerListeners[eventName]);
        }

        this.notifier.on('onFramePreRender', this.onFramePreRender);
    }

    //--------------------------------------------------------------------------
    uninstallListeners(skipStateHandler = false)
    {
        if(!this.inputListeners)
        {
            return;
        }

        for(const inputName of Object.keys(this.inputListeners))
        {
            const listener = this.inputListeners[inputName];
            if(!skipStateHandler && listener.stateHandler)
            {
                listener.element.removeEventListener(inputName, listener.stateHandler, listener.options);
            }

            if(listener.callback)
            {
                listener.element.removeEventListener(inputName, listener.callback, listener.options);
            }
        }

        if(this.hammer)
        {
            for(const eventName in this.hammerListeners)
            {
                this.hammer.off(eventName, this.hammerListeners[eventName]);
            }
        }

        this.notifier.off('onFramePreRender', this.onFramePreRender);
    }

    //--------------------------------------------------------------------------
    close()
    {
        this.uninstallListeners();

        if(this.hammer)
        {
            this.hammer.destroy();
        }

        this.resetState();
    }

    //--------------------------------------------------------------------------
    suspendInputs()
    {
        this.uninstallListeners(true);
        this.resetInputs();
        this.isSuspended = true;
    }

    //--------------------------------------------------------------------------
    resumeInputs()
    {
        if(!this.isSuspended)
        {
            return;
        }

        for(const keyID in this.keys)
        {
            const key = this.keys[keyID];
            if(key.down)
            {
                this.taskStack.pushInput(TaskID.MSG_ONKEYDOWN, key.data);
                key.pressed = true;
            }
        }

        const MData = utils.fillMousePosBuffer(this.canvas, this.lastMousePosition.x, this.lastMousePosition.y);

        // Trigger a mouse move event and trigger purge tasks immediatly after,
        // to force the renderer to reset its mouse position
        this.taskStack.pushInput(TaskID.MSG_MOUSEMOVE, new Uint8Array(MData));
        this.purgeTasks();

        for(const mouseButton in this.mouseButtons)
        {
            const isDown = this.mouseButtons[mouseButton];
            if(isDown)
            {
                const EventID   = this.getTaskIDFromMouseButton(parseInt(mouseButton), true);
                this.taskStack.pushInput(EventID, new Uint8Array(MData));
            }
        }

        this.isSuspended = false;
        this.installListeners(true);
    }

    //--------------------------------------------------------------------------
    updateMouseState(event, value)
    {
        if(value !== undefined)
        {
            this.mouseButtons[event.button] = value;
        }

        if(this.isSuspended)
        {
            this.lastMousePosition.x = event.clientX;
            this.lastMousePosition.y = event.clientY;
        }
    }

    //--------------------------------------------------------------------------
    /**
     * Remap the KeyboardEvent.keyCode for keys which differs from the regular
     * QWERTY english keyboard. But this works only for code of "regular" keys e.g
     * starting with "Key" followed by a single capital character like "KeyA".
     * TODO: KeyboardEvent.keyCode is deprecated, and be removed from browsers in the future.
     *       Afaiu there's no more such thing as a keyCode integer value which is.
     *       So according to the MDN web doc on KeyboardEvent, I guess the renderer should rely on the
     *       KeyboardEvent.code, which is a string independent from the keyboard layout: KeyA, KeyQ, Semicolon, AltLeft,...
     *       https://developer.chrome.com/blog/keyboardevent-keys-codes/
     *       https://w3c.github.io/uievents/tools/key-event-viewer.html
     * @private
     *
     * @param {KeyboardEvent} event
     * @returns {KeyboardEvent} the altered event
     */
    getLayoutAgnosticKeyCode(event)
    {
        const { code, key } = event;
        if(!code?.startsWith('Key')) {
            return event.keyCode;
        }

        // The real key letter on the regular QWERTY english keyboard.
        // This https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_code_values
        // is a guarantee that any code starting with "Key" is followed by a single english alphabetic character.
        const keyFromCode = code[code.length - 1];
        if(keyFromCode !== key) {
            return keyFromCode.charCodeAt(0);
        }
        return event.keyCode;
    }

    //--------------------------------------------------------------------------
    updateKeyState(event, value)
    {
        const keyCode  = this.getLayoutAgnosticKeyCode(event);

        let key = this.keys[keyCode];
        if(key)
        {
            key.down = value;
            return;
        }

        const KeyData   = new Uint8Array(4);
        KeyData[0]      = keyCode & 0xFF;
        KeyData[1]      = (keyCode >> 8) & 0xFF;
        KeyData[2]      = (keyCode >> 16) & 0xFF;
        KeyData[3]      = (keyCode >> 24) & 0xFF;

        key             = { data : KeyData, down : value };
        this.keys[keyCode] = key;
    }

    //--------------------------------------------------------------------------
    OnKeyDown(event)
    {
        const keyCode = this.getLayoutAgnosticKeyCode(event);
        const key = this.keys[keyCode];
        if(!key.pressed)
        {
            this.taskStack.pushInput(TaskID.MSG_ONKEYDOWN, key.data);
            key.pressed = true;
        }
    }

    //--------------------------------------------------------------------------
    OnKeyUp(event)
    {
        const keyCode = this.getLayoutAgnosticKeyCode(event);
        const key = this.keys[keyCode];
        this.taskStack.pushInput(TaskID.MSG_ONKEYUP, key.data);
        key.pressed = false;
    }

    //--------------------------------------------------------------------------
    OnMouseDown = (event) =>
    {
        this.canvas.focus();

        const position  = this.getMousePosition(event);
        const MData     = utils.fillMousePosBuffer(this.canvas, position.x, position.y);
        const EventID   = this.getTaskIDFromMouseButton(event.button, true);

        this.taskStack.pushInput(EventID, new Uint8Array(MData));
    }

    //--------------------------------------------------------------------------
    OnMouseUp = (event) =>
    {
        const position  = this.getMousePosition(event);
        const MData     = utils.fillMousePosBuffer(this.canvas, position.x, position.y);
        const EventID   = this.getTaskIDFromMouseButton(event.button, false);

        this.taskStack.pushInput(EventID, new Uint8Array(MData));    }

    //--------------------------------------------------------------------------
    OnMouseMove = (event) =>
    {
        const position  = this.getMousePosition(event);
        const MData     = utils.fillMousePosBuffer(this.canvas, position.x, position.y);
        this.taskStack.pushInput(TaskID.MSG_MOUSEMOVE, new Uint8Array(MData), true);
    }

    //--------------------------------------------------------------------------
    getMousePosition(event)
    {
        if(this.isLocked)
        {
            this.lastMousePosition.x += event.movementX;
            this.lastMousePosition.y += event.movementY;
        }
        else
        {
            this.lastMousePosition.x = event.clientX;
            this.lastMousePosition.y = event.clientY;
        }

        return this.lastMousePosition;
    }

    //--------------------------------------------------------------------------
    OnMouseWheel = (event) =>
    {
        const Delta     = event.wheelDelta || event.detail*-60;
        const MouseData = new Uint8Array(4);

        MouseData[0]    = Delta & 0xFF;
        MouseData[1]    = (Delta >> 8) & 0xFF;
        MouseData[2]    = (Delta >> 16) & 0xFF;
        MouseData[3]    = (Delta>> 24) & 0xFF;

        this.taskStack.pushInput(TaskID.MSG_WHEEL, MouseData);
    }

    //--------------------------------------------------------------------------
    onElementLocked = () =>
    {
        this.isLocked = Boolean(document.pointerLockElement || document.mozPointerLockElement);
    }

    //--------------------------------------------------------------------------
    OnPanStart = (e) =>
    {
        var touch0  = e.pointers[0];
        var MData   = utils.fillMousePosBuffer(this.canvas, touch0.clientX, touch0.clientY);
        this.taskStack.pushInput(TaskID.MSG_TOUCH_START, new Uint8Array(MData));
    }

    //--------------------------------------------------------------------------
    OnPanEnd = (e) =>
    {
        var touch0  = e.pointers[0];
        var MData   = utils.fillMousePosBuffer(this.canvas, touch0.clientX, touch0.clientY);
        this.taskStack.pushInput(TaskID.MSG_TOUCH_END, new Uint8Array(MData));
    }

    //--------------------------------------------------------------------------
    OnPan = (e) =>
    {
        var touch0  = e.pointers[0];
        var MData   = utils.fillMousePosBuffer(this.canvas, touch0.clientX, touch0.clientY);
        this.taskStack.pushInput(TaskID.MSG_TOUCH_MOVE, new Uint8Array(MData));
    }

    //--------------------------------------------------------------------------
    OnPinchStart = (e) =>
    {
        var touch0 = e.pointers[0];
        var touch1 = e.pointers[1];

        if(!touch1) return;

        var TData = utils.fillPinchPosBuffer(
            this.canvas,
            touch1.clientX, touch1.clientY,
            touch0.clientX, touch0.clientY,
            this.canvas.width, this.canvas.height
        );
        this.taskStack.pushInput(TaskID.MSG_TOUCH_PINCH_START, new Uint8Array(TData));
    }

    //--------------------------------------------------------------------------
    OnPinchEnd = (e) =>
    {
        var touch0  = e.pointers[0];
        var MData   = utils.fillMousePosBuffer(this.canvas, touch0.clientX, touch0.clientY);
        this.taskStack.pushInput(TaskID.MSG_TOUCH_PINCH_END, new Uint8Array(MData));
    }

    //--------------------------------------------------------------------------
    OnPinch = (e) =>
    {
        var touch0 = e.changedPointers[0];
        var touch1 = e.changedPointers[1];

        if(!touch1) return;

        var TData = utils.fillPinchPosBuffer(
            this.canvas,
            touch1.clientX, touch1.clientY,
            touch0.clientX, touch0.clientY,
            this.canvas.width, this.canvas.height
        );
        this.taskStack.pushInput(TaskID.MSG_TOUCH_PINCH_MOVE, new Uint8Array(TData));
    }

    //--------------------------------------------------------------------------
    sendControllerAxis(gamepadIndex, ControllerAxisType, value)
    {
        var buffer          = new ArrayBuffer(6);
        var bufferWriter    = new DataView(buffer);

        bufferWriter.setUint8(0, gamepadIndex)
        bufferWriter.setUint8(1, ControllerAxisType, true);
        bufferWriter.setFloat32(2, value, true);

        this.taskStack.pushInput(TaskID.MSG_GAMEPAD_AXIS, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    sendControllerButtons(gamepadIndex, buttonReading)
    {
        var buffer          = new ArrayBuffer(5);
        var bufferWriter    = new DataView(buffer);

        bufferWriter.setUint8(0, gamepadIndex)
        bufferWriter.setUint16(1, buttonReading, true);

        this.taskStack.pushInput(TaskID.MSG_GAMEPAD_BUTTONS, new Uint8Array(buffer));
    }

    //--------------------------------------------------------------------------
    getTaskIDFromMouseButton(button, down)
    {
        const suffixKey = down ? "DOWN" : "UP";

        switch(button)
        {
            case 0:
                return TaskID["MSG_LBUTTON_" + suffixKey];
            case 1:
                return TaskID["MSG_MBUTTON_" + suffixKey];
            case 2:
                return TaskID["MSG_RBUTTON_" + suffixKey];
        }

        console.log("Unhandled mouse button ", button);
    }

    //--------------------------------------------------------------------------
    handleGamepadInputs()
    {
        if(!document.hasFocus())
        {
            return;
        }

        const gamepadsReading = computeGamepadsReading();

        for(const i in gamepadsReading)
        {
            const previousReading   = this.previousGamepadsReading[i];
            const gamepadReading    = gamepadsReading[i];
            if(previousReading && gamepadReading)
            {
                this.sendControllerEvents(i, previousReading, gamepadReading);
            }
            this.previousGamepadsReading[i] = gamepadReading;
        }
    }

    //--------------------------------------------------------------------------
    sendControllerEvents(gamepadIndex, previousReading, gamepadReading)
    {
        /// HACK HAAAAAACK!
        // ftl-rendering-services rendering_viewer::installRequestedInputDevices only add
        // the gamepad of index zero and misses facilities to attach distinct gamepads to
        // distinct controllers (camera, characters, ...)
        // So we force gamepadIndex to 0 until this is supported in the renderer.
        gamepadIndex = 0;

        if (gamepadReading.leftThumbstickX != previousReading.leftThumbstickX)
        {
            this.sendControllerAxis(gamepadIndex, SDK3DVerse_InputRelay.ControllerAxis.LeftThumbstickX, gamepadReading.leftThumbstickX);
        }

        if (gamepadReading.leftThumbstickY != previousReading.leftThumbstickY)
        {
            this.sendControllerAxis(gamepadIndex, SDK3DVerse_InputRelay.ControllerAxis.LeftThumbstickY, gamepadReading.leftThumbstickY);
        }

        if (gamepadReading.rightThumbstickX != previousReading.rightThumbstickX)
        {
            this.sendControllerAxis(gamepadIndex, SDK3DVerse_InputRelay.ControllerAxis.RightThumbstickX, gamepadReading.rightThumbstickX);
        }

        if (gamepadReading.rightThumbstickY != previousReading.rightThumbstickY)
        {
            this.sendControllerAxis(gamepadIndex, SDK3DVerse_InputRelay.ControllerAxis.RightThumbstickY, gamepadReading.rightThumbstickY);
        }

        if (gamepadReading.leftTrigger != previousReading.leftTrigger)
        {
            this.sendControllerAxis(gamepadIndex, SDK3DVerse_InputRelay.ControllerAxis.LeftTrigger, gamepadReading.leftTrigger);
        }

        if (gamepadReading.rightTrigger != previousReading.rightTrigger)
        {
            this.sendControllerAxis(gamepadIndex, SDK3DVerse_InputRelay.ControllerAxis.RightTrigger, gamepadReading.rightTrigger);
        }

        if (gamepadReading.buttons != previousReading.buttons)
        {
            this.sendControllerButtons(gamepadIndex, gamepadReading.buttons);
        }
    }

    //--------------------------------------------------------------------------
    // Reset key inputs, when the browser lost the focus
    resetInputs = () =>
    {
        this.taskStack.pushInput(TaskID.MSG_RESET_INPUTS, null, false);

        for(const keyID in this.keys)
        {
            const key   = this.keys[keyID];
            key.down    = false;
            key.pressed = false;
        }
    }
}

//------------------------------------------------------------------------------
SDK3DVerse_InputRelay.ControllerAxis = {
    LeftThumbstickX     : 0,
    LeftThumbstickY     : 1,
    RightThumbstickX    : 2,
    RightThumbstickY    : 3,
    LeftTrigger         : 4,
    RightTrigger        : 5
};

//------------------------------------------------------------------------------
const XInputMaskMap = [
    4,      // A
    8,      // B
    16,     // X
    32,     // Y
    1024,   // LeftShoulder
    2048,   // RightShoulder

    0,      // LeftTrigger. Ignored
    0,      // RightTrigger. Ignored
    2,      // View
    1,      // Menu
    4096,   // LeftThumbstick
    8192,   // RightThumbstick

    64,     // DPadUp
    128,    // DPadDown
    256,    // DPadLeft
    512,    // DPadRight
];

//------------------------------------------------------------------------------
function computeGamepadsReading()
{
    const gamepads  = navigator.getGamepads
                    ? navigator.getGamepads()
                    : ( navigator.webkitGetGamepads
                      ? navigator.webkitGetGamepads
                      : []
                      );

    const readings  = [];

    for (let i = 0; i < gamepads.length; ++i)
    {
        const gamepad = gamepads[i];
        if(!gamepad)
        {
            continue;
        }

        readings[gamepad.index] = computeGamepadReading(gamepad);
    }

    return readings;
}

//------------------------------------------------------------------------------
function computeGamepadReading(gamepad)
{
    return {
        buttons             : computebuttonReading(gamepad),
        leftTrigger         : getButtonValue(gamepad.buttons[6]),
        rightTrigger        : getButtonValue(gamepad.buttons[7]),
        leftThumbstickX     : -gamepad.axes[0],
        leftThumbstickY     : gamepad.axes[1],
        rightThumbstickX    : -gamepad.axes[2],
        rightThumbstickY    : gamepad.axes[3]
    };
}

//------------------------------------------------------------------------------
function computebuttonReading(gamepad)
{
    let buttonReading = 0;
    for(const i in XInputMaskMap)
    {
        const button = gamepad.buttons[i];
        if(isButtonPressed(button))
        {
            buttonReading |= XInputMaskMap[i];
        }
    }

    return buttonReading;
}

//------------------------------------------------------------------------------
function isButtonPressed(b)
{
    if (typeof(b) == "object")
    {
        return b.pressed;
    }
    return b == 1.0;
}

//------------------------------------------------------------------------------
function getButtonValue(b)
{
    if (typeof(b) == "object")
    {
        return b.value;
    }
    return b;
}
