export default class SDK3DVerse_AudioStreamer
{
    //--------------------------------------------------------------------------
    constructor()
    {
        this.numChannels    = 2;
        this.sampleRate     = 44100;
        this.bitDepth       = 32;
        this.globalVolume   = 1.0;
    }

    //--------------------------------------------------------------------------
    createContext()
    {
        this.audioCtx       = new (window.AudioContext || window.webkitAudioContext)( { sampleRate: this.sampleRate } );
        this.nextStartTime  = this.audioCtx.currentTime;
    }

    //--------------------------------------------------------------------------
    releaseContext()
    {
        if(this.audioCtx)
        {
            this.audioCtx.close();
        }
        this.audioCtx = null;
    }

    //--------------------------------------------------------------------------
    onAudioReceived(FTL_HEADER_SIZE, LITTLE_ENDIAN, message, dataView)
    {
        const AUDIO_CHANNEL_HEADER_SIZE        = 20;
        const AUDIO_CHANNEL_HEADER_FULL_SIZE   = FTL_HEADER_SIZE + AUDIO_CHANNEL_HEADER_SIZE;
        const dataSize = dataView.getUint32(FTL_HEADER_SIZE + 16, LITTLE_ENDIAN);

        // Convert this ArrayBuffer to a Float32Array.
        const rawSamples = new Float32Array(message.data.slice(
            AUDIO_CHANNEL_HEADER_FULL_SIZE,
            AUDIO_CHANNEL_HEADER_FULL_SIZE + dataSize));

        const numSamples = dataSize / (this.bitDepth / 8 * this.numChannels);

        this.scheduleAudio(rawSamples, numSamples);
    }

    //--------------------------------------------------------------------------
    scheduleAudio(rawSamples, numSamples)
    {
        let source      = this.audioCtx.createBufferSource();
        source.buffer   = this.getBuffer(rawSamples, numSamples);
        source.connect(this.audioCtx.destination);
        if(this.nextStartTime < this.audioCtx.currentTime)
        {
            // The first time the source will play, it will play a buffer later to allow for
            // that maximum unexpected latency.
            this.nextStartTime = this.audioCtx.currentTime + (numSamples / this.sampleRate);
        }
        source.start(this.nextStartTime);
        this.nextStartTime += numSamples / this.sampleRate;
    }

    //--------------------------------------------------------------------------
    getBuffer(rawSamples, numSamples)
    {
        const buffer = this.audioCtx.createBuffer(this.numChannels, numSamples, this.sampleRate);
        for(let channel = 0; channel<this.numChannels; channel++)
        {
            let channelData = buffer.getChannelData(channel);
            for(let j=0; j<numSamples; j++)
            {
                channelData[j] = rawSamples[channel*numSamples + j] * this.globalVolume;
            }
        }
        return buffer;
    }

    //--------------------------------------------------------------------------
    setVolume(volume)
    {
        // 1.0 is normal volume, 0.0 is no volume.
        if(volume > 0 && volume < 4)
        {
            this.globalVolume = volume;
        }
    }

    //--------------------------------------------------------------------------
    getVolume()
    {
        return this.globalVolume;
    }
}
